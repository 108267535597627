import { Recipient } from 'types';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const mapper = (data: any): Array<any> => {
  const recipients: Array<Recipient> = [];

  data.forEach((doc: any) => {
    const recipient = doc.data() as Recipient;
    recipient.id = doc.id;
    recipients.push(recipient);
  });

  return recipients;
};

export default mapper;
