import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import cls from 'classnames';
import { Order, OrderStatus, OrderItem, CashChange } from 'types';
import { sumArrayValues, currencyFormat, editOrderPath, formatShortDate } from 'utils';
import { viewOrderPath } from 'utils';
import style from './OrderDetail.module.scss';
import { ORDER_STATUS_LABELS } from 'constants/order';
import { CASH, CASH_DEPOSIT, TRANSACTIONS_NAME } from 'constants/transactions';
import OrderDetailCashDropsTransaction from './OrderDetailCashDropsTransaction';
import OrderDetailCashDepositTransaction from './OrderDetailCashDepositTransaction';
import OrderInfo from './OrderInfo/OrderInfo';
import { getCurrentOrderStatus } from 'utils/getCurrentOrderStatus';

type OrderItemsProps = {
  items?: Array<OrderItem>;
};

export const OrderItems = ({ items }: OrderItemsProps): any => {
  return (
    <div className="order-items" data-id="order-items">
      {items && items.length ? (
        <table className="w-100 mt-4">
          <thead>
            <tr>
              <th className={style.label}>Description</th>
              <th className={style.label}>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, idx) => (
              <tr key={idx}>
                <td className={cls(style.cell, 'border-bottom border-gray pr-2')} data-id="order-item-name">
                  {item.item}
                </td>
                <td className={cls(style.cell, 'border-bottom border-gray pr-2')} data-id="order-item-quantity">
                  {item.quantity}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <span>No Items</span>
      )}
    </div>
  );
};

type OrderTransactionItemsProps = {
  items?: Array<OrderItem>;
  transactionType?: string;
  cashChange?: CashChange;
};

export const OrderTransactionItems = ({ items, transactionType, cashChange }: OrderTransactionItemsProps): any => {
  const isCashDeposit = transactionType === CASH_DEPOSIT;
  return isCashDeposit ? (
    <OrderDetailCashDepositTransaction items={items} />
  ) : (
    <OrderDetailCashDropsTransaction cashChange={cashChange} />
  );
};

const OrderDetail: React.FC<Order> = ({
  id,
  status,
  createdAt,
  recipientInfo,
  senderInfo,
  destinationInfo,
  originInfo,
  items,
  type,
  transactionType,
  depositType,
  cashChange,
  orderNumber,
}: Order) => {
  const isRequest = !orderNumber;
  const orderStatus = getCurrentOrderStatus(status, isRequest);

  const statuses = {
    [OrderStatus.DRAFT]: [ORDER_STATUS_LABELS[OrderStatus.DRAFT], 'fas fa-clipboard'],
    [OrderStatus.CREATED]: [ORDER_STATUS_LABELS[OrderStatus.CREATED], 'fas fa-paper-plane'],
    [OrderStatus.SCHEDULED]: [ORDER_STATUS_LABELS[OrderStatus.SCHEDULED], 'fas fa-calendar-check'],
    [OrderStatus.PROGRESS]: [ORDER_STATUS_LABELS[OrderStatus.PROGRESS], 'fas fa-truck'],
    [OrderStatus.COMPLETED]: [ORDER_STATUS_LABELS[OrderStatus.COMPLETED], 'fas fa-thumbs-up'],
  };

  const isCashOrderType = type === CASH;
  const isCashDeposit = isCashOrderType && transactionType === CASH_DEPOSIT;
  const orderTypeName = type ? TRANSACTIONS_NAME[type] : '';
  const transactionTypeName = isCashOrderType && transactionType ? TRANSACTIONS_NAME[transactionType] : '';
  const depositTypeName = isCashDeposit && depositType ? TRANSACTIONS_NAME[depositType] : '';

  const statusesArray = Object.entries(statuses);

  const getOrderTotal = useMemo((): any => {
    let total = 0;

    if (items) {
      const totalItemValues: Array<number> = [];

      items.forEach(item => {
        if (item.price && item.quantity) {
          totalItemValues.push(item.price * item.quantity);
        }
      });

      total = sumArrayValues(totalItemValues);
    }

    return currencyFormat(total);
  }, [items]);

  const ActionButton = (): JSX.Element => {
    const isDraft = orderStatus === OrderStatus.DRAFT;
    const to = isDraft ? editOrderPath(id) : viewOrderPath(id);
    const label = isDraft ? 'Edit Draft' : 'View Complete Order';

    return (
      <Link className={style.viewOrder} to={to}>
        {label}
      </Link>
    );
  };

  return (
    <div className={style.wrapper}>
      <div className="d-flex flex-column py-4 border-bottom border-gray">
        <div className="horizontal-drawer-padding">
          <span className={style.title}>Status:</span>
          <div className="status-info d-flex align-items-center justify-content-around mt-5">
            {statusesArray.map(([status, statusInfo], index) => {
              const [label, icon] = statusInfo;
              const isCompleted = orderStatus === OrderStatus.COMPLETED;
              const completedStatus = index < statusesArray.findIndex(([status]) => status === orderStatus);

              return (
                <div
                  key={index}
                  className={cls(style.statusItem, 'd-flex flex-column align-items-center', {
                    [style.completedStatus]: completedStatus || isCompleted,
                    [style.activeStatus]: !isCompleted && orderStatus === status,
                  })}
                >
                  <div className={style.statusIconContainer}>
                    <i className={cls(style.statusIcon, icon)}></i>
                  </div>
                  <span className={style.statusLabel} data-id={`order-status-${label}`}>
                    {label}
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column pb-5">
        <div className="horizontal-drawer-padding">
          <div className="recipient-info mt-4">
            <div className="d-flex flex-column mt-3 order-submission">
              <span className={style.label}>Date of Order Submission</span>
              <span data-id="order-created-date">{createdAt ? formatShortDate(createdAt) : '-'}</span>
            </div>
            <OrderInfo
              isCashDeposit={isCashDeposit}
              originInfo={originInfo}
              senderInfo={senderInfo}
              destinationInfo={destinationInfo}
              recipientInfo={recipientInfo}
            />
            <div className="d-flex flex-column mt-3 order-type">
              <span className={style.label}>Order Type</span>
              <span data-id="order-type">{orderTypeName}</span>
            </div>
            {isCashOrderType && (
              <div className="d-flex flex-column mt-3 order-transaction-type">
                <span className={style.label}>Transaction Type</span>
                <span data-id="order-transaction-type">{transactionTypeName}</span>
              </div>
            )}
            {isCashDeposit && (
              <div className="d-flex flex-column mt-3 order-deposity-type">
                <span className={style.label}>Deposit Type</span>
                <span data-id="order-deposity-type">{depositTypeName}</span>
              </div>
            )}
          </div>
          <div className="mt-4">
            <h4 className={style.subtitle}>{isCashOrderType ? 'Transactions:' : 'Packages:'}</h4>
            {isCashOrderType ? (
              <OrderTransactionItems items={items} transactionType={transactionType} cashChange={cashChange} />
            ) : (
              <OrderItems items={items} />
            )}
          </div>
          <div className="total-info d-flex flex-column mt-4">
            <span className={style.label}>Total</span>
            <span className={style.titleLarge} data-id="order-total">
              {getOrderTotal}
            </span>
          </div>
          <div className="mt-5 text-center">
            <ActionButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
