import { firestore } from '_firebase';
import { CashTransaction } from 'types/cashTransaction';
import { getRecipientById } from './recipients';

export const getCustomerTransactions = async (recipientId: string): Promise<CashTransaction[]> => {
  const data: any[] = [];
  const recipients: { [key: string]: any } = {};

  // prettier-ignore
  const ref = await firestore
    .collection("cashTransactions")
    .where('recipientId', '==', recipientId)
    .get();

  if (!ref.empty) {
    for (const doc of ref.docs) {
      const transaction = doc.data();

      if (!recipients[transaction.recipientId]) {
        recipients[transaction.recipientId] = await getRecipientById(transaction.recipientId);
      }

      data.push({
        id: doc.id,
        ...transaction,
        recipient: recipients[transaction.recipientId],
      });
    }
  }

  return data.sort((a, b) => b.createdAt - a.createdAt);
};

export const getFinancialInstitutionTransactions = async (id: string): Promise<CashTransaction[]> => {
  const transactions: any[] = await getCustomerTransactions(id);
  const recipients: { [key: string]: any } = {};

  // prettier-ignore
  const ref = await firestore
    .collection("cashTransactions")
    .where('financialInstitutionId', '==', id)
    .get();

  if (!ref.empty) {
    for (const doc of ref.docs) {
      const transaction = doc.data();

      if (!recipients[transaction.recipientId]) {
        recipients[transaction.recipientId] = await getRecipientById(transaction.recipientId);
      }

      transactions.push({
        id: doc.id,
        ...transaction,
        recipient: recipients[transaction.recipientId],
      });
    }
  }

  return transactions.sort((a, b) => b.createdAt - a.createdAt);
};
