import React from 'react';
import cls from 'classnames';
import Select from 'components/Select';

import { SelectFieldProps } from '../types';

const SelectField: React.FC<SelectFieldProps> = ({
  id,
  label,
  options,
  inputClassName,
  onChange,
  value,
  isValid,
  feedback,
}: SelectFieldProps) => {
  const Feedback = (): React.ReactNode => {
    if (feedback) {
      return (
        <span className={cls({ 'valid-feedback': isValid, 'invalid-feedback': !isValid }, 'd-block')}>{feedback}</span>
      );
    }

    return null;
  };

  return (
    <div>
      {label && <label>{label}</label>}
      <Select
        id={id}
        className={inputClassName}
        options={options}
        placeholder="Select an option"
        onChange={onChange}
        value={value}
      />
      {Feedback()}
    </div>
  );
};

export default SelectField;
