import React, { useEffect, useState, useMemo, useContext } from 'react';
import classnames from 'classnames';
import styles from './BankingInformation.module.scss';
import { withAlert, withAuthorization, withPageView } from 'components';
import { BankingInformationType, CurrentUserRecipient } from 'types/bankingInformation';
import Alert from 'types/alert';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { DestinationSelectField, TextField } from 'components/FormFields';
import { isEmpty } from 'lodash';
import { Address, Destination, DestinationStatus, Recipient } from 'types';
import { AuthContext } from 'contexts';
import { getBankingInformation, updateCurrentUserRecipient } from 'services/bankInformation';
import { createDestination, updateDestinationStatus } from 'services';

interface Props extends Alert, RouteComponentProps {}
type BankingInformationError = {
  bankName: string;
  accountNumber: string;
  fein: string;
  address: string;
};

const BankingInformation: React.FC<Props> = ({ success }: Props) => {
  const [bankingInformation, setBankingInformation] = useState<BankingInformationType>({} as BankingInformationType);
  const [formErrors, setFormErrors] = useState<BankingInformationError>();
  const [address, setAddress] = useState<Address | null>();
  const { currentUser } = useContext(AuthContext);
  const [isSaving, setIsSaving] = useState(false);

  const getCustomer = async (recipient: Recipient): Promise<void> => {
    const currentBankingInformation = await getBankingInformation(recipient);
    setBankingInformation(currentBankingInformation);
  };

  useEffect(() => {
    if (currentUser?.recipientInfo) {
      getCustomer(currentUser?.recipientInfo);
    }
  }, [currentUser]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsSaving(true);

    const errorList = new Map();

    if (isEmpty(bankingInformation?.name)) {
      errorList.set('bankName', 'Bank Name is required');
    }

    if (isEmpty(bankingInformation?.accountNumber)) {
      errorList.set('accountNumber', 'Account Number is required');
    }

    if (isEmpty(bankingInformation?.fein)) {
      errorList.set('fein', 'F.E.I.N. is required');
    }

    if (!bankingInformation.destinationId && !address) {
      errorList.set('address', 'Address is required');
    }

    if (!isEmpty(errorList)) {
      setFormErrors(Object.fromEntries(errorList));
      setIsSaving(false);
      return;
    }

    if (bankingInformation && currentUser?.recipientId) {
      const currentUserRecipient: CurrentUserRecipient = {
        financialInstitutionId: bankingInformation.financialInstitutionId,
        accountNumber: bankingInformation.accountNumber,
        fein: bankingInformation.fein,
      };

      await updateCurrentUserRecipient(currentUser.recipientId, currentUserRecipient);

      if (address !== null) {
        const destinationData = {
          ...address,
          owner: bankingInformation.financialInstitutionId,
          active: true,
        };

        await createDestination(destinationData as Destination);

        if (bankingInformation.destinationId) {
          const prevDestinationStatus: DestinationStatus = {
            active: false,
          };

          await updateDestinationStatus(bankingInformation.destinationId, prevDestinationStatus);
        }
      }

      setFormErrors(undefined);

      if (currentUser?.recipientInfo) {
        await getCustomer({
          ...currentUser?.recipientInfo,
          ...currentUserRecipient,
        });
      }

      success('Banking Information updated');
    }

    setIsSaving(false);
  };

  const updateBankingInformationState = (key: string, value: string): void => {
    if (value === null || value === undefined) return;
    setBankingInformation(currentBankingInformation => ({
      ...currentBankingInformation,
      [key]: value,
    }));
  };

  const onAddressChange = (address: any): void => {
    setAddress(address);
  };

  const prevSelectedAddress = useMemo(() => {
    if (bankingInformation?.destinationId) {
      return {
        value: bankingInformation.destinationId,
        label: bankingInformation.destinationName,
      };
    }

    return {
      value: '',
      label: '',
    };
  }, [bankingInformation]);

  return (
    <form id="account-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <TextField
          id="bankName"
          inputClassName={classnames('form-control', styles.formInput)}
          name="bankName"
          type="text"
          label="Bank Name"
          placeholder="Bank Name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            updateBankingInformationState('name', e.target.value)
          }
          value={bankingInformation?.name ?? ''}
          isValid={!formErrors?.bankName}
          feedback={formErrors?.bankName}
          disabled
        />
      </div>
      <div className="form-group">
        <TextField
          id="accountNumber"
          inputClassName={classnames('form-control', styles.formInput)}
          name="accountNumber"
          type="text"
          label="ACCOUNT NUMBER"
          placeholder="ACCOUNT NUMBER"
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            updateBankingInformationState('accountNumber', e.target.value)
          }
          value={bankingInformation?.accountNumber ?? ''}
          isValid={!formErrors?.accountNumber}
          feedback={formErrors?.accountNumber}
        />
      </div>
      <div className="form-group">
        <TextField
          id="fein"
          inputClassName={classnames('form-control', styles.formInput)}
          name="fein"
          type="text"
          label="F.E.I.N."
          placeholder="F.E.I.N."
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
            updateBankingInformationState('fein', e.target.value)
          }
          value={bankingInformation?.fein ?? ''}
          isValid={!formErrors?.fein}
          feedback={formErrors?.fein}
        />
      </div>
      <div className="form-group">
        <DestinationSelectField
          id="newAddress"
          className={styles.formDestination}
          label="Address"
          options={[]}
          value={bankingInformation?.destinationId}
          defaultValue={prevSelectedAddress}
          onChange={onAddressChange}
          isValid={!formErrors?.address}
          feedback={formErrors?.address}
        />
      </div>
      <button
        disabled={isSaving}
        className={classnames(!isSaving ? styles.formSubmit : styles.formSubmitDisabled)}
        type="submit"
      >
        SAVE
      </button>
    </form>
  );
};

const Enhanced = compose(withAlert, withAuthorization, withPageView)(BankingInformation, 'BankingInformation');
export default withRouter(Enhanced as React.ComponentType<any>);
