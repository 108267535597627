import { RequestExecutionType } from 'types/states';
export const SHOW_REQUEST_EXECUTING = 'SHOW_REQUEST_EXECUTING';

interface RequestExecutingAction {
  type: typeof SHOW_REQUEST_EXECUTING;
  isRequestExecuting: boolean;
}

export const actions: any = {
  onRequestExecuting: (isRequestExecuting: boolean): RequestExecutingAction => ({
    type: SHOW_REQUEST_EXECUTING,
    isRequestExecuting,
  }),
};

const initialState: RequestExecutionType = {
  isRequestExecuting: false,
};

const actionHandlers = {
  [SHOW_REQUEST_EXECUTING]: (state: any, { isRequestExecuting }: RequestExecutingAction): RequestExecutionType => ({
    ...state,
    isRequestExecuting,
  }),
};

export const reducer = (state = initialState, action: RequestExecutingAction): RequestExecutionType => {
  const handler = actionHandlers[action.type];

  return handler ? handler(state, action) : state;
};
