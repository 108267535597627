import React, { useContext } from 'react';
import { AlertContext } from 'contexts';

export default function withAlert<Props>(
  WrappedComponent: React.ComponentType<Props>,
): {
  (props: Props): JSX.Element;
} {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const WithAlert = (props: Props): React.ReactElement => {
    const alertContext = useContext(AlertContext);

    return <WrappedComponent {...alertContext} {...(props as Props)} />;
  };

  WithAlert.displayName = `withAlert(${displayName})`;

  return WithAlert;
}
