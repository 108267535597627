import { createContext, useContext } from 'react';
import { AuthContext } from '../types/auth';

const Auth = createContext<AuthContext>({
  currentUser: null,
});

const useAuth = (): AuthContext => useContext(Auth);

export { useAuth };

export default Auth;
