import React, { useState, useEffect, PropsWithChildren } from 'react';
import PropTypes from 'prop-types';
import { CurrentUser } from '../types/auth';
import { AuthContext } from '../contexts';
import { getCurrentUser } from '../services/users';
import { auth } from '_firebase';

const AuthProvider: React.FC<PropsWithChildren<unknown>> = props => {
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>();

  useEffect(() => {
    auth.onAuthStateChanged(async authUser => {
      if (authUser) {
        const profile = await getCurrentUser(authUser?.uid);

        if (profile) {
          setCurrentUser({
            displayName: authUser?.displayName || '',
            emailVerified: authUser?.emailVerified,
            ...profile,
          });

          return;
        }
      }

      setCurrentUser(null);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        currentUser,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any,
};

export default AuthProvider;
