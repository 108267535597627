import React from 'react';
import { connect } from 'react-redux';
import { StateProps } from 'reducers';
import { OrderStepperStateProps } from 'reducers/orderStepper';
import * as OrderStepperActions from 'actions/orderStepper';
import { Destination, Order, Recipient } from 'types';
import { OptionType } from 'components/Select';
import PickupForm from 'components/Forms/PickupForm';

interface Props {
  destinations: Array<Destination>;
  destinationsOptions: (showNew?: boolean) => Array<OptionType>;
  isGuestUser: boolean;
  order?: Order;
  recipients: Array<Recipient>;
  pickupStep: Record<string, unknown>;
  isValid: boolean;
  errors: any;
}

const PickupStep: React.FC<Props> = ({
  destinations,
  destinationsOptions,
  isGuestUser,
  order,
  recipients,
  isValid,
  errors,
}: Props) => {
  return (
    <PickupForm
      destinations={destinations}
      destinationsOptions={destinationsOptions}
      isGuestView={isGuestUser}
      recipients={recipients}
      order={order}
      isValid={isValid}
      errors={errors}
    />
  );
};

const mapStateToProps = (): any => ({});

const mapDispatchToProps = { ...OrderStepperActions };

const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PickupStep);

export default Connected;
