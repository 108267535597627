import { firestore } from '_firebase';
import { Driver } from 'types/driver';
import * as collections from 'constants/collections';
import * as roles from 'constants/roles';

export const getDrivers = async (): Promise<Array<Driver>> => {
  const drivers: Array<Driver> = [];

  // prettier-ignore
  const driversRef = await firestore
    .collection(collections.USERS)
    .where('role', '==', roles.DRIVER)
    .get();

  if (!driversRef.empty) {
    driversRef.forEach(doc => {
      const driver = doc.data();

      drivers.push({
        id: doc.id,
        firstName: driver.firstName,
        lastName: driver.lastName,
        email: driver.email,
        driverLicense: driver.driverLicense || '',
      });
    });
  }

  return drivers;
};

export const getDriverById = async (driverId: string): Promise<Driver | undefined> => {
  const driverRef = await firestore.collection(collections.USERS).doc(driverId).get();

  if (!driverRef.exists) {
    return undefined;
  }

  const driverData = driverRef.data() || {};

  const driver: Driver = {
    id: driverRef.id,
    firstName: driverData.firstName,
    lastName: driverData.lastName,
    email: driverData.email,
    driverLicense: driverData.driverLicense || '',
  };

  return driver;
};
