import React from 'react';
import classnames from 'classnames';

import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './AppNavLink.module.scss';

export type NavLinkProps = {
  id?: string;
  label: string;
  route?: string;
  dropdown?: boolean;
  options?: {
    id?: string;
    label: string;
    route?: string;
  }[];
};

interface Props extends RouteComponentProps {
  id: string;
  label: string;
  route?: string;
}

const AppNavLink: React.FC<Props> = ({ id, label, route, location }: Props) => {
  const isActive = location.pathname === route;
  const active = isActive ? 'active' : '';

  return (
    <li className={`nav-item ${active}`}>
      <Link
        id={id}
        data-test-id={id}
        className={classnames('nav-link', styles.appNavLink, styles.navLink)}
        to={route || '#'}
      >
        {label}
        <span className="sr-only">(current)</span>
      </Link>
    </li>
  );
};

export default withRouter(AppNavLink);
