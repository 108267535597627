/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { StateProps } from 'reducers';
import { findLicense, findRecipientLicense } from 'services';
import { OrderStepperStateProps } from 'reducers/orderStepper';
import * as OrderStepperActions from 'actions/orderStepper';
import { OrderStepperActionsProps } from 'actions/types/orderStepper';
import { Destination, Order, Recipient } from 'types';
import { DraggableMarkerMap } from 'components/Map';
import { TextField, CheckBoxField, DestinationSelectField, TextSearchField } from 'components/FormFields';
import { OptionType } from 'components/Select';
import { SelectField } from 'components/FormFields';
import { MapMarker } from 'components/Map/DraggableMarkerMap';
import { isEmpty } from 'lodash';
import { useAuth } from 'contexts/auth';
import { useGeocodeAddress } from 'services/hooks/useGeocodeAddress';

interface Props extends OrderStepperStateProps, OrderStepperActionsProps {
  order?: Order;
  recipients: Array<Recipient>;
  destinations: Array<Destination>;
  destinationsOptions: (showNew?: boolean) => Array<OptionType>;
  isValid: boolean;
  errors: any;
}

const PickUpForm: React.FC<Props> = ({
  // State
  order,
  pickup,
  // Props
  recipients,
  destinations,
  destinationsOptions,
  isValid,
  errors,
  // Actions
  onPickupAddressChanged,
  onPickupRecipientChanged,
  onPickupDateChanged,
  onBillingAddressChanged,
  onPickupAddressAsBillingChanged,
  onIsFreshFrozenTransport,
  onNewPickupRecipient,
  isGuestView,
}: Props) => {
  const { currentUser } = useAuth();
  const { geocodeAddress } = useGeocodeAddress();
  const {
    pickupAddress,
    requestedPickup,
    pickupRecipient,
    pickupAddressAsBilling,
    isNewPickupRecipient,
    isFreshFrozenTransport,
  } = pickup;

  useEffect(() => {
    if (isGuestView || !pickupRecipient?.metrcLicenseNumber) {
      onNewPickupRecipient();
    }

    const originInfo = order?.originInfo || pickup?.pickupAddress;
    if (originInfo) {
      onPickupAddressChanged(originInfo);
    }

    const senderInfo = order?.senderInfo || pickup.pickupRecipient;
    if (senderInfo) {
      onPickupRecipientChanged(senderInfo);
    }

    if (order?.requestedPickup) {
      const formattedDate = moment.unix(order.requestedPickup).format('YYYY-MM-DDThh:mm');
      onPickupDateChanged(formattedDate);
    }

    if (order?.billingAddressId) {
      onBillingAddressChanged(order?.billingAddressId);
    }

    if (order?.billingAddressId) {
      onBillingAddressChanged(order?.billingAddressId);
    }

    const isFreshFrozenTransportValue = order?.isFreshFrozenTransport || pickup.isFreshFrozenTransport;
    onIsFreshFrozenTransport(isFreshFrozenTransportValue ?? false);

    onPickupAddressAsBillingChanged(order?.originId === order?.billingAddressId);
  }, [order]);

  const onSearchMetrcLicense = async (metrcLicenseNumber: string): Promise<void> => {
    if (!currentUser) {
      return;
    }

    const license = await findRecipientLicense(metrcLicenseNumber);
    const { recipientId, recipients, destinations = [] } = currentUser;

    if (license?.recipientId && license.owner === recipientId) {
      const recipient = recipients?.find(r => r.id === license.recipientId);

      if (recipient) {
        const defaultDestination = destinations.find(d => d.id === license.destinationId);

        onPickupRecipientChanged({
          ...recipient,
          metrcLicenseNumber: license.number,
        });

        defaultDestination && onPickupAddressChanged(defaultDestination);
      }
    } else {
      const license = await findLicense(metrcLicenseNumber);

      if (!license) {
        return;
      }

      const { destination: address } = license;
      const destination = await geocodeAddress(
        `${address?.line1} ${address?.city} ${address?.state} ${address?.postalCode}`,
      );

      if (license && pickup.pickupRecipient) {
        onPickupRecipientChanged({
          ...pickup.pickupRecipient,
          name: license.businessName,
        });

        destination && onPickupAddressChanged(destination);
      }
    }
  };

  const onPickupAddressChange = (option: OptionType): void => {
    if (option.value === 'new') {
      onNewPickupRecipient();
      return;
    }

    const [recipientId, destinationId] = (option.value || '').split(',');
    let selectedDestination = destinations.find((d: Destination) => d.id === destinationId);
    let selectedRecipient = recipients.find((r: Recipient) => r.id === recipientId);

    const license = currentUser?.metrcLicenses?.find(
      ml => ml.recipientId === recipientId && ml.destinationId === destinationId,
    );

    if (selectedRecipient && license) {
      selectedRecipient.metrcLicenseNumber = license.number;
    }

    if (!selectedDestination) {
      selectedDestination = pickupRecipient?.destinations?.find((d: Destination) => d.id === destinationId);
    }

    if (!selectedRecipient) {
      selectedRecipient = pickupRecipient;
    }

    selectedDestination && onPickupAddressChanged(selectedDestination);
    selectedRecipient && onPickupRecipientChanged(selectedRecipient);
  };

  const onPickupDestinationChange = (destination: Destination): void => {
    destination && onPickupAddressChanged(destination);
  };

  const onPickupRecipientFieldChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (pickup?.pickupRecipient) {
      onPickupRecipientChanged({
        ...pickup?.pickupRecipient,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onPinChanged = ({ longitude, latitude }: MapMarker): void => {
    if (pickupAddress) {
      onPickupAddressChanged({
        ...pickupAddress,
        location: {
          lon: longitude,
          lat: latitude,
        },
      });
    }
  };

  const marker = useMemo(() => {
    if (pickupAddress) {
      return {
        latitude: pickupAddress?.location.lat,
        longitude: pickupAddress?.location.lon,
      };
    }
  }, [pickupAddress]);

  const prevDestinations = useMemo(() => {
    if (pickupAddress) {
      return [
        {
          value: pickupAddress.id,
          label: pickupAddress.name,
        },
      ];
    }

    return [];
  }, [pickupAddress]);

  const getDestinationOptions = (): Array<OptionType> => {
    const options: Array<OptionType> = [
      {
        value: 'new',
        label: 'Add New',
      },
    ];

    if (pickupRecipient?.id && pickupRecipient?.destinations) {
      const { destinations } = pickupRecipient;

      destinations.forEach(destination => {
        options.push({
          value: `${pickupRecipient.id},${destination.id}`,
          label: `${pickupRecipient.name} - ${destination.name}`,
        });
      });

      return options;
    }

    return destinationsOptions(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h2>Pick-Up</h2>
          <p className="tagline text-secondary">Order Details</p>
        </div>
        <div className="col-12">
          {!isValid && !isEmpty(errors) && (
            <div className="alert alert-primary" role="alert">
              Check the following errors before continue
            </div>
          )}
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="row">
            <div className="col">
              <form className="needs-validation" noValidate>
                <div className="row">
                  <div className="col-12 mb-3">
                    <TextSearchField
                      id="metrcLicenseNumber"
                      name="metrcLicenseNumber"
                      type="text"
                      label="METRC License"
                      placeholder="Enter a METRC License"
                      onSearch={onSearchMetrcLicense}
                      onChange={onPickupRecipientFieldChange}
                      value={pickupRecipient?.metrcLicenseNumber}
                      isValid={!errors?.metrcLicense}
                      feedback={errors?.metrcLicense}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      id="requestedPickup"
                      name="requestedPickup"
                      type="datetime-local"
                      label="Date of Pick-up"
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => onPickupDateChanged(e.target.value)}
                      value={requestedPickup}
                      isValid={!errors?.requestedPickup}
                      feedback={errors?.requestedPickup}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      id="pickupContactName"
                      name="name"
                      type="text"
                      label="Business Name"
                      disabled={!isNewPickupRecipient}
                      onChange={onPickupRecipientFieldChange}
                      value={pickupRecipient?.name}
                      isValid={!errors?.pickupContactName}
                      feedback={errors?.pickupContactName}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      id="pickupPhone"
                      name="phone"
                      type="text"
                      label="Pick-up Phone"
                      disabled={!isNewPickupRecipient}
                      onChange={onPickupRecipientFieldChange}
                      value={pickupRecipient?.phone}
                      isValid={!errors?.pickupPhone}
                      feedback={errors?.pickupPhone}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      id="pickupRecipientEmail"
                      name="email"
                      type="email"
                      label="Email"
                      disabled={!isNewPickupRecipient}
                      onChange={onPickupRecipientFieldChange}
                      value={pickupRecipient?.email}
                      isValid={!errors?.email}
                      feedback={errors?.email}
                    />
                  </div>
                  <div className="col-12">
                    <CheckBoxField
                      id="sameAddressAsBilling"
                      name="sameAddressAsBilling"
                      label="Use same pickup Address as Billing Address"
                      onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                        onPickupAddressAsBillingChanged(e.target.checked)
                      }
                      checked={pickupAddressAsBilling}
                    />
                  </div>
                  <div className="col-12">
                    <CheckBoxField
                      id="isFreshFrozenTransport"
                      name="isFreshFrozenTransport"
                      label="Is a fresh frozen transport?"
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => onIsFreshFrozenTransport(e.target.checked)}
                      checked={isFreshFrozenTransport}
                    />
                  </div>
                  {!pickupAddressAsBilling && (
                    <div className="col-12 mb-3">
                      <SelectField
                        id="billTo"
                        label="Bill To"
                        options={destinationsOptions()}
                        onChange={({ value }): void => onBillingAddressChanged(value)}
                        value={pickup?.billingAddressId}
                        isValid={!errors?.billTo}
                        feedback={errors?.billTo}
                      />
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <div className="row">
            <div className="col-12">
              <div className="col-12 mb-3">
                <SelectField
                  id="pickupAddress"
                  label="Pick-up Address"
                  options={getDestinationOptions()}
                  onChange={(option: OptionType): void => onPickupAddressChange(option)}
                  value={isNewPickupRecipient ? 'new' : `${pickupRecipient?.id},${pickupAddress?.id}`}
                  isValid={!errors?.pickupAddress}
                  feedback={errors?.pickupAddress}
                />
              </div>
            </div>
            <div className="col-12">
              {isNewPickupRecipient && (
                <div className="col-12 mb-3">
                  <DestinationSelectField
                    id="newPickupAddress"
                    label="New Pick-up Address"
                    options={prevDestinations}
                    onChange={onPickupDestinationChange}
                    value={pickupAddress?.id ?? 'new'}
                    isValid={!errors?.pickupAddress}
                    feedback={errors?.pickupAddress}
                    defaultValue={
                      pickupAddress
                        ? {
                            label: pickupAddress?.name,
                            value: 'new',
                          }
                        : undefined
                    }
                    clearable
                  />
                </div>
              )}
            </div>
            <div className="col-12">
              <DraggableMarkerMap marker={marker} disabled={!isNewPickupRecipient} onPinChanged={onPinChanged} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ orderStepper }: StateProps): any => ({
  pickup: orderStepper.pickup,
});

const mapDispatchToProps = { ...OrderStepperActions };

const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(PickUpForm);

export default Connected;
