import { Address } from 'types';

export const currencyFormat = (number: number): string =>
  new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number);

export const getFormattedAddress = (address: Address): string => {
  if (!address) {
    return '';
  }

  const { line1, country, postalCode, state } = address;
  const items = [line1, state, postalCode, country];

  return items.filter(x => x && x.length > 0).join(', ');
};
