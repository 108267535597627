import React, { useState, useEffect } from 'react';

import { validateToken, createWidgetElement, loadScript } from 'services/recaptcha';

declare let window: ExtendedWindow;

export interface ExtendedWindow extends Window {
  captchaOnLoad: any;
  grecaptcha: any;
}

export interface Props {
  action: string;
  children: (props: CaptchaProps) => JSX.Element;
}

export interface CaptchaProps {
  isReady: boolean;
  execute: () => Promise<any>;
}

export interface State {
  readonly isReady: boolean;
}

const ReCaptcha: React.FC<Props> = ({ action, children }: Props) => {
  const [state, setState] = useState<State>({ isReady: false });

  const executeCaptcha = async (): Promise<any> => {
    if (!state.isReady) {
      throw new Error('Captcha is not ready.');
    }

    const token = await window.grecaptcha.execute({
      action: action,
    });

    return await validateToken(token);
  };

  useEffect(() => {
    let recaptchaWidget!: HTMLDivElement;

    const onLoad = (): void => {
      recaptchaWidget = createWidgetElement(() => setState({ isReady: true }));
    };

    const recaptchaScript: HTMLScriptElement = loadScript(onLoad);
    //clenup function
    return (): void => {
      recaptchaWidget && document.body.removeChild(recaptchaWidget);
      recaptchaScript && document.body.removeChild(recaptchaScript);
    };
  }, []);

  return children({
    isReady: state.isReady,
    execute: executeCaptcha,
  });
};

export default ReCaptcha;
