import React from 'react';
import style from './SimpleCard.module.css';

export interface SimpleCardProps {
  className?: string;
  children: JSX.Element;
  title: string;
  transactionType?: string;
}

const SimpleCard: React.FC<SimpleCardProps> = ({ title, children, className, transactionType }: SimpleCardProps) => {
  return (
    <>
      <div className={className}>
        <h2 className="text-primary">
          {title}
          {transactionType && <span className={style.badge}>{transactionType}</span>}
        </h2>
        {children}
      </div>
    </>
  );
};

export default SimpleCard;
