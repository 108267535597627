import React from 'react';
import cls from 'classnames';
import { capitalize } from 'lodash';
import { OrderStatus, OrderRow } from 'types';
import { CustomCell } from 'components/CustomDataTable';

import style from '../OrderHistory.module.scss';
import { formatShortDateTime } from 'utils';

type Props = {
  onRowClicked: (row: OrderRow) => void;
};

const columns = ({ onRowClicked }: Props): any => [
  {
    name: '#',
    selector: 'orderNumber',
    maxWidth: '5%',
    minWidth: '5%',
    sortable: true,
  },
  {
    name: 'Status',
    selector: 'status',
    maxWidth: '10%',
    sortable: true,
    cell: (row: OrderRow): any => <CustomCell onClick={(): any => onRowClicked(row)} content={statusCell(row)} />,
  },
  {
    name: 'Type',
    selector: 'orderType',
    maxWidth: '15%',
    sortable: true,
    cell: (row: OrderRow): any => <CustomCell onClick={(): any => onRowClicked(row)} content={row.orderType} />,
  },
  {
    name: 'Origin',
    cell: (row: OrderRow): any => (
      <CustomCell onClick={(): any => onRowClicked(row)} title={row.origin} content={row.origin} />
    ),
  },
  {
    name: 'Destination',
    grow: 2,
    cell: (row: OrderRow): any => (
      <CustomCell onClick={(): any => onRowClicked(row)} title={row.destination} content={row.destination} />
    ),
  },
  {
    name: 'Created Date',
    selector: 'createdAt',
    sortable: true,
    cell: (row: OrderRow): any => {
      const { createdAt } = row;
      return (
        <CustomCell
          onClick={(): any => onRowClicked(row)}
          title={formatShortDateTime(createdAt)}
          content={formatShortDateTime(createdAt)}
        />
      );
    },
  },
];

const statusCell = (row: OrderRow): any => {
  const statusCellConfig: any = {
    [OrderStatus.DRAFT]: ['far fa-circle', '#AAAEB3'],
    [OrderStatus.CREATED]: ['far fa-circle', '#AAAEB3'],
    [OrderStatus.ACCEPTED]: ['fas fa-circle', '#425CBB'],
    [OrderStatus.PROCESSING]: ['fas fa-circle', '#425CBB'],
    [OrderStatus.PROGRESS]: ['far fa-circle', '#425CBB'],
    [OrderStatus.COMPLETED]: ['fas fa-circle', '#7AC741'],
  };

  const [icon, color] = statusCellConfig[row.status];

  return (
    <div>
      <i style={{ color }} className={cls(icon, style.statusIcon)}></i>
      {capitalize(row.status)}
    </div>
  );
};

export default columns;
