import React from 'react';
import { withAuthorization, withPageView } from 'components';
import { OrderCashStepper, OrderStepper } from 'components/Stepper';
import { useLocation } from 'react-router-dom';

interface NewOrderPageProps {
  isGuestView: boolean;
}

const NewOrder: React.FC<NewOrderPageProps> = ({ isGuestView }: NewOrderPageProps) => {
  const { search } = useLocation();
  const params = new URLSearchParams(search);

  if (params.get('type') === 'cash') {
    return <OrderCashStepper isNew isGuestView={isGuestView} />;
  }

  return <OrderStepper isNew isGuestView={isGuestView} />;
};

const GuestView = withPageView(NewOrder, 'Transport Request');

export default withAuthorization(() => GuestView({ isGuestView: false }));
export { GuestView };
