import React from 'react';
import DataTable from 'react-data-table-component';

export type Props = {
  columns: Array<any>;
  data: Array<any>;
  customStyles?: any;
  onRowClicked?: (data: any) => void;
  [otherProp: string]: any;
};

const baseStyles = {
  cells: {
    style: {
      paddingRight: 0,
    },
  },
  headCells: {
    style: {
      fontWeight: '600',
      textTransform: 'uppercase',
      letterSpacing: '1px',
      color: '#425CBB',
      borderBottom: '2px solid #425CBB',
    },
  },
  headRow: {
    style: {
      backgroundColor: '#fff',
    },
  },
};

const CustomDataTable: React.FC<Props> = ({ columns, data, onRowClicked, customStyles, ...restProps }: Props) => {
  return (
    <DataTable
      columns={columns}
      data={data}
      customStyles={{ ...baseStyles, ...customStyles }}
      onRowClicked={onRowClicked}
      sortIcon={<i className="fas fa-sort"></i>}
      paginationPerPage={25}
      paginationRowsPerPageOptions={[25, 50, 100]}
      pagination
      noHeader
      {...restProps}
    />
  );
};

CustomDataTable.defaultProps = {
  customStyles: {},
};

export default CustomDataTable;
