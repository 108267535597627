import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { EmailField } from 'pages/Login/components';
import { ResetPasswordData } from 'types/auth';
import { login as loginRoute } from 'constants/routes';

import styles from 'components/Layout/AuthLayout.module.scss';

type Props = {
  onSubmit: (signInData: ResetPasswordData) => void;
};

const ResetPasswordForm: React.FC<Props> = ({ onSubmit }: Props) => {
  const [email, setEmail] = useState<string>('');

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    onSubmit({
      email,
    });
  };

  return (
    <form
      id="reset-form"
      className="d-flex flex-column justify-content-center align-items-center"
      onSubmit={handleSubmit}
    >
      <EmailField className={classnames('form-control', styles.form_input)} onChange={onUsernameChange} value={email} />
      <div className={classnames('d-flex align-items-center', styles.form_actions)}></div>
      <button id="reset-submit-btn" className={styles.form_submit} type="submit">
        Reset Password
      </button>
      <br />
      <br />
      <Link to={loginRoute} className="text-dark mb-2">
        Login
      </Link>
    </form>
  );
};

export default ResetPasswordForm;
