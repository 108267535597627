import {
  Destination,
  Order,
  OrderItem,
  Recipient,
  OrderDeliveryItem,
  CashTransactionType,
  OrderCashTransportItem,
  DepositType,
  CashChange,
  LocationType,
} from 'types';
import { AttachmentInfo } from './attachmentInfo';

export type Contact = {
  name: string;
  email: string;
  phone: string;
};
export interface OrderRequest
  extends Pick<Order, 'requestedPickup' | 'billingAddressId' | 'reportUrl'>,
    Pick<OrderDeliveryItem, 'requestedDelivery'> {
  id?: string;
  transactionType?: CashTransactionType;
  depositType?: DepositType | null;
  status: OrderRequestStatus;
  cashChange?: CashChange;
  requestNumber?: number;
  createdAt?: number;
  financialInstitutionId?: string;
  pickupAddressAsBilling?: boolean;
  isFreshFrozenTransport?: boolean;
  items?: Array<OrderItem | OrderCashTransportItem>;
  contactInfo?: Contact;
  creator?: string;
  senderInfo?: Recipient;
  originInfo?: Destination;
  recipientInfo?: Recipient;
  destinationInfo?: Destination;
  attachments: AttachmentInfo;
  // New props
  taxPercentage?: number;
  senderId?: string;
  originId?: string;
  recipientId?: string;
  destinationId?: string;
  source?: string;
  senderLicenseId?: string;
  recipientLicenseId?: string;
  processed?: boolean;
  orderReferenceId: string;
  orderReference?: any;
  isNew?: boolean;
  isDraftChild?: boolean;
  parentId: string | null;
  type: 'cash' | 'package';
  locationSource?: LocationType;
  locationTarget?: LocationType;
  isNewPickupAddress?: boolean;
  isNewDeliveryAddress?: boolean;
  orderSize?: string;
}

export enum OrderRequestStatus {
  DRAFT = 'DRAFT',
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  DENIED = 'DENIED',
}
