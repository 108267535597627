import React from 'react';
import Select, { OptionType } from 'components/Select';
import Filter from 'components/Filters';
import { OrderDateFilterOptions } from 'types';

type Props = {
  className?: string;
  onChange: (option: OptionType) => void;
  value?: any;
};

const options = [
  { value: OrderDateFilterOptions.ALL, label: 'All' },
  { value: OrderDateFilterOptions.THIS_WEEK, label: 'This Week' },
  { value: OrderDateFilterOptions.TODAY, label: 'Today' },
];

const OrderDateFilter: React.FC<Props> = ({ className, onChange, value }: Props) => {
  return (
    <Filter name="Created Date" selector="created-date">
      {/* prettier-ignore */}
      <Select
        className={className}
        options={options}
        placeholder="Select an option"
        onChange={onChange}
        value={value}
      />
    </Filter>
  );
};

export default OrderDateFilter;
