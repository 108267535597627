import React from 'react';
import { AttachmentInfo, FileInfo } from 'types';
import AttachmentFiles from './AttachmentFiles';

export type Props = {
  attachments: AttachmentInfo;
};

const AttachmentDetails: React.FC<Props> = ({ attachments }: Props) => {
  const { note, isInvoiceRequired, invoiceFile, otherFiles } = attachments;
  const invoiceFiles: Array<FileInfo> = [];
  if (isInvoiceRequired && invoiceFile) {
    invoiceFiles.push(invoiceFile);
  }
  const orderRequestNote = note ? note : 'This order does not include a note';
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column mt-3">
            <span className="label">Note</span>
            <span className="font-light">{orderRequestNote}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column mt-3">
            <span className="label">Include Invoice?</span>
            <span className="font-light">{isInvoiceRequired ? 'Yes' : 'No'}</span>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          {isInvoiceRequired && invoiceFiles.length > 0 && (
            <div className="d-flex flex-column mt-3">
              <AttachmentFiles title="Invoice File" files={invoiceFiles} />
            </div>
          )}
        </div>
      </div>
      <div className="row">
        <div className="col">
          <div className="d-flex flex-column mt-3">
            <AttachmentFiles title="Other files" files={otherFiles} />
          </div>
        </div>
      </div>
    </>
  );
};

export default AttachmentDetails;
