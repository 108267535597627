import { firestore } from '_firebase';
import * as collections from 'constants/collections';
import { SettingsMapper } from 'mappers';
import { Settings } from 'types/settings';

export const getSettings = async (): Promise<Settings | null> => {
  const settingsRef = await firestore.collection(collections.SETTINGS).get();

  if (!settingsRef.empty) {
    return SettingsMapper(settingsRef);
  }

  return null;
};
