/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import Alert from 'types/alert';

const Alerts = createContext<Alert>({
  info: (): void => {},
  success: (): void => {},
  warning: (): void => {},
  error: (): void => {},
});

export default Alerts;
