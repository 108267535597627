import React, { useContext } from 'react';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { signOut } from 'services/auth';
import { AuthContext } from 'contexts';
import { Spinner } from 'components/Loaders';
import * as routes from 'constants/routes';
import * as roles from 'constants/roles';

export default function WithAuthorization<Props extends RouteComponentProps>(
  WrappedComponent: React.ComponentType<Props>,
): any {
  const WithAuth = (props: Props): React.ReactElement => {
    const { currentUser } = useContext(AuthContext);

    if (currentUser === null) {
      return <Redirect to={routes.login} />;
    }

    if ((currentUser?.role && currentUser.role !== roles.CUSTOMER) || currentUser?.disabled) {
      signOut();
    }

    if (currentUser?.uid) {
      return <WrappedComponent {...(props as Props)} />;
    }

    return <Spinner fullScreen message="Initializing application ..." />;
  };

  return withRouter(WithAuth);
}
