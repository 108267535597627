import React, { useEffect, ChangeEvent, useState } from 'react';
import classnames from 'classnames';
import { Status, SignUpData } from 'types/auth';

import styles from 'components/Layout/AuthLayout.module.scss';
import { TextField } from 'components/FormFields';
import { SUCCESS } from 'constants/strings';
import * as routes from 'constants/routes';

type Props = {
  error: (message: string) => void;
  success: (message: string) => void;
  onSubmit: (signUpData: SignUpData) => any;
  redirectTo: (path: string) => void;
};

const SignUpForm: React.FC<Props> = ({ success, error, onSubmit, redirectTo }: Props) => {
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [userData, setUserData] = useState<SignUpData>({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    licenseNumber: '',
    status: Status.Pending,
  });

  useEffect(() => {
    const isFirstNameValid = userData.firstName !== '';
    const isLastNameValid = userData.lastName !== '';
    const isEmailValid = userData.email !== '';
    const isPhoneNumberValid = userData.phoneNumber !== '';
    const isLicenseNumberValid = userData.licenseNumber !== '';
    const isValid = isFirstNameValid && isLastNameValid && isEmailValid && isPhoneNumberValid && isLicenseNumberValid;
    setIsValidForm(isValid);
  }, [userData]);

  const onFieldChange = (key: string, value: string): void => {
    setUserData(prevState => ({
      ...prevState,
      [key]: value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<any> => {
    e.preventDefault();
    try {
      setIsValidForm(false);
      const response = await onSubmit(userData);
      if (response.code !== SUCCESS) {
        error(response.message);
      } else {
        success(response.message);
        setTimeout(() => {
          redirectTo(routes.login);
        }, 1500);
      }
      setIsValidForm(true);
    } catch (e) {
      setIsValidForm(true);
      console.log(e);
    }
  };

  return (
    <form
      id="sign-up-form"
      className="needs-validation"
      onSubmit={(e: React.FormEvent<HTMLFormElement>): Promise<void> => handleSubmit(e)}
    >
      <div className="form-group">
        <TextField
          id="firstName"
          name="firstName"
          type="text"
          label="First Name"
          onChange={(e: ChangeEvent<HTMLInputElement>): void => onFieldChange('firstName', e.target.value)}
          value={userData.firstName}
          required
        />
      </div>
      <div className="form-group">
        <TextField
          id="lastName"
          name="lastName"
          type="text"
          label="Last Name"
          onChange={(e: ChangeEvent<HTMLInputElement>): void => onFieldChange('lastName', e.target.value)}
          value={userData.lastName}
          required
        />
      </div>
      <div className="form-group">
        <TextField
          id="email"
          name="email"
          type="email"
          label="Email"
          onChange={(e: ChangeEvent<HTMLInputElement>): void => onFieldChange('email', e.target.value)}
          value={userData.email}
          required
        />
      </div>
      <div className="form-group">
        <TextField
          id="phoneNumber"
          name="phoneNumber"
          type="text"
          label="Phone Number"
          onChange={(e: ChangeEvent<HTMLInputElement>): void => onFieldChange('phoneNumber', e.target.value)}
          value={userData.phoneNumber}
          required
        />
      </div>
      <div className="form-group">
        <TextField
          id="licenseNumber"
          name="licenseNumber"
          type="text"
          label="License Number"
          onChange={(e: ChangeEvent<HTMLInputElement>): void => onFieldChange('licenseNumber', e.target.value)}
          value={userData.licenseNumber}
          required
        />
      </div>
      <div className="text-center">
        <button
          id="signup-submit-btn"
          className={classnames('btn btn-primary', styles.form_submit)}
          type="submit"
          disabled={!isValidForm}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default SignUpForm;
