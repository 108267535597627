import { firestore } from '_firebase';
import { Recipient, MRALicense } from 'types/recipient';

import * as collections from 'constants/collections';
import { RecipientMapper } from 'mappers';
import { emailEndpoint } from 'config';
import { Destination } from 'types/destination';

export const getRecipients = async (): Promise<Array<Recipient>> => {
  // prettier-ignore
  const recipientsRef = await firestore
    .collection(collections.RECIPIENTS)
    .get();

  if (!recipientsRef.empty) {
    return RecipientMapper(recipientsRef);
  }

  return [];
};

/**
 * @param {*} email
 */
export const validCustomerEmail = async (email: string): Promise<any> => {
  try {
    const response: Response = await fetch(`${emailEndpoint.validCustomerEmailEndpoint}`, {
      method: 'POST',
      body: JSON.stringify({ email: email }),
      headers: { 'Content-Type': 'application/json' },
    });
    return await response.json();
  } catch (e) {
    return false;
  }
};

export const getRecipientById = async (recipientId: string): Promise<Recipient | undefined> => {
  const recipientRef = await firestore.collection(collections.RECIPIENTS).doc(recipientId).get();

  if (!recipientRef.exists) {
    return undefined;
  }

  const recipientData = recipientRef.data() as Recipient;

  const recipient: Recipient = {
    ...recipientData,
    id: recipientRef.id,
    isNew: !recipientData.email && !recipientData.metrcLicenseNumber && !recipientData.name && !recipientData.phone,
  };

  if (recipient && recipient.billingAddressId) {
    const destinationRef = await firestore.collection(collections.DESTINATIONS).doc(recipient.billingAddressId).get();

    if (destinationRef.exists) {
      const destinationData = destinationRef.data() || {};

      const destination: Destination = {
        id: destinationRef.id,
        name: destinationData.name,
        active: destinationData.active,
        address: destinationData.address,
        location: destinationData.location,
        owner: destinationData.owner,
        photoUrl: destinationData.photoUrl,
      };

      recipient.destinationInfo = destination;
    }
  }

  return recipient;
};

export const getRecipientByMetrcLicense = async (metrcLicense: string): Promise<Recipient | undefined> => {
  const license = await findRecipientLicense(metrcLicense);

  if (license?.recipientId) {
    const recipient = await getRecipientById(license.recipientId);

    if (recipient) return recipient;
  }

  return undefined;
};

export const findRecipientLicense = async (number: string): Promise<MRALicense | null> => {
  const licenseRef = await firestore.collection(collections.MRA_LICENSES).where('number', '==', number).get();

  if (!licenseRef.empty) {
    const [doc] = licenseRef.docs;

    return {
      ...doc.data(),
      id: doc.id,
    } as MRALicense;
  }

  return null;
};

export const findLicense = async (number: string): Promise<any> => {
  const licenseRef = await firestore.collection(collections.LICENSES).doc(number).get();

  if (licenseRef.exists) {
    const doc = licenseRef.data();

    return {
      ...doc,
      id: licenseRef.id,
    } as MRALicense;
  }

  return null;
};

export const createMRALicense = async (data: MRALicense): Promise<string> => {
  const license = await findRecipientLicense(data.number);

  if (license?.id) {
    return license.id;
  }

  const newLicense = await firestore.collection(collections.MRA_LICENSES).add(data);
  return newLicense.id;
};

export const createRecipient = async (data: Recipient): Promise<string> => {
  const recipient = await firestore.collection(collections.RECIPIENTS).add(data);
  return recipient.id;
};

export const getRecipientsByOwner = async (ownerId: string): Promise<Array<Recipient>> => {
  const recipientRef = await firestore.collection(collections.RECIPIENTS).where('ownerId', '==', ownerId).get();
  const recipients: Array<Recipient> = [];

  if (!recipientRef.empty) {
    for (const doc of recipientRef.docs) {
      const recipient = doc.data() as Recipient;
      if (!recipient.isFinancialInstitution) {
        recipients.push({
          ...recipient,
          id: doc.id,
        });
      }
    }
  }

  return recipients;
};

export const getFinancialInstitutionByOwner = async (ownerId: string): Promise<Array<Recipient>> => {
  const recipientRef = await firestore.collection(collections.RECIPIENTS).where('ownerId', '==', ownerId).get();
  const recipients: Array<Recipient> = [];

  if (!recipientRef.empty) {
    for (const doc of recipientRef.docs) {
      const recipient = doc.data() as Recipient;
      if (recipient.isFinancialInstitution) {
        recipients.push({
          ...recipient,
          id: doc.id,
        });
      }
    }
  }

  return recipients;
};

export const getCustomerLicenses = async (owner: string): Promise<Array<MRALicense>> => {
  const ref = await firestore.collection(collections.MRA_LICENSES).where('owner', '==', owner).get();
  const metrcLicenses: Array<MRALicense> = [];

  if (!ref.empty) {
    for (const doc of ref.docs) {
      const license = doc.data() as MRALicense;

      metrcLicenses.push({
        id: doc.id,
        ...license,
      });
    }
  }

  return metrcLicenses;
};
