import React, { useEffect, ChangeEvent } from 'react';
import { isEmpty } from 'lodash';
import style from './FileManager.module.css';
import { FileInfo } from 'types';
import cls from 'classnames';

type FileManagerProps = {
  inputId: string;
  title?: string;
  isShowing?: boolean;
  isMultiple?: boolean;
  feedback?: string;
  filesData?: Array<FileInfo> | null;
  onSelectedFiles: (data: any) => void;
  onRemoveFile: (data: any) => void;
};

const FileManager: React.FC<FileManagerProps> = (props: FileManagerProps) => {
  const { inputId, title, isShowing, isMultiple, feedback, filesData, onSelectedFiles, onRemoveFile } = props;

  useEffect(() => {
    if (filesData === undefined || isEmpty(filesData) || filesData === null) {
      (document.getElementById(inputId) as HTMLInputElement).value = '';
    }
  }, [filesData, inputId]);

  const handleFileInput = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    const { files: inputFiles } = target;
    if (inputFiles) onSelectedFiles(inputFiles);
  };

  const Feedback = (): React.ReactNode => {
    if (feedback) {
      return <span className={'invalid-feedback d-block'}>{feedback}</span>;
    }

    return null;
  };

  if (!isShowing) {
    return null;
  }

  return (
    <div>
      <label className={style.title}>{title}</label>
      {Feedback()}
      <div className={style.container}>
        <div className={style.upload_input}>
          <input
            id={inputId}
            type="file"
            className={style.file_input}
            onChange={handleFileInput}
            multiple={isMultiple}
          />
          <label className={cls('button btn btn-primary', style.custom_buttom)} htmlFor={inputId}>
            {isMultiple ? 'Select files' : 'Select file'}
          </label>
        </div>
        <div className={style.uploadesFiles}>
          {filesData && (
            <ul className={style.list_group_files}>
              {filesData
                .filter(file => !file.shouldBeRemoved)
                .map((item: FileInfo, index: number) => (
                  <li key={index} className={style.list_group_file_item}>
                    {item.fileName}
                    <span className="badge badge-primary badge-pill" onClick={(): void => onRemoveFile(index)}>
                      <i className="fas fa-trash fa-md"></i>
                    </span>
                  </li>
                ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

FileManager.defaultProps = {
  title: 'File Uploaer',
  isShowing: true,
  isMultiple: true,
};

export default FileManager;
