import React from 'react';
import { AlertType } from 'types/alert';

type AlertProps = {
  isShowing?: boolean;
  message?: string;
  type?: AlertType;
  children?: JSX.Element;
};

const Alert: React.FC<AlertProps> = ({ message, isShowing, type, children }: AlertProps) => {
  if (!isShowing) {
    return null;
  }

  return (
    <div className={`alert alert-${type} fade show`} role="alert">
      {message || children}
    </div>
  );
};

export default Alert;
