import React from 'react';
import { CollapseHeader, CollapseContent } from './index';

type wrappedComponentTypes = { data: any; itemIndex: number };
interface Props {
  id: string;
  title: string;
  data: Array<any>;
  component: React.ComponentType<wrappedComponentTypes>;
  invalidSteps?: Array<number>;
  onRemoveItem?: (index: number) => void;
}

const Collapse: React.FC<Props> = ({
  id,
  title,
  data,
  component: WrappedComponent,
  invalidSteps,
  onRemoveItem,
}: Props) => {
  return (
    <div id={id} className="accordion">
      {data.map((itemData, index) => {
        const shouldRoundBottom = index === data.length - 1 && data.length > 1;
        return (
          <div className="panel panel-default" key={index}>
            <CollapseHeader
              index={index}
              shouldRoundBottom={shouldRoundBottom}
              title={`${title} #${index + 1}`}
              onRemoveItem={onRemoveItem}
              invalid={invalidSteps?.includes(index)}
            />
            <CollapseContent collapsed={index < data.length - 1 && data.length > 1} id={id} index={index}>
              <WrappedComponent data={itemData} itemIndex={index} />
            </CollapseContent>
          </div>
        );
      })}
    </div>
  );
};
export default Collapse;
