import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Alert } from 'components/Alerts';
import { TransactionStep } from '../../OrderCashRequest/Steps';
import { OptionType } from 'components/Select';
import { Stepper, StepperFeedback } from 'components/Stepper';
import withAlert from 'components/WithAlert';
import { dashboard } from 'constants/routes';
import { USER_HAS_NOT_RECIPIENT_ASSOCIATED } from 'constants/strings';
import { AuthContext } from 'contexts';
import { StateProps } from 'reducers';
import { OrderStepperDeliveryStateProps, OrderStepperStateProps } from 'reducers/orderStepper';
import { saveOrderRequest, getOrderReference, getFinancialInstitutionDestination } from 'services';
import { useGetOrderById } from 'services/hooks/orders';
import * as OrderStepperActions from 'actions/orderStepper';
import { OrderStepperActionsProps } from 'actions/types/orderStepper';
import {
  OrderRequest,
  OrderRequestStatus,
  UploadedFileModel,
  AttachmentInfo,
  FileInfo,
  SelectedFileInfo,
  OrderCashTransportItem,
  CashChange,
} from 'types';
import Alerts, { AlertType } from 'types/alert';
import { uploadFile, deleteFile } from 'services/fileService';
import { OrderSteps } from 'types/orderContext';
import { Spinner } from 'components/Loaders';
import { useIsLoading } from 'services/hooks/useIsLoading';
import { AttachmentForm, CashChangeForm } from 'components/Forms';
import { CASH_CHANGE_DROPS, CASH_DEPOSIT } from 'constants/transactions';

interface Props extends OrderStepperStateProps, OrderStepperActionsProps, Alerts {
  id: string;
}

enum RequestStatus {
  IDLE = 'idle',
  SUCCESS = 'success',
  ERROR = 'error',
}

const stepBaseState = {
  isValid: false,
  errors: {},
};

const OrderCashStepper: React.FC<Props> = ({
  // State
  id,
  isGuestView,
  contact,
  transactionType,
  depositType,
  amount,
  financialInstitutionId,
  locationSource,
  locationTarget,
  pickup,
  deliveries,
  cashChange,
  onCleanOrder,
  // Utilities
  success,
}: Props) => {
  const { currentUser } = useContext(AuthContext);
  const {
    recipients = [],
    financialInstitutions = [],
    financialInstitution,
    destinations = [],
    financialDestinations = [],
  } = currentUser || {};
  const { order } = useGetOrderById(id);
  const history = useHistory();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.IDLE);
  const [orderId, setOrderId] = useState<string>(id);
  const [alert, setAlert] = useState<string | boolean>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savingOrdersMsg, setSavingOrdersMsg] = useState<string>('Saving orders...');
  const [stepperState, setStepperState] = useState({
    [OrderSteps.TRANSACTION]: stepBaseState,
    [OrderSteps.CASH_CHANGE]: stepBaseState,
    [OrderSteps.ATTACHMENT]: stepBaseState,
  });
  const { transactionStep, cashChangeStep, attachmentStep } = stepperState;
  const [attachmentFileInfo, setAttachmentFileInfo] = useState<SelectedFileInfo>();
  const { isLoading } = useIsLoading();
  const deliveryIndex = 0;
  const attachments = deliveries[deliveryIndex].attachments;
  const isGuestUser = isGuestView || !currentUser;

  useEffect(() => {
    return (): void => {
      onCleanOrder();
    };
  }, [onCleanOrder]);

  useEffect(() => {
    if (!currentUser?.recipientId && !isGuestUser) {
      setAlert(USER_HAS_NOT_RECIPIENT_ASSOCIATED);
    }
  }, [currentUser, isGuestUser]);

  const validateStep = (step: OrderSteps, errorList: any): boolean => {
    const isValid = isEmpty(errorList);

    setStepperState({
      ...stepperState,
      [step]: {
        isValid,
        errors: errorList,
      },
    });

    return isValid;
  };

  const destinationsOptions = (finacialInstitution = false, newOption = false): Array<OptionType> => {
    const options: Array<OptionType> = [];
    const list = finacialInstitution ? financialInstitutions : recipients;
    const destinationsList = finacialInstitution ? financialDestinations : destinations;

    list.forEach(recipient => {
      const filterDestinations = destinationsList.filter(destination => destination.owner === recipient.id);

      for (const destination of filterDestinations) {
        const value = `${recipient.id},${destination.id}`;

        if (!options.find(option => option.value === value)) {
          options.push({
            value: value,
            label: `${recipient.name} - ${destination.name}`,
          });
        }
      }
    });

    if (newOption) {
      options.push({
        value: 'new',
        label: 'Add New',
      });
    }

    return options;
  };

  const validateTransactionStep = (): boolean => {
    const errorList = new Map();

    if (isEmpty(transactionType)) {
      errorList.set('transactionType', 'Transaction type is missing');
    }

    if (isEmpty(locationSource)) {
      errorList.set('locationSource', 'From is missing');
    }

    if (isEmpty(locationTarget)) {
      errorList.set('locationTarget', 'To is missing');
    }

    if (isEmpty(financialInstitutionId)) {
      errorList.set('financialInstitutionId', 'Financial Institution is missing');
    }

    if (transactionType === CASH_DEPOSIT && !amount) {
      errorList.set('amount', 'Amount is missing');
    }

    if (transactionType === CASH_DEPOSIT && [locationSource, locationTarget].includes('addressBook')) {
      validatePickupFields(errorList);
    }

    if (transactionType === CASH_DEPOSIT) {
      validatePickupDate(errorList);
    }

    if (transactionType === CASH_CHANGE_DROPS && [locationSource, locationTarget].includes('addressBook')) {
      validateDeliveryFields(errorList);
    }

    if (transactionType === CASH_CHANGE_DROPS) {
      validateDeliveryDate(errorList);
    }

    return validateStep(OrderSteps.TRANSACTION, Object.fromEntries(errorList));
  };

  const validatePickupFields = (errorList: Map<string, string>): void => {
    if ((!pickup?.pickupAddress?.id && !pickup.isNewPickupRecipient) || (isGuestUser && !pickup?.pickupAddress)) {
      errorList.set('pickupAddress', 'Enter a pickup address or create a new one');
    }
    if (pickup.isNewPickupRecipient) {
      if (isEmpty(pickup?.pickupRecipient?.name)) {
        errorList.set('pickupContactName', 'Pickup contact name is missing');
      }

      if (isEmpty(pickup?.pickupRecipient?.phone)) {
        errorList.set('pickupPhone', 'Pickup contact phone is missing');
      }

      if (isEmpty(pickup?.pickupRecipient?.email)) {
        errorList.set('email', 'Email is mising');
      }
    }
  };

  const validatePickupDate = (errorList: Map<string, string>): void => {
    if (isEmpty(pickup?.requestedPickup)) {
      errorList.set('requestedPickup', 'Enter a preferred pickup date');
    }
  };

  const validateDeliveryFields = (errorList: Map<string, string>): void => {
    const delivery = deliveries[deliveryIndex].deliveryInfo;

    if (
      (!delivery?.deliveryAddress?.id && !delivery.isNewDeliveryRecipient) ||
      (isGuestUser && !delivery?.deliveryAddress)
    ) {
      errorList.set('deliveryAddress', 'Enter a delivery address or create a new one');
    }

    if (delivery.isNewDeliveryRecipient) {
      if (delivery.isNewDeliveryRecipient && isEmpty(delivery?.deliveryRecipient?.name)) {
        errorList.set('deliveryContactName', 'Delivery contact name is missing');
      }

      if (isEmpty(delivery?.deliveryRecipient?.name)) {
        errorList.set('deliveryPhone', 'Delivery contact phone is missing');
      }

      if (isEmpty(delivery?.deliveryRecipient?.email)) {
        errorList.set('email', 'Email is mising');
      }
    }
  };

  const validateDeliveryDate = (errorList: Map<string, string>): void => {
    const delivery = deliveries[deliveryIndex].deliveryInfo;

    if (isEmpty(delivery?.requestedDelivery)) {
      errorList.set('requestedDelivery', 'Enter a preferred delivery date');
    }
  };

  const validateCashChangeStep = (cashChange: CashChange): boolean => {
    const cashChangeErrorList = new Map();

    if (!cashChange?.denominations || cashChange.denominations.length === 0) {
      cashChangeErrorList.set('cashChange', 'Cash change denominations are missing');
    }

    if (!cashChange?.denominations || cashChange.denominations.reduce((acc, curr) => acc + curr.amount, 0) === 0) {
      cashChangeErrorList.set('cashChange', 'Cash change denominations should be greater than zero');
    }

    return validateStep(OrderSteps.CASH_CHANGE, Object.fromEntries(cashChangeErrorList));
  };

  const validateAttachmentForm = (attachments: AttachmentInfo, attachmentFileInfo?: SelectedFileInfo): boolean => {
    const attachmentErrorList = new Map();

    if (attachments?.isInvoiceRequired && !attachmentFileInfo?.invoiceFile) {
      attachmentErrorList.set('invoiceFile', 'An invoice file is required');
    }

    return validateStep(OrderSteps.ATTACHMENT, attachmentErrorList);
  };

  const getFileData = (matchItem: FileInfo): FileInfo => ({
    fileName: matchItem.fileName,
    fileUrl: matchItem.fileUrl,
    filePath: matchItem.filePath,
  });

  const getUploadedAttachmentData = async (
    currentAttachment: AttachmentInfo,
    uploadedFileData: UploadedFileModel[],
  ): Promise<AttachmentInfo> => {
    let invoiceFileInfo: FileInfo | null = null;
    const otherFilesInfo: Array<FileInfo> = [];
    const currentInvoiceFile = currentAttachment?.invoiceFile;

    if (currentInvoiceFile) {
      const matchItem = uploadedFileData.find(item => item.fileName === currentInvoiceFile.fileName);
      if (matchItem) {
        invoiceFileInfo = getFileData(matchItem);
      }
    }

    const otherFiles = currentAttachment?.otherFiles;
    if (otherFiles) {
      Array.from(otherFiles).forEach(otherFile => {
        const matchItem = uploadedFileData.find(item => item.fileName === otherFile.fileName);
        if (matchItem) {
          const fileData = getFileData(matchItem);
          otherFilesInfo.push(fileData);
        }
      });
    }

    const newAttachmentData: AttachmentInfo = {
      ...currentAttachment,
      ...(invoiceFileInfo ? { invoiceFile: invoiceFileInfo } : {}),
      ...(otherFilesInfo && otherFilesInfo.length > 0 ? { otherFiles: otherFilesInfo } : {}),
    };
    return newAttachmentData;
  };

  const uploadFiles = async (attachments: AttachmentInfo, path: string): Promise<any> => {
    const fileListData: UploadedFileModel[] = [];
    const fileList = Array.from([...([attachments?.invoiceFile] || []), ...(attachments?.otherFiles || [])]);
    const newFiles = fileList.filter(file => !file?.fileUrl);

    for (const fileItem of newFiles) {
      const file = fileItem?.file;
      if (file) {
        await uploadFile(file, path).then((response: UploadedFileModel) => {
          fileListData.push(response);
        });
      }
    }
    return fileListData;
  };

  const removeFiles = async (attachments: AttachmentInfo): Promise<any> => {
    const fileList = Array.from([...([attachments?.invoiceFile] || []), ...(attachments?.otherFiles || [])]);
    const filesToDelete = fileList.filter(file => file?.shouldBeRemoved && file.filePath);

    for (const fileItem of filesToDelete) {
      const filePath = fileItem?.filePath;
      if (filePath) {
        await deleteFile(filePath).then();
      }
    }
  };

  const buildIndividualRequests = async (
    status: OrderRequestStatus,
    deliveryItem: OrderStepperDeliveryStateProps,
    orderId: string,
    orderReference: any,
    isNew: boolean,
  ): Promise<OrderRequest | null> => {
    const { billingAddressId } = pickup;
    let { requestedPickup, pickupAddress, pickupRecipient } = pickup;
    const { deliveryInfo, attachments } = deliveryItem;
    let { requestedDelivery, deliveryRecipient, deliveryAddress } = deliveryInfo;
    const isDraft = status === OrderRequestStatus.DRAFT;

    if (transactionType === CASH_CHANGE_DROPS) {
      if (
        ['bank', 'fedReserve'].includes(locationSource || '') &&
        locationTarget === 'vault' &&
        financialInstitutionId
      ) {
        pickupRecipient = financialInstitutions.find(item => item.id === financialInstitutionId);
        pickupAddress = await getFinancialInstitutionDestination(financialInstitutionId);
        requestedPickup = requestedDelivery;
        requestedDelivery = undefined;
        deliveryRecipient = undefined;
      }

      if (
        ['bank', 'fedReserve'].includes(locationSource || '') &&
        locationTarget === 'addressBook' &&
        financialInstitutionId
      ) {
        pickupRecipient = financialInstitutions.find(item => item.id === financialInstitutionId);
        pickupAddress = await getFinancialInstitutionDestination(financialInstitutionId);
        requestedPickup = requestedDelivery;
      }

      if (locationSource === 'vault' && locationTarget === 'addressBook') {
        pickupRecipient = undefined;
      }

      if (currentUser?.recipientInfo?.isFinancialInstitution) {
        pickupRecipient = currentUser.financialInstitution;

        if (currentUser.financialInstitution) {
          pickupAddress = currentUser.financialInstitution.destinationInfo;
        }
      }
    }

    if (transactionType === CASH_DEPOSIT && locationTarget) {
      if (
        locationSource === 'vault' &&
        ['bank', 'fedReserve'].includes(locationTarget || '') &&
        financialInstitutionId
      ) {
        deliveryRecipient = financialInstitution;
        deliveryAddress = financialInstitution?.destinationInfo;
        requestedDelivery = requestedPickup;
        requestedPickup = undefined;
        pickupRecipient = undefined;
      }
      if (
        locationSource === 'addressBook' &&
        ['bank', 'fedReserve'].includes(locationTarget || '') &&
        financialInstitutionId
      ) {
        deliveryRecipient = financialInstitutions.find(item => item.id === financialInstitutionId);
        deliveryAddress = await getFinancialInstitutionDestination(financialInstitutionId);
        requestedDelivery = requestedPickup;
      }

      if (locationSource === 'addressBook' && locationTarget === 'vault') {
        deliveryRecipient = undefined;
      }
    }

    const product: OrderCashTransportItem = {
      item: 'Cash Transport',
      description: 'Cash Transport',
      amount: amount ?? 0,
    };

    if (isDraft || (!isDraft && (pickupRecipient || deliveryRecipient) && (pickupAddress || deliveryAddress))) {
      const dateField = isDraft ? 'draftedAt' : 'createdAt';
      const currentDate = moment().utc().unix();
      let attachmentData = attachments;
      const orderRefId = orderId || orderReference.id;
      if (orderRefId) {
        if (attachments?.invoiceFile || attachments?.otherFiles) {
          const path = `attachments/${orderRefId}`;
          await removeFiles(attachments);
          const result = await uploadFiles(attachments, path);
          attachmentData = await getUploadedAttachmentData(attachments, result);
        }
      }
      const tmpOrder: OrderRequest = {
        ...(order?.id ? { id: orderRefId } : {}),
        ...(order?.id ? { updatedAt: currentDate } : {}),
        [dateField]: currentDate,
        financialInstitutionId: financialInstitutionId || '',
        status,
        transactionType,
        depositType: depositType ?? null,
        ...(requestedPickup ? { requestedPickup: moment(requestedPickup).unix() } : null),
        ...(requestedDelivery ? { requestedDelivery: moment(requestedDelivery).unix() } : null),
        ...(currentUser?.uid ? { creator: currentUser.uid } : null),
        ...(contact && !currentUser?.uid ? { contactInfo: contact } : null),
        ...(pickupRecipient ? { senderInfo: pickupRecipient } : null),
        ...(deliveryRecipient ? { recipientInfo: deliveryRecipient } : null),
        ...(pickupAddress ? { originInfo: pickupAddress } : null),
        ...(deliveryAddress ? { destinationInfo: deliveryAddress } : null),
        cashChange,
        billingAddressId: billingAddressId,
        pickupAddressAsBilling: true,
        createdAt: currentDate,
        items: [product],
        attachments: attachmentData,
        orderReferenceId: orderRefId,
        orderReference: orderReference,
        parentId: null,
        locationSource,
        locationTarget,
        type: 'cash',
        isNew: isNew,
        isDraftChild: false,
      };

      return tmpOrder;
    }

    return null;
  };

  const buildOrderRequest = async (status: OrderRequestStatus): Promise<Array<OrderRequest> | null> => {
    const orderRequests: Array<OrderRequest> = [];
    const deliveryStepItem = deliveries[deliveryIndex];
    const orderReference = await getOrderReference();
    const orderRefId = id;
    const isNew = orderRefId === undefined;
    const orderId = orderRefId || orderReference.id;
    const orderRequestItem = await buildIndividualRequests(status, deliveryStepItem, orderId, orderReference, isNew);

    if (orderRequestItem !== null) {
      orderRequests.push(orderRequestItem);
    }

    return orderRequests.length > 0 ? orderRequests : null;
  };

  const onSubmitDraft = async (): Promise<void> => {
    setIsSaving(true);
    const ordersInfo = await buildOrderRequest(OrderRequestStatus.DRAFT);
    if (ordersInfo && currentUser) {
      const results = await saveOrderRequest(ordersInfo, currentUser, setSavingOrdersMsg);
      if (results) {
        success('Transport Requests saved as draft.');
        history.push(dashboard);
      }
    }
    setIsSaving(false);
  };

  const onSubmitOrder = async (): Promise<void> => {
    setIsSaving(true);
    const isValid = validateTransactionStep();

    if (isValid) {
      const ordersInfo = await buildOrderRequest(OrderRequestStatus.CREATED);
      if (ordersInfo) {
        const results = await saveOrderRequest(ordersInfo, currentUser, setSavingOrdersMsg);
        success('Transport Requests created successfully.');
        if (currentUser) {
          history.push(dashboard);
        } else {
          setOrderId(results[0]?.orderRefId || null);
          setRequestStatus(RequestStatus.SUCCESS);
        }
      }
    }
    setIsSaving(false);
  };

  const isRequestSuccess = (): boolean => requestStatus === RequestStatus.SUCCESS;

  const getSteps = (): Array<any> => [
    {
      name: 'Cash Transport',
      icon: 'fas fa-dollar-sign',
      isValid: transactionStep.isValid,
      component: (
        <TransactionStep
          destinations={destinations}
          financialDestinations={financialDestinations}
          destinationsOptions={destinationsOptions}
          isGuestView={isGuestUser}
          recipients={recipients}
          financialInstitutions={financialInstitutions}
          order={order}
          {...transactionStep}
        />
      ),
      validate: validateTransactionStep,
    },
    ...(transactionType === CASH_CHANGE_DROPS
      ? [
          {
            name: 'Cash Change',
            icon: 'fas fa-money-bill',
            isValid: cashChangeStep.isValid,
            component: <CashChangeForm {...cashChangeStep} />,
            validate: (): boolean => validateCashChangeStep(cashChange),
          },
        ]
      : []),
    {
      name: 'Attachment',
      icon: 'fa-paperclip',
      isValid: attachmentStep.isValid,
      component: (
        <AttachmentForm
          deliveryIndex={deliveryIndex}
          attachmentFileInfo={attachmentFileInfo}
          setAttachmentFileInfo={setAttachmentFileInfo}
          {...attachmentStep}
        />
      ),
      validate: (): boolean => validateAttachmentForm(attachments, attachmentFileInfo),
    },
  ];

  const resizeSummary = (): void => {
    const MIN_HEIGHT = 615;
    const stepperContainerDiv = document.querySelector('.stepper-container');
    const summaryContainer = document.querySelector('.summary-container');
    if (stepperContainerDiv instanceof HTMLElement && summaryContainer instanceof HTMLElement) {
      let maxHeight = stepperContainerDiv.offsetHeight || 0;
      maxHeight = maxHeight > MIN_HEIGHT ? maxHeight : MIN_HEIGHT;
      summaryContainer.style.height = `${maxHeight}px`;
      summaryContainer.style.overflow = 'auto';
    }
  };
  resizeSummary();

  useEffect(() => {
    const stepperContainerDiv = document.querySelector('.stepper-container');

    const handleOnClick = (event: any): void => {
      const clickedElement = event.target.classList;
      const validElementClasses = ['stepper-button', 'collapse-header', 'add-delivery', 'remove-item-btn'];
      const isValidElement = validElementClasses.findIndex(validClass => clickedElement.contains(validClass)) > -1;
      if (isValidElement) {
        setTimeout(() => {
          resizeSummary();
        }, 250);
      }
    };

    if (stepperContainerDiv) {
      stepperContainerDiv.addEventListener('click', handleOnClick);
    }

    return (): void => {
      if (stepperContainerDiv) {
        stepperContainerDiv.removeEventListener('click', handleOnClick);
      }
    };
  });

  if (isLoading) {
    return <Spinner message="Loading..." />;
  }

  return (
    <>
      {isSaving && <Spinner message={savingOrdersMsg} overScren={true} />}
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <h1>{!id ? 'Transport Request' : 'Edit Transport Request'}</h1>
        </div>
        <div className="col-12">
          {alert && <Alert message={USER_HAS_NOT_RECIPIENT_ASSOCIATED} type={AlertType.primary} isShowing={!!alert} />}
        </div>
      </div>
      <div className="row">
        {isRequestSuccess() ? (
          <div className="col-12 mt-4">
            <StepperFeedback
              order={{ ...order, id: orderId }}
              title="Order Request created successfully"
              message="Thak you for submitting your request. Our team will review it and contact you if necessary."
            />
          </div>
        ) : (
          <>
            <div className="col-12 col-lg-9 mt-4">
              <Stepper steps={getSteps()} mainStepper></Stepper>
            </div>
            <div className="d-none d-lg-block col-lg-3 mt-4 bg-white rounded summary-container">
              <div className="row">
                <div className="col-12 pt-4">
                  <h2>Transport Request Summary</h2>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-12 pt-2">
                    <h3 className="mb-3">Pick-up</h3>
                  </div>
                  <div className="col-12">
                    <p className="text-uppercase font-light text-small mb-2">Bill To</p>
                    <p className="font-semibold mb-2">{pickup.pickupRecipient?.name || '-'}</p>
                  </div>
                  <div className="col-12">
                    <p className="text-uppercase font-light text-small mb-2">Address</p>
                    <p className="font-semibold mb-2">{pickup.pickupAddress?.name || '-'}</p>
                  </div>
                  <div className="col-12">
                    <p className="text-uppercase font-light text-small mb-2">Date of Pick-up</p>
                    <p className="font-semibold mb-2">{pickup.requestedPickup || '-'}</p>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="col-12 col-lg-9">
          {!isRequestSuccess() && (
            <div className="col-xs-12 pull-right mt-3">
              <div className="float-right">
                <button
                  type="button"
                  className="btn btn-transparent text-uppercase mr-3"
                  onClick={(): void => history.push(dashboard)}
                >
                  Cancel
                </button>
                {!isGuestUser && (
                  <button onClick={onSubmitDraft} type="button" className="btn btn-secondary text-uppercase mr-3">
                    Save to Draft
                  </button>
                )}
                <button
                  // disabled={!transactionStep.isValid || !attachmentStep.isValid}
                  onClick={onSubmitOrder}
                  type="button"
                  className="btn btn-primary text-uppercase"
                >
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ orderStepper }: StateProps): any => ({ ...orderStepper });
const mapDispatchToProps = { ...OrderStepperActions };
const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrderCashStepper);
export default withAlert(Connected);
