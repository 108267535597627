import React, { forwardRef } from 'react';
import classnames from 'classnames';

import PreviousButton from './PreviousButton';
import NextButton from './NextButton';
import styles from './Stepper.module.scss';
import { IStepHeader } from './StepHeader';

export interface IStepProps extends IStepHeader {
  component?: any;
  children?: React.ReactNode;
  displayNext?: boolean;
  displayPrevious?: boolean;
  displaySubmit?: boolean;
  validate: () => boolean;
  goToPreviousStep?: () => void;
  goToNextStep?: () => void;
}

const Step = forwardRef<HTMLButtonElement, IStepProps>(
  (
    { isActive, displayPrevious, displayNext, goToPreviousStep, goToNextStep, component, children }: IStepProps,
    ref,
  ) => {
    if (isActive === false) return null;
    const isPreviousActive = displayPrevious || false;
    const isNextActive = displayNext || false;

    return (
      <div className={classnames(styles.stepContainer, 'rounded')}>
        {component ? component : children}
        <div className="d-flex mt-5">
          <PreviousButton isActive={isPreviousActive} onClick={(): void => goToPreviousStep && goToPreviousStep()} />
          <NextButton ref={ref} isActive={isNextActive} onClick={(): void => goToNextStep && goToNextStep()} />
        </div>
      </div>
    );
  },
);

export default Step;
