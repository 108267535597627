import React, { ComponentProps } from 'react';
import { Route } from 'react-router-dom';
import { GuestLayout } from '../Layout';

type GuestRouteProps = ComponentProps<typeof Route> & {
  component: React.FC;
};

const GuestRoute: React.FC<GuestRouteProps> = ({ component: Component, ...rest }: GuestRouteProps) => {
  return <Route {...rest} render={(): JSX.Element => <GuestLayout component={Component} />} />;
};

export default GuestRoute;
