import React from 'react';
import classnames from 'classnames';
import ReactMapGL, { Marker } from 'react-map-gl';
import { Destination } from 'types';
import { mapbox } from 'config/index';
import styles from './AddressCard.module.scss';

export interface SimpleCardProps {
  item: Destination;
  onEdit: (item: Destination) => void;
}
const mapStyle = 'mapbox://styles/mapbox/streets-v10';

const AddressCard: React.FC<SimpleCardProps> = ({ item, onEdit }: SimpleCardProps) => {
  const viewport = {
    latitude: item.location.lat,
    longitude: item.location.lon,
    zoom: 5,
  };

  const handleEdit = (): void => {
    onEdit(item);
  };

  const getLicenses = (): string => item.metrcLicenses?.map(l => l.number).join(', ') || '';

  return (
    <div key={item.id} className={classnames('col-md-6 col-sm-12')}>
      <div onClick={handleEdit} className={classnames('p-0 pl-4 pt-2 pr-4 col-md-12', styles.addressItem)}>
        <div className={classnames('row')} style={{ height: '100%' }}>
          <div className={classnames('p-0 col-md-5')}>
            <ReactMapGL
              {...viewport}
              width="100%"
              height="95%"
              mapboxApiAccessToken={mapbox.accessToken}
              mapStyle={mapStyle}
            >
              {item.location && (
                <Marker latitude={item.location.lat} longitude={item.location.lon}>
                  <i className={classnames('fas fa-map-marker-alt fa-2x', styles.marker)}></i>
                </Marker>
              )}
            </ReactMapGL>
          </div>
          <div className={classnames('pt-1 col-md-7')}>
            <h3 className="name">{item.address.line1}</h3>
            <h6 className="address">{item.address.formatted}</h6>
            <span className={classnames(styles.smText)}>{getLicenses()}</span>
            {!item.active && (
              <div className="clearfix">
                <div className={classnames('float-right', styles.disableContainer)}>
                  <i className={classnames('fas fa-eye-slash', styles.eyeIcon)}></i>
                  <span>DISABLED</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddressCard;
