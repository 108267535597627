import { OptionType } from 'components/Select';
import { OrderStepperDeliveryStateProps } from 'reducers/orderStepper';
import { CashDenomination, Destination, FileInfo, LocationType, OrderItem, Recipient } from 'types';
import { AttachmentInfo } from 'types/attachmentInfo';
import { MetrcPackage } from 'types/metrcPackage';

export const CLEAN_ORDER = 'CLEAN_ORDER';
export const SET_BILLING_ADDRESS = 'SET_BILLING_ADDRESS';
export const SET_PICKUP_ADDRESS = 'SET_PICKUP_ADDRESS';
export const SET_TRANSACTION_TYPE = 'SET_TRANSACTION_TYPE';
export const SET_DEPOSIT_TYPE = 'SET_DEPOSIT_TYPE';
export const SET_LOCATION_SOURCE = 'SET_LOCATION_SOURCE';
export const SET_LOCATION_TARGET = 'SET_LOCATION_TARGET';
export const SET_AMOUNT = 'SET_AMOUNT';
export const SET_FINANCIAL_INSTITUTION = 'SET_FINANCIAL_INSTITUTION';
export const SET_PICKUP_DATE = 'SET_PICKUP_DATE';
export const SET_PICKUP_RECIPIENT = 'SET_PICKUP_RECIPIENT';
export const SET_PICKUP_PHONE = 'SET_PICKUP_PHONE';
export const SET_NEW_PICKUP_RECIPIENT = 'SET_NEW_PICKUP_RECIPIENT';
export const SET_PICKUP_ADDRESS_AS_BILLING = 'SET_PICKUP_ADDRESS_AS_BILLING';
export const SET_PICKUP_IS_FRESH_FROZEN_TRANSPORT = 'SET_PICKUP_IS_FRESH_FROZEN_TRANSPORT';
export const SET_DELIVERY_ADDRESS = 'SET_DELIVERY_ADDRESS';
export const SET_DELIVERY_DATE = 'SET_DELIVERY_DATE';
export const SET_DELIVERY_ORDER_SIZE = 'SET_DELIVERY_ORDER_SIZE';
export const SET_DELIVERY_RECIPIENT = 'SET_DELIVERY_RECIPIENT';
export const SET_DELIVERY_PHONE = 'SET_DELIVERY_PHONE';
export const SET_NEW_DELIVERY_RECIPIENT = 'SET_NEW_DELIVERY_RECIPIENT';
export const SET_PRODUCT_METRC_LICENSE = 'SET_PRODUCT_METRC_LICENSE';
export const SET_PRODUCT_DESCRIPTION = 'SET_PRODUCT_DESCRIPTION';
export const SET_PRODUCT_NAME = 'SET_PRODUCT_NAME';
export const SET_PRODUCT_QUANTITY = 'SET_PRODUCT_QUANTITY';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_NEW_PRODUCT = 'SET_NEW_PRODUCT';
export const SET_REMOVE_PRODUCT = 'SET_REMOVE_PRODUCT';
export const SET_FOUND_PRODUCT = 'SET_FOUND_PRODUCT';
export const SET_EMPTY_PRODUCT_WITH_TAG = 'SET_EMPTY_PRODUCT';
export const SET_GUEST_NAME = 'SET_GUEST_NAME';
export const SET_GUEST_EMAIL = 'SET_GUEST_EMAIL';
export const SET_GUEST_PHONE = 'SET_GUEST_PHONE';
export const SET_ATTACHMENT = 'SET_ATTACHMENT';
export const SET_INVOICE_AS_REQUIRED = 'SET_INVOICE_AS_REQUIRED';
export const SET_NOTE = 'SET_NOTE';
export const SET_NEW_DELIVERY_INFO = 'SET_NEW_DELIVERY_INFO';
export const SET_DELIVERIES_DATA = 'SET_DELIVERIES_DATA';
export const SET_REMOVE_DELIVERY_INFO = 'SET_REMOVE_DELIVERY_INFO';
export const RESET_RECIPIENT = 'RESET_RECIPIENT';

export const SET_CASH_DENOMINATION_TYPE = 'SET_CASH_DENOMINATION_TYPE';
export const SET_CASH_DENOMINATION_QUANTITY = 'SET_CASH_DENOMINATION_QUANTITY';
export const SET_CASH_DENOMINATION_AMOUNT = 'SET_CASH_DENOMINATION_AMOUNT';
export const SET_NEW_CASH_DENOMINATION = 'SET_NEW_CASH_DENOMINATION';
export const SET_REMOVE_CASH_DENOMINATION = 'SET_REMOVE_CASH_DENOMINATION';

type setBillingAddress = {
  type: typeof SET_BILLING_ADDRESS;
  payload: { billingAddressId: string };
};

type setPickupAddress = {
  type: typeof SET_PICKUP_ADDRESS;
  payload: { pickupAddress: Destination };
};

type setTransactionType = {
  type: typeof SET_TRANSACTION_TYPE;
  payload: { transactionType: OptionType };
};

type setDepositType = {
  type: typeof SET_DEPOSIT_TYPE;
  payload: { depositType: OptionType };
};

type setAmount = {
  type: typeof SET_AMOUNT;
  payload: { amount: number };
};

type setFinancialInstitution = {
  type: typeof SET_FINANCIAL_INSTITUTION;
  payload: { financialInstitutionId: string };
};

type setLocationSource = {
  type: typeof SET_LOCATION_SOURCE;
  payload: { locationSource: LocationType };
};

type setLocationTarget = {
  type: typeof SET_LOCATION_TARGET;
  payload: { locationTarget: LocationType };
};

type setPickupDate = {
  type: typeof SET_PICKUP_DATE;
  payload: { requestedPickup: string };
};

type setPickupRecipient = {
  type: typeof SET_PICKUP_RECIPIENT;
  payload: { pickupRecipient: Recipient };
};

type setIsNewPickupRecipient = {
  type: typeof SET_NEW_PICKUP_RECIPIENT;
};

type setPickupAddressAsBilling = {
  type: typeof SET_PICKUP_ADDRESS_AS_BILLING;
  payload: { pickupAddressAsBilling: boolean };
};

type setPickupIsFreshFrozenTransport = {
  type: typeof SET_PICKUP_IS_FRESH_FROZEN_TRANSPORT;
  payload: { isFreshFrozenTransport: boolean };
};

type setDeliveryAddress = {
  type: typeof SET_DELIVERY_ADDRESS;
  payload: { index: number; deliveryAddress: Destination };
};

type setDeliveryDate = {
  type: typeof SET_DELIVERY_DATE;
  payload: { index: number; requestedDelivery: string };
};

type setDeliveryOrderSize = {
  type: typeof SET_DELIVERY_ORDER_SIZE;
  payload: { index: number; orderSize: string };
};

type setDeliveryRecipient = {
  type: typeof SET_DELIVERY_RECIPIENT;
  payload: { index: number; deliveryRecipient: Recipient };
};

type setIsNewDeliveryRecipient = {
  type: typeof SET_NEW_DELIVERY_RECIPIENT;
  payload: { index: number };
};

type setProducts = {
  type: typeof SET_PRODUCTS;
  payload: { deliveryIndex: number; products: Array<OrderItem> };
};

type setNewProduct = {
  type: typeof SET_NEW_PRODUCT;
  payload: {
    deliveryIndex: number;
  };
};

type setRemoveProduct = {
  type: typeof SET_REMOVE_PRODUCT;
  payload: {
    deliveryIndex: number;
    productIndex: number;
  };
};

type setProductMetrcLicense = {
  type: typeof SET_PRODUCT_METRC_LICENSE;
  payload: {
    deliveryIndex: number;
    productIndex: number;
    metrcReferenceNumber: string;
  };
};

type setProductDescription = {
  type: typeof SET_PRODUCT_DESCRIPTION;
  payload: {
    deliveryIndex: number;
    productIndex: number;
    description: string;
  };
};

type setProductName = {
  type: typeof SET_PRODUCT_NAME;
  payload: {
    deliveryIndex: number;
    productIndex: number;
    item: string;
  };
};

type setProductQuantity = {
  type: typeof SET_PRODUCT_QUANTITY;
  payload: {
    deliveryIndex: number;
    productIndex: number;
    quantity: number;
  };
};

type setFoundProduct = {
  type: typeof SET_FOUND_PRODUCT;
  payload: {
    deliveryIndex: number;
    productIndex: number;
    product: OrderItem;
    metrcPackage: MetrcPackage;
  };
};

type onSetEmptyProducWithTag = {
  type: typeof SET_EMPTY_PRODUCT_WITH_TAG;
  payload: {
    deliveryIndex: number;
    productIndex: number;
    tag: string;
  };
};

type setGuestName = {
  type: typeof SET_GUEST_NAME;
  payload: { name: string };
};

type setGuestEmail = {
  type: typeof SET_GUEST_EMAIL;
  payload: { email: string };
};

type setGuestPhone = {
  type: typeof SET_GUEST_PHONE;
  payload: { phone: string };
};

type onCleanOrder = {
  type: typeof CLEAN_ORDER;
};

type onResetRecipient = {
  type: typeof RESET_RECIPIENT;
  payload: { type: 'pickup' | 'delivery'; index: number };
};

type setAttachment = {
  type: typeof SET_ATTACHMENT;
  payload: {
    deliveryIndex: number;
    attachments: AttachmentInfo;
  };
};

type setInvoiceAsRequired = {
  type: typeof SET_INVOICE_AS_REQUIRED;
  payload: {
    deliveryIndex: number;
    isInvoiceRequired: boolean;
  };
};

type setNote = {
  type: typeof SET_NOTE;
  payload: {
    deliveryIndex: number;
    note: string;
  };
};

type setNewDeliveryInfo = {
  type: typeof SET_NEW_DELIVERY_INFO;
};

type setDeliveriesData = {
  type: typeof SET_DELIVERIES_DATA;
  payload: { deliveries: Array<OrderStepperDeliveryStateProps> };
};

type setRemoveDeliveryInfo = {
  type: typeof SET_REMOVE_DELIVERY_INFO;
  payload: { deliveryIndex: number };
};

type onNewCashDenomination = {
  type: typeof SET_NEW_CASH_DENOMINATION;
};

type onRemoveCashDenomination = {
  type: typeof SET_REMOVE_CASH_DENOMINATION;
  payload: { index: number };
};

type onCashDenominationTypeChanged = {
  type: typeof SET_CASH_DENOMINATION_TYPE;
  payload: { index: number; denomination: CashDenomination };
};

type onCashDenominationQuantityChanged = {
  type: typeof SET_CASH_DENOMINATION_QUANTITY;
  payload: { index: number; quantity: number };
};

type onCashDenominationAmountChanged = {
  type: typeof SET_CASH_DENOMINATION_AMOUNT;
  payload: { index: number; amount: number };
};

export type ActionTypes =
  | setGuestName
  | setGuestEmail
  | setGuestPhone
  | setBillingAddress
  | setPickupAddress
  | setTransactionType
  | setLocationSource
  | setLocationTarget
  | setDepositType
  | setFinancialInstitution
  | setAmount
  | setPickupDate
  | setPickupRecipient
  | setIsNewPickupRecipient
  | setPickupAddressAsBilling
  | setPickupIsFreshFrozenTransport
  | setDeliveryAddress
  | setDeliveryDate
  | setDeliveryOrderSize
  | setDeliveryRecipient
  | setIsNewDeliveryRecipient
  | setProducts
  | setNewProduct
  | setRemoveProduct
  | setProductMetrcLicense
  | setProductDescription
  | setProductName
  | setProductQuantity
  | setFoundProduct
  | onSetEmptyProducWithTag
  | onCleanOrder
  | setAttachment
  | setInvoiceAsRequired
  | setNote
  | setNewDeliveryInfo
  | setDeliveriesData
  | setRemoveDeliveryInfo
  | onResetRecipient
  | onNewCashDenomination
  | onRemoveCashDenomination
  | onCashDenominationTypeChanged
  | onCashDenominationQuantityChanged
  | onCashDenominationAmountChanged;

export type OrderStepperActionsProps = {
  onCleanOrder: () => void;
  onBillingAddressChanged: (billingAddressId: string) => void;
  onDeliveryAddressChanged: (index: number, destinations: Destination) => void;
  onTransactionTypeChanged: (transactionType: OptionType) => void;
  onDepositTypeChanged: (depositType: OptionType) => void;
  onFinancialInstitutionChanged: (financialInstitutionId: string) => void;
  onLocationSourceChanged: (locationType: LocationType) => void;
  onLocationTargetChanged: (locationType: LocationType) => void;
  onAmountChanged: (amount: number) => void;
  onDeliveryDateChanged: (index: number, value: string) => void;
  onDeliveryOrderSizeChanged: (index: number, orderSize: string) => void;
  onDeliveryRecipientChanged: (index: number, recipient: Recipient) => void;
  onNewDeliveryRecipient: (index: number) => void;
  onNewPickupRecipient: () => void;
  onNewProduct: (index: number) => void;
  onPickupAddressAsBillingChanged: (value: boolean) => void;
  onIsFreshFrozenTransport: (value: boolean) => void;
  onPickupAddressChanged: (destinations: Destination) => void;
  onPickupDateChanged: (value: string) => void;
  onPickupRecipientChanged: (recipient: Recipient) => void;
  onProductDescriptionChanged: (deliveryIndex: number, productIndex: number, name: string) => void;
  onProductNameChanged: (deliveryIndex: number, productIndex: number, item: string) => void;
  onProductMetrcLicenseChanged: (deliveryIndex: number, productIndex: number, metrcLicense: string) => void;
  onProductQuantityChanged: (deliveryIndex: number, productIndex: number, name: string) => void;
  onRemoveProduct: (deliveryIndex: number, productIndex: number) => void;
  onSetProducts: (deliveryIndex: number, products: Array<OrderItem>) => void;
  onSearchedProductFound: (
    deliveryIndex: number,
    productIndex: number,
    product: OrderItem,
    metrcPackage: MetrcPackage,
  ) => void;
  onSetEmptyProducWithTag: (deliveryIndex: number, productIndex: number, tag: string) => void;
  onGuestNameChanged: (name: string) => void;
  onGuestEmailChanged: (email: string) => void;
  onGuestPhoneChanged: (phone: string) => void;
  onSetAttachment: (deliveryIndex: number, attachments: AttachmentInfo) => void;
  onIsInvoiceRequiredChanged: (deliveryIndex: number, isInvoiceRequired: boolean) => void;
  onNoteChanged: (deliveryIndex: number, value: string) => void;
  onSetInvoiceFile: (deliveryIndex: number, invoiceFile: FileInfo) => void;
  onSetOtherFiles: (deliveryIndex: number, otherFiles: Array<FileInfo>) => void;
  onNewDeliveryInfo: () => void;
  onSetDeliveries: (deliveries: Array<OrderStepperDeliveryStateProps>) => void;
  onRemoveDeliveryInfo: (deliveryIndex: number) => void;
  onResetRecipient: (type: 'pickup' | 'delivery', index?: number) => void;
  onCashDenominationTypeChanged: (index: number, destination: CashDenomination) => void;
  onCashDenominationQuantityChanged: (index: number, quantity: number) => void;
  onCashDenominationAmountChanged: (index: number, amount: number) => void;
  onNewCashDenomination: () => void;
  onRemoveCashDenomination: (index: number) => void;
};
