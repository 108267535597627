import { api } from 'config';
import React from 'react';
import { useApi } from 'services/hooks';

type Props = {
  orderId: string;
  isRequest?: boolean;
};

const DownloadPDFButton: React.FC<Props> = ({ orderId, isRequest }: Props) => {
  const { post, isLoading } = useApi();

  const getPDFUrl = async (): Promise<void> => {
    const { status, data } = await post(api.getOrderReportUrl, {
      orderType: isRequest ? 'request' : 'order',
      orderId,
    });

    if (status === 'success') {
      const { url } = data;
      window.open(url, '_blank');
    }
  };

  const getMessage = (): string => {
    if (isLoading) {
      return 'Getting Directions...';
    }

    return 'Download PDF';
  };

  return (
    <button
      disabled={isLoading}
      className="btn btn-primary text-uppercase py-2"
      onClick={(): Promise<void> => getPDFUrl()}
    >
      {getMessage()}
    </button>
  );
};

DownloadPDFButton.defaultProps = {
  isRequest: false,
};

export default DownloadPDFButton;
