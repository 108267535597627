import React, { useEffect, useState } from 'react';
import MapGL, { Marker, NavigationControl } from 'react-map-gl';
import classnames from 'classnames';
import { DragEvent as MapDragEvent } from 'react-map-gl';

import { mapbox } from 'config';
import Pin from './Pin';
import styles from './DraggableMarkerMap.module.scss';

export type MapProps = {
  className?: string | undefined;
  viewport?: MapViewport;
  marker?: MapMarker;
  disabled?: boolean;
  onResetButtonClick?: () => void;
  onPinChanged?: (coordinates: MapMarker) => void;
};

export type MapViewport = {
  latitude: number;
  longitude: number;
  zoom: number;
  bearing: number;
  pitch: number;
};

export type MapMarker = {
  latitude: number;
  longitude: number;
};

const initialMarker: MapMarker = {
  latitude: 42.3528165,
  longitude: -83.1692448,
};

const getViewPort = (marker: MapMarker): MapViewport => {
  return {
    ...marker,
    zoom: 11,
    bearing: 0,
    pitch: 0,
  };
};

const DraggableMarkerMap: React.FC<MapProps> = ({
  className,
  disabled,
  marker: markerProp,
  onResetButtonClick,
  onPinChanged,
}: MapProps) => {
  const initialViewport: MapViewport = getViewPort(initialMarker);

  const [viewport, setViewport] = useState(initialViewport);
  const [events, setEvents] = useState({});
  const [marker, setMarker] = useState(initialMarker);

  const _logDragEvent = (name: string, event: MapDragEvent): void => {
    setEvents({
      ...events,
      [name]: event.lngLat,
    });
  };

  const onMarkerDragStart = (event: MapDragEvent): void => {
    _logDragEvent('onDragStart', event);
  };

  const onMarkerDrag = (event: MapDragEvent): void => {
    _logDragEvent('onDrag', event);
  };

  const onMarkerDragEnd = (event: MapDragEvent): void => {
    const [longitude, latitude] = event.lngLat;
    setMarker({ longitude, latitude });
    onPinChanged && onPinChanged({ longitude, latitude });
  };

  const onReset = (): void => {
    setViewport(initialViewport);
    setMarker(initialMarker);
    onResetButtonClick && onResetButtonClick();
  };

  useEffect(() => {
    if (markerProp) {
      setMarker(markerProp);
      setViewport(getViewPort(markerProp));
    }
  }, [markerProp]);

  return (
    <div className={classnames(styles.draggableMarkerMapContainer, 'rounded')}>
      <MapGL
        attributionControl={false}
        className={className}
        {...viewport}
        width="100%"
        height="100%"
        mapStyle="mapbox://styles/mapbox/light-v10"
        onViewportChange={(viewport): void => setViewport(viewport)}
        mapboxApiAccessToken={mapbox.accessToken}
        scrollZoom={!disabled}
        dragPan={!disabled}
      >
        <Marker
          longitude={marker.longitude}
          latitude={marker.latitude}
          offsetTop={-20}
          offsetLeft={-10}
          draggable={!disabled}
          onDragStart={(e: MapDragEvent): void => onMarkerDragStart(e)}
          onDrag={(e: MapDragEvent): void => onMarkerDrag(e)}
          onDragEnd={(e: MapDragEvent): void => onMarkerDragEnd(e)}
        >
          <Pin size={20} />
        </Marker>
        {!disabled && (
          <>
            <div className={classnames(styles.mapNavigation)}>
              <NavigationControl showCompass={false} onViewportChange={(viewport): void => setViewport(viewport)} />
            </div>
            <div className={classnames(styles.mapResetContainer)}>
              <button
                onClick={onReset}
                className={classnames(
                  styles.mapResetButton,
                  'btn btn-block btn-white text-uppercase rounded pl-5 pr-5',
                )}
              >
                <i className="fas fa-undo fa-2x text-dark mr-3"></i>
                Reset Address
              </button>
            </div>
          </>
        )}
      </MapGL>

      <div className={classnames(styles.dragLabelContainer)}>
        <i className="fas fa-exclamation-circle fa-lg text-dark"></i>
        <p className={classnames(styles.dragLabel)}>Drag the pin to customize the address.</p>
      </div>
    </div>
  );
};

export default DraggableMarkerMap;
