import React from 'react';
import styles from './AppFooter.module.scss';
import { AUTH } from '../../../constants/footerTypes';
import JKLogixLogo from '../../../assets/images/jklogic.png';

type FooterProps = {
  type: string;
};

const AppFooter: React.FC<FooterProps> = React.memo(({ type }: FooterProps) => {
  let className = '';
  if (type === AUTH) {
    className = styles.footer;
  } else {
    className = styles.footerInside;
  }
  return (
    <div className={className}>
      {type !== AUTH && (
        <div>
          <img src={JKLogixLogo} alt="JX Logix Logo" />
          <br />
        </div>
      )}
      <span>© Copyright 2020 JK Logix - All rights reserved</span>
    </div>
  );
});
export default AppFooter;
