import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';

export default function withPageView<Props>(
  WrappedComponent: React.ComponentType<Props>,
  title: string,
): {
  (props: Props): JSX.Element;
} {
  const WithPageView = (props: Props): React.ReactElement => {
    const history = useHistory();

    useEffect(() => {
      const trackPage = (location: any): void => {
        const page = location.pathname + location.search;
        ReactGA.pageview(page, undefined, title);
      };

      trackPage(history.location);
    }, [history]);

    return <WrappedComponent {...(props as Props)} />;
  };

  return WithPageView;
}
