import React, { useState, useEffect, useContext, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import cls from 'classnames';
import { useGetOrderById } from 'services/hooks/orders';
import { currencyFormat, formatShortDate, getFormattedAddress } from 'utils';
import { withAuthorization, withPageView } from 'components';
import { SimpleCard } from 'components/Cards/';
import { StatusBar, OrderProductsTable } from './components';
import { Spinner } from 'components/Loaders';
import { NotFound as NotFoundComponent } from 'components/NotFound';
import { Destination, Order, Recipient } from 'types';
import * as routes from 'constants/routes';
import { AuthContext } from 'contexts';
import { CurrentUser } from 'types/auth';

import style from './ViewOrder.module.scss';
import { DownloadPDFButton } from 'components/DownloadPDFButton';
import AttachmentDetails from './components/AttachmentDetails';
import { useIsLoading } from 'services/hooks/useIsLoading';
import OrderCash from './components/OrderCash/OrderCash';
import { PACKAGE, TRANSACTIONS_NAME } from 'constants/transactions';

interface RouteParams {
  id: string;
}
const ViewOrder: React.FC<RouteComponentProps<RouteParams>> = ({
  match,
  history,
}: RouteComponentProps<RouteParams>) => {
  const auth = useContext(AuthContext);
  const { currentUser } = auth;
  const { order: orderFound } = useGetOrderById(match.params.id, currentUser?.uid);
  const [order, setOrder] = useState<Order | undefined>();
  const [isUserOrder, setIsUserOrder] = useState<boolean>(true);
  const { isLoading, setIsLoading } = useIsLoading(true);

  const {
    estimatedPickup,
    orderNumber,
    originInfo,
    billingAddressInfo,
    driverInfo,
    senderInfo,
    status,
    requestedPickup,
    deliveries,
    isFreshFrozenTransport,
    cashChange,
  } = order ?? {};
  const deliveriesData = deliveries ?? [];
  const orderData = deliveriesData[0] ?? {};
  const destinationInfo: Destination | any = orderData?.destinationInfo ?? {};
  const recipientInfo: Recipient | any = orderData?.recipientInfo ?? {};
  const estimatedDelivery = orderData?.estimatedDelivery ?? '';
  const requestedDelivery = orderData?.requestedDelivery ?? '';
  const items = orderData?.items ?? {};
  const attachments = orderData?.attachments ?? {};
  const orderSize = orderData.orderSize;

  const { address: pickUpAddress } = originInfo ?? {};
  const { address: deliveryAddress } = destinationInfo;
  const { metrcLicenseNumber: senderMetrcLicense } = senderInfo ?? {};
  const { name: recipientName, phone: recipientPhone } = recipientInfo;
  const { name: senderName, phone: senderPhone } = senderInfo ?? {};
  const { firstName: driverName, lastName: driverLastName, driverLicense } = driverInfo ?? {};

  useEffect(() => {
    const isUsersOrder = (order): boolean => {
      if (!order) {
        return true;
      }

      const user: CurrentUser = currentUser as CurrentUser;

      if (!currentUser) {
        history.push(routes.login);
      }

      return order.creator === user.uid;
    };

    // check if order was created by the current user
    if (!isUsersOrder(orderFound)) {
      setIsUserOrder(false);
    }

    setOrder(orderFound);
    return (): void => {
      setOrder(undefined);
    };
  }, [currentUser, orderFound, history, auth]);

  const isPackageType = useMemo(() => order?.type === PACKAGE, [order]);
  const transactionType = useMemo(() => order?.transactionType ?? '', [order]);
  const transactionTypeText = useMemo(() => TRANSACTIONS_NAME[transactionType] ?? '', [transactionType]);
  const orderId = order?.orderId ?? order?.orderRequestId;

  const renderOrderItems = (): JSX.Element => {
    if (orderSize) {
      return (
        <div className="d-flex flex-column mt-3">
          <span className="label">Order Size</span>
          <span className="font-light">{orderSize}</span>
        </div>
      );
    }

    if (items) {
      return isPackageType ? (
        <OrderProductsTable items={items} />
      ) : (
        <OrderCash items={items} transactionType={transactionType} cashChange={cashChange} />
      );
    }

    return <span>No items to display.</span>;
  };

  const renderAttachmentDetails = (): JSX.Element => {
    if (attachments) {
      return <AttachmentDetails attachments={attachments} />;
    }
    return <span>No attachment data to display.</span>;
  };

  if (isLoading) {
    return <Spinner message="Loading..." />;
  }

  // if order was not found or was created by another user
  if ((!isLoading && !order) || !isUserOrder) {
    setIsLoading(false);
    return <NotFoundComponent notFoundUrl={window.location.href} redirectUrl={routes.dashboard} />;
  }

  return (
    <>
      <div id="orderDetailsWrapper" className="row mb-4">
        <div className="col-xs-12 col-sm-12 col-lg-5">
          <h1>View Order {order && (orderNumber ? `#${orderNumber}` : '')}</h1>
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-7">
          <StatusBar status={status} isRequest={!orderNumber}></StatusBar>
          <div className="float-right pt-4">
            {!!orderId && <DownloadPDFButton orderId={orderId} isRequest={!order?.orderId} />}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className={style.viewOrderContainer}>
            <SimpleCard title="Pick-Up Details" className={cls(style.pickUpContainer, 'bg-white p-4 rounded ')}>
              <>
                <div className="row border-bottom border-gray">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <div className="d-flex flex-column mt-3">
                          <span className="label">Request Date</span>
                          <span className="font-light">
                            {(requestedPickup && formatShortDate(requestedPickup)) || '-'}
                          </span>
                        </div>
                      </div>
                      <div className="col">
                        <div className="d-flex flex-column mt-3">
                          <span className="label">Estimated Date</span>
                          <span className="font-light">
                            {(estimatedPickup && formatShortDate(estimatedPickup)) || '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="d-flex flex-column mb-4 mt-3">
                          <span className="label text-uppercase">Pick-up Address</span>
                          <span className="font-light">
                            {(pickUpAddress && getFormattedAddress(pickUpAddress)) || '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                    {isFreshFrozenTransport && (
                      <div className="row">
                        <div className="col">
                          <div className="d-flex flex-column mb-4 mt-3">
                            <p className="font-lighter">
                              This order was marked as <span className="font-semibold">Fresh Frozen Transport</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <h3>Billing Contact</h3>
                  </div>
                  <div className="col-12">
                    <div className="d-flex flex-column mt-3">
                      <span className="label">Name</span>
                      <span className="font-light">
                        {(billingAddressInfo && billingAddressInfo.ownerInfo?.name) ?? '-'}
                      </span>
                    </div>
                    {isPackageType && (
                      <div className="d-flex flex-column mt-3">
                        <span className="label text-uppercase">Bill and License Number</span>
                        <span className="font-light">{senderMetrcLicense ?? '-'}</span>
                      </div>
                    )}
                  </div>
                </div>
              </>
            </SimpleCard>
            <SimpleCard title="Delivery Details" className={cls(style.deliveryContainer, 'bg-white p-4 rounded ')}>
              <>
                <div className="row border-bottom border-gray">
                  <div className="col">
                    <div className="row">
                      <div className="col">
                        <div className="d-flex flex-column mt-3">
                          <span className="label">Request Date</span>
                          <span className="font-light">
                            {(requestedDelivery && formatShortDate(requestedDelivery)) || '-'}
                          </span>
                        </div>
                      </div>
                      <div className="col">
                        <div className="d-flex flex-column mt-3">
                          <span className="label">Estimated Date</span>
                          <span className="font-light">
                            {(estimatedDelivery && formatShortDate(estimatedDelivery)) || '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="d-flex flex-column mb-4 mt-3">
                          <span className="label text-uppercase">Delivery Address</span>
                          <span className="font-light">
                            {(deliveryAddress && getFormattedAddress(deliveryAddress)) || '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row border-bottom border-gray mt-4 pb-4">
                  <div className="col-12">
                    <h3>Pick-Up Contact</h3>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column mt-3">
                      <span className="label">Name</span>
                      <span className="font-light">{senderName ?? '-'}</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column mt-3">
                      <span className="label">Phone Number</span>
                      <span className="font-light">{senderPhone ?? '-'}</span>
                    </div>
                  </div>
                </div>
                <div className="row border-gray mt-4">
                  <div className="col-12">
                    <h3>Delivery Contact</h3>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column mt-3">
                      <span className="label">Name</span>
                      <span className="font-light">{recipientName || '-'}</span>
                    </div>
                  </div>
                  <div className="col">
                    <div className="d-flex flex-column mt-3">
                      <span className="label">Phone Number</span>
                      <span className="font-light">{recipientPhone || '-'}</span>
                    </div>
                  </div>
                </div>
              </>
            </SimpleCard>
            <div className={style.summaryContainer}>
              <div className={cls(style.driverContainer, 'bg-white p-4 rounded ')}>
                <SimpleCard title="Driver Details">
                  <>
                    <div className="row">
                      <div className="col">
                        <div className="d-flex flex-column mt-3">
                          <span className="label">Name</span>
                          <span className="font-light">{driverInfo ? `${driverName} ${driverLastName}` : '-'}</span>
                        </div>
                        {isPackageType && (
                          <div className="d-flex flex-column mb-4 mt-3">
                            <span className="label text-uppercase">License Number</span>
                            <span className="font-light">{driverLicense ? driverLicense : '-'}</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                </SimpleCard>
              </div>
              <div className={cls(style.totalContainer, 'bg-white p-4 rounded ')}>
                <SimpleCard title="Total">
                  <>
                    <div className="row border-bottom pb-4 border-gray">
                      <div className="col">
                        <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                          <span className="label">Sub-total</span>
                          <span className="font-light">
                            {order?.orderTotal ? currencyFormat(order?.orderTotal) : 'Pending'}
                          </span>
                        </div>
                        <div className="d-flex flex-row mt-2 justify-content-between align-items-center">
                          <span className="label">Discount</span>
                          <span className="font-light">{currencyFormat(0)}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col">
                        <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                          <span className="label">Total</span>
                          <span className="font-light h2">{currencyFormat(order?.orderTotal ?? 0)}</span>
                        </div>
                      </div>
                    </div>
                  </>
                </SimpleCard>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleCard
            title={isPackageType ? 'Product Details' : 'Transaction details'}
            className="bg-white mt-3 p-4 rounded"
            transactionType={transactionTypeText}
          >
            {renderOrderItems()}
          </SimpleCard>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleCard title="Attachment Details" className="bg-white mt-3 p-4 rounded">
            {renderAttachmentDetails()}
          </SimpleCard>
        </div>
      </div>
    </>
  );
};

const Enhanced = withPageView(ViewOrder, 'View Order');
export default withAuthorization(Enhanced);
