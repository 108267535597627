/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useMemo } from 'react';
import { connect } from 'react-redux';
import { StateProps } from 'reducers';
import { findLicense, findRecipientLicense } from 'services';
import { OrderStepperStateProps } from 'reducers/orderStepper';
import * as OrderStepperActions from 'actions/orderStepper';
import { OrderStepperActionsProps } from 'actions/types/orderStepper';
import { Destination, Recipient } from 'types';
import { DraggableMarkerMap } from 'components/Map';
import { TextField, SelectField, DestinationSelectField, TextSearchField } from 'components/FormFields';
import { OptionType } from 'components/Select';
import { MapMarker } from 'components/Map/DraggableMarkerMap';
import { useAuth } from 'contexts/auth';
import { useGeocodeAddress } from 'services/hooks/useGeocodeAddress';

interface Props extends OrderStepperStateProps, OrderStepperActionsProps {
  deliveryIndex: number;
  recipients: Array<Recipient>;
  destinations: Array<Destination>;
  destinationsOptions: (showNew?: boolean) => Array<OptionType>;
  isValid: boolean;
  errors: any;
}

const DeliveryForm: React.FC<Props> = ({
  deliveryIndex,
  // State
  deliveries,
  // Props
  recipients,
  destinations,
  destinationsOptions,
  errors,
  // Actions
  onDeliveryAddressChanged,
  onDeliveryRecipientChanged,
  onDeliveryDateChanged,
  onDeliveryOrderSizeChanged,
  onNewDeliveryRecipient,
}: Props) => {
  const { currentUser } = useAuth();
  const { geocodeAddress } = useGeocodeAddress();
  const delivery = deliveries[deliveryIndex].deliveryInfo;
  const { deliveryAddress, deliveryRecipient, isNewDeliveryRecipient, requestedDelivery, orderSize } = delivery;

  const onSearchMetrcLicense = async (metrcLicenseNumber: string): Promise<void> => {
    if (!currentUser) {
      return;
    }

    const license = await findRecipientLicense(metrcLicenseNumber);
    const { recipientId, recipients, destinations = [] } = currentUser;

    if (license?.recipientId && license.owner === recipientId) {
      const recipient = recipients?.find(r => r.id === license.recipientId);

      if (recipient) {
        const defaultDestination = destinations.find(d => d.id === license.destinationId);

        onDeliveryRecipientChanged(deliveryIndex, {
          ...recipient,
          metrcLicenseNumber: license.number,
        });

        defaultDestination && onDeliveryAddressChanged(deliveryIndex, defaultDestination);
      }
    } else {
      const license = await findLicense(metrcLicenseNumber);

      if (!license) {
        return;
      }

      const { destination: address } = license;
      const destination = await geocodeAddress(
        `${address?.line1} ${address?.city} ${address?.state} ${address?.postalCode}`,
      );

      if (license && delivery.deliveryRecipient) {
        onDeliveryRecipientChanged(deliveryIndex, {
          ...delivery.deliveryRecipient,
          name: license.businessName,
        });

        destination && onDeliveryAddressChanged(deliveryIndex, destination);
      }
    }
  };

  const onDeliveryAddressChange = (option: OptionType): void => {
    if (option.value === 'new') {
      onNewDeliveryRecipient(deliveryIndex);
      return;
    }

    const [recipientId, destinationId] = (option.value || '').split(',');
    let selectedDestination = destinations.find((d: Destination) => d.id === destinationId.trim());
    let selectedRecipient = recipients.find((r: Recipient) => r.id === recipientId);

    const license = currentUser?.metrcLicenses?.find(
      ml => ml.recipientId === recipientId && ml.destinationId === destinationId,
    );

    if (!selectedDestination) {
      selectedDestination = deliveryRecipient?.destinations?.find((d: Destination) => d.id === destinationId);
    }

    if (!selectedRecipient) {
      selectedRecipient = deliveryRecipient;
    }

    selectedDestination && onDeliveryAddressChanged(deliveryIndex, selectedDestination);
    selectedRecipient &&
      license &&
      onDeliveryRecipientChanged(deliveryIndex, {
        ...selectedRecipient,
        metrcLicenseNumber: license.number,
      });
  };

  const onDeliveryDestinationChange = (destination: Destination): void => {
    destination && onDeliveryAddressChanged(deliveryIndex, destination);
  };

  const onDeliveryRecipientFieldChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (delivery?.deliveryRecipient) {
      onDeliveryRecipientChanged(deliveryIndex, {
        ...delivery?.deliveryRecipient,
        isNew: delivery.isNewDeliveryRecipient,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onPinChanged = ({ longitude, latitude }: MapMarker): void => {
    if (deliveryAddress) {
      onDeliveryAddressChanged(deliveryIndex, {
        ...deliveryAddress,
        location: {
          lon: longitude,
          lat: latitude,
        },
      });
    }
  };

  const marker = useMemo(() => {
    if (deliveryAddress) {
      return {
        latitude: deliveryAddress?.location.lat,
        longitude: deliveryAddress?.location.lon,
      };
    }
  }, [deliveryAddress]);

  const prevDestinations = useMemo(() => {
    if (deliveryAddress) {
      return [
        {
          value: deliveryAddress.id,
          label: deliveryAddress.name,
        },
      ];
    }

    return [];
  }, [deliveryAddress]);

  const getDestinationOptions = (): Array<OptionType> => {
    const options: Array<OptionType> = [
      {
        value: 'new',
        label: 'Add New',
      },
    ];

    if (deliveryRecipient?.id && deliveryRecipient?.destinations) {
      const { destinations } = deliveryRecipient;

      destinations.forEach(destination => {
        options.push({
          value: `${deliveryRecipient.id},${destination.id}`,
          label: `${deliveryRecipient.name} - ${destination.name}`,
        });
      });

      return options;
    }

    return destinationsOptions(true);
  };

  return (
    <div className="row">
      <div className="col-12">
        <p className="tagline text-secondary">Where do you want to deliver this order?</p>
      </div>
      <div className="col-xs-12 col-sm-6">
        <div className="row">
          <div className="col">
            <form className="needs-validation" noValidate>
              <div className="row">
                <div className="col-12 mb-3">
                  <TextSearchField
                    id="metrcLicenseNumber"
                    name="metrcLicenseNumber"
                    type="text"
                    label="METRC License"
                    placeholder="Enter a METRC License"
                    onSearch={onSearchMetrcLicense}
                    onChange={onDeliveryRecipientFieldChange}
                    value={deliveryRecipient?.metrcLicenseNumber}
                    isValid={!errors?.metrcLicense}
                    feedback={errors?.metrcLicense}
                  />
                </div>
                <div className="col-12 mb-3">
                  <TextField
                    id="requestedDelivery"
                    name="requestedDelivery"
                    type="datetime-local"
                    label="Date of Delivery"
                    onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      onDeliveryDateChanged(deliveryIndex, e.target.value)
                    }
                    value={requestedDelivery}
                    isValid={!errors?.requestedDelivery}
                    feedback={errors?.requestedDelivery}
                  />
                </div>
                <div className="col-12 mb-3">
                  <TextField
                    id="orderSize"
                    name="orderSize"
                    type="string"
                    label="Order Size"
                    placeholder="4 Boxes"
                    onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                      onDeliveryOrderSizeChanged(deliveryIndex, e.target.value)
                    }
                    value={orderSize}
                    isValid={!errors?.orderSize}
                    feedback={errors?.orderSize}
                  />
                </div>
                <div className="col-12 mb-3">
                  <TextField
                    id="deliveryContactName"
                    name="name"
                    type="text"
                    label="Business Name"
                    disabled={!isNewDeliveryRecipient}
                    onChange={onDeliveryRecipientFieldChange}
                    value={deliveryRecipient?.name || ''}
                    isValid={!errors?.deliveryContactName}
                    feedback={errors?.deliveryContactName}
                  />
                </div>
                <div className="col-12 mb-3">
                  <TextField
                    id="deliveryPhone"
                    name="phone"
                    type="text"
                    label="Delivery Phone"
                    disabled={!isNewDeliveryRecipient}
                    onChange={onDeliveryRecipientFieldChange}
                    value={deliveryRecipient?.phone || ''}
                    isValid={!errors?.deliveryPhone}
                    feedback={errors?.deliveryPhone}
                  />
                </div>
                <div className="col-12 mb-3">
                  <TextField
                    id="deliveryRecipientEmail"
                    name="email"
                    type="email"
                    label="Email"
                    disabled={!isNewDeliveryRecipient}
                    onChange={onDeliveryRecipientFieldChange}
                    value={deliveryRecipient?.email}
                    isValid={!errors?.email}
                    feedback={errors?.email}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-6">
        <div className="row">
          <div className="col-12 mb-3">
            <SelectField
              id="deliveryAddress"
              label="Delivery Address"
              options={getDestinationOptions()}
              onChange={(option: OptionType): void => onDeliveryAddressChange(option)}
              value={isNewDeliveryRecipient ? 'new' : `${deliveryRecipient?.id},${deliveryAddress?.id}`}
              isValid={!errors?.deliveryAddress}
              feedback={errors?.deliveryAddress}
            />
          </div>
          {isNewDeliveryRecipient && (
            <div className="col-12 mb-3">
              <DestinationSelectField
                id="newDeliveryAddress"
                label="New Delivery Address"
                options={prevDestinations}
                onChange={onDeliveryDestinationChange}
                value={deliveryAddress?.id ?? 'new'}
                isValid={!errors?.deliveryAddress}
                feedback={errors?.deliveryAddress}
                autoComplete={false}
                defaultValue={
                  deliveryAddress
                    ? {
                        label: deliveryAddress?.name,
                        value: 'new',
                      }
                    : undefined
                }
                clearable
              />
            </div>
          )}
          <div className="col-12">
            <DraggableMarkerMap marker={marker} disabled={!isNewDeliveryRecipient} onPinChanged={onPinChanged} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ orderStepper }: StateProps): any => ({ ...orderStepper });
const mapDispatchToProps = { ...OrderStepperActions };
const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveryForm);
export default Connected;
