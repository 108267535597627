/* eslint-disable react-hooks/exhaustive-deps */
import * as OrderStepperActions from 'actions/orderStepper';
import { OrderStepperActionsProps } from 'actions/types/orderStepper';
import classnames from 'classnames';
import { Alert } from 'components/Alerts';
import { TextField } from 'components/FormFields';
import TextSearchField from 'components/FormFields/TextSearchField/TextSearchField';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { StateProps } from 'reducers';
import { OrderStepperStateProps, productListProp } from 'reducers/orderStepper';
import { getMetrcPackageByTag } from 'services/metrc';
import { MetrcPackage, OrderItem } from 'types';
import { AlertType } from 'types/alert';
import { api } from 'config';

const { metrc: metrcApi } = api;

interface Props extends OrderStepperStateProps, OrderStepperActionsProps {
  deliveryIndex: number;
  stepperState: any;
  isValid: boolean;
  errors: any;
  onBeforeSearchProduct: (
    deliveryIndex: number,
    productList: productListProp,
    productIndex: number,
    metrcReferenceNumber: string,
  ) => boolean;
  onSearchedProductNotFound: (index: number, metrcReferenceNumber: string) => void;
  onSearchedProductFound: (
    deliveryIndex: number,
    productIndex: number,
    product: OrderItem,
    metrcPackage: MetrcPackage,
  ) => void;
}

const ProductsForm: React.FC<Props> = ({
  deliveryIndex,
  // State
  deliveries,
  // Props
  errors,
  // Actions
  onNewProduct,
  onProductDescriptionChanged,
  onProductNameChanged,
  onProductMetrcLicenseChanged,
  onProductQuantityChanged,
  onRemoveProduct,
  onBeforeSearchProduct,
  onSearchedProductNotFound,
  onSetEmptyProducWithTag,
  onSearchedProductFound,
}: Props) => {
  const productList = deliveries[deliveryIndex].productList;
  const isMetrcApiDisabled = !metrcApi;

  const onSearchProduct = async (productIndex: number, tag: string): Promise<void> => {
    const hasErrors = onBeforeSearchProduct(deliveryIndex, productList, productIndex, tag);

    if (hasErrors) {
      return;
    }

    onSetEmptyProducWithTag(deliveryIndex, productIndex, tag);

    if (isMetrcApiDisabled) {
      return;
    }

    const metrcPackage = await getMetrcPackageByTag(tag);

    if (metrcPackage) {
      const product: OrderItem = {
        description: metrcPackage.item.description || '',
        item: metrcPackage.item.name,
        metrcReferenceNumber: metrcPackage.label,
        quantity: metrcPackage.quantity || 0,
      };
      onSearchedProductFound(deliveryIndex, productIndex, product, metrcPackage);
    } else {
      onSearchedProductNotFound(productIndex, tag);
    }
  };

  return (
    <>
      <div className="row">
        <div className="col">
          <h2>Products</h2>
          <p className="tagline text-secondary">Product Details by Package</p>
        </div>
        <div className="col-12">
          <Alert type={AlertType.primary} isShowing={true}>
            <span>
              <i>
                We are working diligently to integrate this section of the transport request from with METRC. Until
                integration is complete, please use the <b>description</b> section to denote the size of the order, and
                any additional information our operations team should know about the order.
              </i>
            </span>
          </Alert>
        </div>
      </div>
      <div className="row d-none d-lg-flex">
        <div className="col-3">
          <label htmlFor="metrcReferenceNumber">Metrc Reference #</label>
        </div>
        <div className="col-3">
          <label htmlFor="description">Description</label>
        </div>
        <div className="col-3 pl-0">
          <label htmlFor="item">Name</label>
        </div>
        <div className="col-3 pl-0">
          <label htmlFor="quantity">Quantity</label>
        </div>
      </div>
      {productList.products.map((product, productIndex) => (
        <div key={productIndex} className={classnames('row m-0 p-1', productIndex % 2 === 0 ? 'bg-white-2' : '')}>
          <div className="col-xs-12 col-sm-6 col-lg-3 pl-0">
            <TextSearchField
              id="metrcReferenceNumber"
              name="metrcReferenceNumber"
              type="text"
              placeholder="Metrc Reference #"
              inputClassName="my-1"
              onSearch={(tag: string): Promise<void> => onSearchProduct(productIndex, tag)}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                onProductMetrcLicenseChanged(deliveryIndex, productIndex, e.target.value)
              }
              value={product?.metrcReferenceNumber || ''}
              isValid={errors[productIndex] && !errors[productIndex].metrcReferenceNumber}
              feedback={errors[productIndex] && errors[productIndex].metrcReferenceNumber}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-3 pl-0">
            <TextField
              id="description"
              name="description"
              type="text"
              placeholder="Description"
              inputClassName="my-1"
              disabled={!isMetrcApiDisabled}
              required={false}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                onProductDescriptionChanged(deliveryIndex, productIndex, e.target.value)
              }
              value={product?.description || ''}
              isValid={errors[productIndex] && !errors[productIndex].description}
              feedback={errors[productIndex] && errors[productIndex].description}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-3 pl-0">
            <TextField
              id="item"
              name="item"
              type="text"
              placeholder="Name"
              inputClassName="my-1"
              disabled={!isMetrcApiDisabled}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                onProductNameChanged(deliveryIndex, productIndex, e.target.value)
              }
              value={product?.item || ''}
              isValid={errors[productIndex] && !errors[productIndex].item}
              feedback={errors[productIndex] && errors[productIndex].item}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-3 pl-0 d-flex align-items-start">
            <TextField
              id="quantity"
              name="quantity"
              type="number"
              placeholder="Quantity"
              inputClassName="my-1"
              disabled={!isMetrcApiDisabled}
              onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                onProductQuantityChanged(deliveryIndex, productIndex, e.target.value)
              }
              value={product?.quantity || ''}
              isValid={errors[productIndex] && !errors[productIndex].quantity}
              feedback={errors[productIndex] && errors[productIndex].quantity}
            />
            {productIndex > 0 && (
              <button
                className="btn btn-transparent no-hover ml-2 p-0 pt-3"
                disabled={productIndex === 0}
                onClick={(): void => onRemoveProduct(deliveryIndex, productIndex)}
              >
                <i className="fas fa-trash fa-lg"></i>
              </button>
            )}
          </div>
        </div>
      ))}
      <div className="row">
        <div className="col-12 text-center pt-3">
          <button
            className="btn btn-transparent text-uppercase ml-auto"
            disabled={false}
            onClick={(): void => onNewProduct(deliveryIndex)}
          >
            <i className="fas fa-plus mr-2"></i>
            Add Product
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ orderStepper }: StateProps): any => ({
  deliveries: orderStepper.deliveries,
});

const mapDispatchToProps = { ...OrderStepperActions };

const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(ProductsForm);

export default Connected;
