import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import styles from './AppLogo.module.scss';

export type AppLogoProps = {
  redirectUrl: string;
  src: string;
  srcSet: string;
  alt: string;
  width: string;
  heigth: string;
};

const AppLogo: React.FC<AppLogoProps> = React.memo(({ redirectUrl, src, srcSet, alt, width, heigth }: AppLogoProps) => (
  <Link to={redirectUrl} rel="noreferrer noopener" id="main-navigation__logo" data-test-id="main-navigation__logo">
    <img
      id="main-navigation__logoImage"
      className={classnames(styles.appLogo)}
      alt={alt}
      src={src}
      srcSet={srcSet}
      width={width}
      height={heigth}
    ></img>
  </Link>
));

export default AppLogo;
