import React from 'react';
import { OrderItem } from 'types';

export type Props = {
  items: Array<OrderItem>;
};

const OrderProductsTable: React.FC<Props> = ({ items }: Props) => {
  return items && items.length > 0 ? (
    <div id="orderProductsTable" className="table-container" role="table" aria-label="Product details">
      <div className="flex-table header" role="rowgroup">
        <div className="flex-table-row first" role="columnheader">
          Metrc Reference #
        </div>
        <div className="flex-table-row" role="columnheader">
          Description
        </div>
        <div className="flex-table-row" role="columnheader">
          Name
        </div>
        <div className="flex-table-row" role="columnheader">
          Quantity
        </div>
      </div>
      {items.map((orderItem: OrderItem) => (
        <div key={orderItem.item} className="flex-table data-row" role="rowgroup">
          <div className="flex-table-row first" role="cell">
            {orderItem.metrcReferenceNumber ?? '-'}
          </div>
          <div className="flex-table-row" role="cell">
            {orderItem.description}
          </div>
          <div className="flex-table-row" role="cell">
            {orderItem.item}
          </div>
          <div className="flex-table-row" role="cell">
            {orderItem.quantity || '-'}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div id="orderProductsEmptyWrapper">No items to display</div>
  );
};

export default OrderProductsTable;
