import React from 'react';
import cls from 'classnames';
import { CashChange, Denomination } from 'types';
import style from './OrderDetail.module.scss';
import { currencyFormat } from 'utils';

type OrderTransactionItemsProps = {
  cashChange?: CashChange;
};

const OrderDetailCashDropsTransaction: React.FC<OrderTransactionItemsProps> = ({
  cashChange,
}: OrderTransactionItemsProps): any => {
  const dataItems = (cashChange && cashChange.denominations) || [];
  return (
    <div className="order-items" data-id="order-items">
      {dataItems && dataItems.length ? (
        <table className="w-100 mt-4">
          <thead>
            <tr>
              <th className={style.label}>Denomination</th>
              <th className={style.label}>Quantity</th>
              <th className={style.label}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {dataItems.map(({ denomination, quantity, amount }: Denomination, idx: number) => (
              <tr key={idx}>
                <td className={cls(style.cell, 'border-bottom border-gray pr-2')} data-id="order-item-denomination">
                  {denomination}
                </td>
                <td className={cls(style.cell, 'border-bottom border-gray pr-2')} data-id="order-item-quantity">
                  {quantity}
                </td>
                <td className={cls(style.cell, 'border-bottom border-gray pr-2')} data-id="order-item-amount">
                  {currencyFormat(amount)}
                </td>
              </tr>
            ))}
            <tr>
              <td className={cls(style.cell, 'border-bottom border-gray pr-2')} data-id="order-total-title" colSpan={2}>
                <b>Total</b>
              </td>
              <td className={cls(style.cell, 'border-bottom border-gray pr-2')} data-id="order-total">
                <b>{currencyFormat(cashChange?.total ?? 0)}</b>
              </td>
            </tr>
          </tbody>
        </table>
      ) : (
        <span>No Items</span>
      )}
    </div>
  );
};

export default OrderDetailCashDropsTransaction;
