import React, { useState, useContext, useEffect } from 'react';
import { isEmpty, pick } from 'lodash';
import classnames from 'classnames';
import { withAuthorization, withPageView } from 'components';
import { Destination, Recipient, DayOfWeek } from 'types';
import { AuthContext } from 'contexts';
import { createDestination, updateDestination } from 'services/destinations';
import { mapbox } from 'config/index';
import ReactMapGL, { Marker } from 'react-map-gl';
import { Drawer } from 'components/Drawer';
import { AddressCard } from '.';
import { getCustomerDestinations } from 'services/customers';
import { createMRALicense, findRecipientLicense, getCustomerLicenses, getRecipientsByOwner } from 'services';
import { DestinationSelectField, SelectField, TextField } from 'components/FormFields';
import { Spinner } from 'components/Loaders';

import styles from './AddressBook.module.scss';
import styleAccount from './AccountForm.module.scss';
import { OptionType } from 'components/Select';
import { CollapseHeader, CollapseContent } from 'components/Collapse';

const defaultLongitude = -83.1692448;
const defaultLatitude = 42.3528165;
const mapStyle = 'mapbox://styles/mapbox/streets-v10';

const defaultStartTime = '08:00';
const defaultEndTime = '17:00';

type ContactError = {
  metrcLicense: string;
  contactName: string;
  phoneNumber: string;
  address: string;
  owner: string;
};

type FormState = Partial<Destination> & {
  metrcLicense?: string;
};

const AddressBook: React.FC = () => {
  const [destinations, setDestinations] = useState<Array<Destination> | null>();
  const [recipients, setRecipients] = useState<Array<Recipient> | null>();
  const [formErrors, setFormErrors] = useState<ContactError>();
  const [formState, setFormState] = useState<FormState>();
  const [selectedDestination, setSelectedDestination] = useState<Destination>();
  const [openAddressDetails, setOpenAddressDetails] = useState<boolean>(false);
  const [viewport, setViewport] = useState<any | null>();
  const { currentUser } = useContext(AuthContext);
  const [isSaving, setIsSaving] = useState(false);

  const getData = ({ destinations, metrcLicenses, recipients }): void => {
    if (destinations) {
      destinations = destinations.map(d => ({
        ...d,
        metrcLicenses: metrcLicenses?.filter(license => license.destinationId === d.id),
      }));

      setDestinations(destinations);
    }

    if (!recipients?.length && currentUser?.recipientInfo) {
      recipients.push(currentUser?.recipientInfo);
    }

    setRecipients(recipients);
  };

  const onReceivingHoursChange = (day: keyof typeof DayOfWeek) => (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (!value) {
      return;
    }

    const { receivingHours = [] } = formState || {};

    const receivingHour = receivingHours?.find(({ dayOfWeek }) => dayOfWeek === day) ?? { dayOfWeek: day };

    setFormState(prevState => ({
      ...prevState,
      receivingHours: [...receivingHours?.filter(rh => rh.dayOfWeek !== day), { ...receivingHour, [name]: value }],
    }));
  };

  const onEdit = (item: Destination): void => {
    setSelectedDestination(item);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    setIsSaving(true);

    const { metrcLicense = '', ...rest } = formState || {};

    const { id, name, owner = '', receivingHours = [] } = rest;

    const errorList = new Map();

    if (isEmpty(metrcLicense)) {
      errorList.set('metrcLicense', 'Metrc License is required');
    } else if (metrcLicense && !id) {
      const alreadyExist = await findRecipientLicense(metrcLicense);

      if (alreadyExist) {
        errorList.set('metrcLicense', 'Metrc License already exists');
      }
    }

    if (isEmpty(name)) {
      errorList.set('address', 'Address is required');
    }

    if (isEmpty(owner)) {
      errorList.set('owner', 'Recipient is required');
    }

    if (!isEmpty(errorList)) {
      setFormErrors(Object.fromEntries(errorList));
      setIsSaving(false);
      return;
    }

    const destination = {
      ...pick(rest, 'name', 'address', 'location', 'owner', 'extra', 'active'),
      receivingHours: receivingHours.filter(
        rh => !isEmpty(rh.dayOfWeek) && !isEmpty(rh.startTime) && !isEmpty(rh.endTime),
      ),
    } as Destination;

    const newDestination = id ? await updateDestination(id, destination) : await createDestination(destination);

    if (newDestination) {
      await createMRALicense({
        disabled: false,
        recipientId: owner,
        number: metrcLicense,
        destinationId: newDestination.id,
        owner: currentUser?.recipientId || '',
      });

      if (currentUser?.recipientId) {
        const metrcLicenses = await getCustomerLicenses(currentUser?.recipientId);
        const destinations = await getCustomerDestinations(currentUser?.recipientId);
        const recipients = await getRecipientsByOwner(currentUser?.uid);
        getData({ destinations, recipients, metrcLicenses });
      }
    }

    handleCloseModal();
    setIsSaving(false);
  };

  const handleCloseModal = (): void => {
    setFormState(undefined);
    setFormErrors(undefined);
    setSelectedDestination(undefined);
    setOpenAddressDetails(false);
  };

  const handleShowModal = (): void => {
    setOpenAddressDetails(true);
  };

  const selectedAddress = (address: any): void => {
    setFormState({ ...formState, ...address });
    setViewport({ ...viewport, latitude: address.location.lat, longitude: address.location.lon });
  };

  useEffect(() => {
    const getUserLocation = (): void => {
      const location = {
        lat: defaultLatitude,
        lon: defaultLongitude,
      };

      const viewport = {
        width: 400,
        height: 400,
        latitude: location.lat,
        longitude: location.lon,
        zoom: 8,
      };
      setViewport(viewport);
    };

    if (currentUser) {
      const { destinations, recipients, metrcLicenses } = currentUser;
      getData({ destinations, recipients, metrcLicenses });
      getUserLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  useEffect(() => {
    if (selectedDestination) {
      setFormState({ ...selectedDestination, metrcLicense: selectedDestination.metrcLicenses?.[0]?.number });
      setViewport({
        latitude: selectedDestination.location.lat,
        longitude: selectedDestination.location.lon,
        zoom: 5,
      });
      setOpenAddressDetails(true);
    }
  }, [selectedDestination]);

  return (
    <>
      {isSaving && <Spinner message="Saving..." overScren={true} />}
      <div className={classnames('row', styles.container)}>
        {destinations?.map(item => {
          return <AddressCard key={item.id} item={item} onEdit={onEdit}></AddressCard>;
        })}
        <div className={classnames('col-md-12 col-sm-12 text-center')}>
          <button
            id="add-address-btn"
            disabled={!currentUser?.recipientId}
            className={classnames(currentUser?.recipientId ? styleAccount.formSubmit : styleAccount.formSubmitDisabled)}
            type="button"
            onClick={handleShowModal}
          >
            <i className={classnames('fa fa-plus', styles.plusIcon)}></i>
            ADD ADDRESS
          </button>
        </div>
      </div>
      <Drawer
        open={openAddressDetails}
        title="New Address"
        onClose={(): void => setOpenAddressDetails(false)}
        isAddressForm={true}
      >
        <ReactMapGL
          {...viewport}
          width="100%"
          height="250px"
          mapboxApiAccessToken={mapbox.accessToken}
          mapStyle={mapStyle}
          onViewportChange={(nextViewport): void => setViewport(nextViewport)}
        >
          {viewport?.latitude && viewport?.longitude && (
            <Marker latitude={viewport?.latitude} longitude={viewport?.longitude}>
              <i className={classnames('fas fa-map-marker-alt fa-2x', styles.marker)}></i>
            </Marker>
          )}
        </ReactMapGL>
        <div className="pl-4 pr-4">
          <div className={classnames(styles.title, 'pt-4')}>
            <i className={classnames('fas fa-exclamation-circle fa-2x')}></i>
            <span>Drag the pin to customize the pick-up address.</span>
          </div>
          <form id="address-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-12 mb-2">
                <TextField
                  id="contactMetrcLicense"
                  name="contactMetrcLicense"
                  type="text"
                  label="Metrc License"
                  placeholder="Enter a Metrc License"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>): void =>
                    setFormState({ ...formState, metrcLicense: e.target.value })
                  }
                  value={formState?.metrcLicense ?? ''}
                  isValid={!formErrors?.metrcLicense}
                  feedback={formErrors?.metrcLicense}
                />
              </div>
              <div className="col-12 mb-2">
                <DestinationSelectField
                  id="newAddress"
                  options={[]}
                  label="Contact Address"
                  onChange={selectedAddress}
                  defaultValue={formState?.name ? { label: formState.name, value: formState.name } : undefined}
                  isValid={!formErrors?.address}
                  feedback={formErrors?.address}
                />
              </div>
              <div className="col-12 mb-2">
                <SelectField
                  label="Business"
                  onChange={(option: OptionType): void => setFormState({ ...formState, owner: option?.value })}
                  options={recipients?.map(r => ({ value: r.id, label: r.name })) || []}
                  value={formState?.owner ?? ''}
                  isValid={!formErrors?.owner}
                  feedback={formErrors?.owner}
                />
              </div>
              <div id={'receivingHours'} className="col-12 mb-2 accordion">
                <div className="panel panel-default">
                  <CollapseHeader shouldRoundBottom title="Shipping & Receiving Hours" index={0} />
                  <CollapseContent collapsed={true} id="receivingHours" index={0}>
                    <div className="col-12 mb-2">
                      <table className="col-12">
                        <thead>
                          <tr>
                            <th></th>
                            <th>Day Of Week</th>
                            <th>Start Time</th>
                            <th>End Time</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {Object.entries(DayOfWeek).map(([key, value]) => (
                            <tr key={key}>
                              <td>
                                <div className="custom-control custom-switch">
                                  <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={formState?.receivingHours?.some(day => day.dayOfWeek === key)}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                                      const { receivingHours = [] } = formState ?? {};

                                      if (!e.target.checked) {
                                        setFormState({
                                          ...formState,
                                          receivingHours: receivingHours?.filter(day => day.dayOfWeek !== key),
                                        });
                                      } else {
                                        const receivingHour = formState?.receivingHours?.find(
                                          day => day.dayOfWeek === key,
                                        ) ?? {
                                          dayOfWeek: key as keyof typeof DayOfWeek,
                                          startTime: defaultStartTime,
                                          endTime: defaultEndTime,
                                        };

                                        setFormState({
                                          ...formState,
                                          receivingHours: [
                                            ...receivingHours?.filter(day => day.dayOfWeek !== key),
                                            receivingHour,
                                          ],
                                        });
                                      }
                                    }}
                                  />
                                </div>
                              </td>
                              <td>{value}</td>
                              <td>
                                <TextField
                                  id="startTime"
                                  name="startTime"
                                  type="time"
                                  onChange={onReceivingHoursChange(key as keyof typeof DayOfWeek)}
                                  value={
                                    formState?.receivingHours?.find(({ dayOfWeek }) => dayOfWeek === key)?.startTime ??
                                    ''
                                  }
                                />
                              </td>
                              <td>
                                <TextField
                                  id="endTime"
                                  name="endTime"
                                  type="time"
                                  onChange={onReceivingHoursChange(key as keyof typeof DayOfWeek)}
                                  value={
                                    formState?.receivingHours?.find(({ dayOfWeek }) => dayOfWeek === key)?.endTime ?? ''
                                  }
                                />
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </CollapseContent>
                </div>
              </div>
            </div>
            <button disabled={isSaving} id="accept-submit-btn" className={styles.formSubmit} type="submit">
              {formState?.id ? 'SAVE' : 'ADD'}
            </button>
            <button
              disabled={isSaving}
              id="cancel-submit-address"
              className={classnames('ml-2', styles.formSubmitCancel)}
              type="button"
              onClick={handleCloseModal}
            >
              CANCEL
            </button>
          </form>
        </div>
      </Drawer>
    </>
  );
};

const Enhanced = withPageView(AddressBook, 'Address Book');
export default withAuthorization(Enhanced);
