import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { isNull } from 'lodash';
import { AuthContext } from '../../contexts';
import { Spinner } from '../Loaders';
import * as routes from '../../constants/routes';

export default function WithNoAuthorization<Props>(
  WrappedComponent: React.ComponentType<Props>,
): {
  (props: Props): JSX.Element;
} {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const WithNoAuthorization = (props: Props): React.ReactElement => {
    const { currentUser } = useContext(AuthContext);

    if (currentUser?.uid) {
      return <Redirect to={routes.dashboard} />;
    }

    if (isNull(currentUser)) {
      return <WrappedComponent {...(props as Props)} />;
    }

    return <Spinner message="Loading ..." />;
  };

  WithNoAuthorization.displayName = `WithNoAuthorization(${displayName})`;

  return WithNoAuthorization;
}
