import React from 'react';
import { Destination, Recipient } from 'types';
import { OrderPickupInfo } from './OrderPickupInfo';
import { OrderDeliveryInfo } from './OrderDeliveryInfo';

type OrderInfoProps = {
  isCashDeposit: boolean;
  originInfo?: Destination;
  senderInfo?: Recipient;
  destinationInfo?: Destination;
  recipientInfo?: Recipient;
};

const OrderInfo: React.FC<OrderInfoProps> = ({
  isCashDeposit,
  destinationInfo,
  originInfo,
  recipientInfo,
  senderInfo,
}: OrderInfoProps) => {
  return isCashDeposit ? (
    <OrderPickupInfo originInfo={originInfo} senderInfo={senderInfo} />
  ) : (
    <OrderDeliveryInfo destinationInfo={destinationInfo} recipientInfo={recipientInfo} />
  );
};

export default OrderInfo;
