import React from 'react';
import { FileInfo } from 'types';

export type Props = {
  title: string;
  files?: Array<FileInfo>;
};

const AttachmentFiles: React.FC<Props> = ({ title, files }: Props) => {
  const handleOnClickFile = (fileUrl?: string): void => {
    if (fileUrl) {
      window.open(fileUrl, '_blank');
      return;
    }
  };
  return (
    <>
      <span className="label">{title} </span>
      {files && files.length > 0 ? (
        <div id="attachmentFiles" className="attachments row">
          {files.map((file: FileInfo, index: number) => (
            <div key={index} className="col-md-4 col-sm-12" onClick={(): void => handleOnClickFile(file.fileUrl)}>
              <div className="attachments__container__file">
                <div className="attachments__container__file--name">
                  <span>{file.fileName}</span>
                </div>
                <div className="attachments__container__file--link">
                  <span>
                    <i className="fas fa-arrow-alt-circle-down fa-lg"></i>
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div id="orderProductsEmptyWrapper">No files to display</div>
      )}
    </>
  );
};

export default AttachmentFiles;
