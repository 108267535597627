import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import Alert from 'types/alert';

import { withNoAuthorization, withAlert, withPageView } from 'components';
import { LoginForm } from './components';
import AuthLayout from 'components/Layout/AuthLayout';

interface Props extends Alert, RouteComponentProps {}

const LoginPage: React.FC<Props> = (props: Props) => {
  return (
    <AuthLayout title={'Welcome ! Please login to your account'}>
      <LoginForm warning={props.warning} error={props.error} />
    </AuthLayout>
  );
};

const Enhanced = compose(withNoAuthorization, withAlert, withPageView)(LoginPage, 'Login');
export default withRouter(Enhanced as React.ComponentType<any>);
