import { firestore } from '_firebase';
import { isEmpty, orderBy } from 'lodash';
import { Order, Driver, Recipient, Destination, OrderStatus, OrderDeliveryItem, AdditionalProps } from 'types';
import {
  getDrivers,
  getDriverById,
  getRecipients,
  getRecipientById,
  getDestinations,
  getDestinationById,
} from 'services';
import * as collections from 'constants/collections';
import { sumArrayValues, currencyFormat } from 'utils';

const getIterableDataFromCollection = (dataListRef: any): Array<any> => {
  const data: Array<any> = [];

  if (!dataListRef.empty) {
    dataListRef.forEach(doc => {
      const dataItem = {
        id: doc.id,
        ...doc.data(),
      };

      data.push(dataItem);
    });
  }
  return data;
};

const mapOrders = (ordersRef: any): Array<Order> => {
  const orders: Array<Order> = [];
  const associatedDrivers: Array<string> = [];
  const associatedRecipients: Array<string> = [];
  const associatedDestinations: Array<string> = [];
  const associatedOrigins: Array<string> = [];

  if (!ordersRef.empty) {
    ordersRef.forEach(async (doc: any) => {
      const order = doc.data();
      const {
        driverId,
        recipientId,
        destinationId,
        originId,
        requestedDelivery,
        requestedPickup,
        attachments,
        parentId,
        estimatedDelivery,
        items,
      } = order;

      associatedDrivers.push(driverId);
      associatedRecipients.push(recipientId);
      associatedDestinations.push(destinationId);
      associatedOrigins.push(originId);

      orders.push({
        id: doc.id,
        driverId,
        isFreshFrozenTransport: order.isFreshFrozenTransport ?? false,
        recipientId,
        destinationId,
        terminalId: order.terminalId,
        assistantId: order.assistantId,
        estimatedPickup: order.estimatedPickup,
        invoiceItems: order.invoiceItems,
        notes: order.notes,
        fuelSurcharge: order.fuelSurcharge,
        status: order.status,
        creator: order.creator,
        createdAt: order.createdAt,
        senderId: order.senderId,
        originId: order.originId,
        vehicleId: order.vehicleId,
        orderNumber: order.orderNumber,
        requestedPickup: requestedPickup,
        parentId: parentId,
        estimatedDelivery,
        items,
        attachments,
        requestedDelivery,
        deliveries: [],
        type: order.type,
        transactionType: order.transactionType,
        depositType: order.depositType,
        cashChange: order.cashChange,
      });
    });
  }

  return orders;
};

const getAdditionPropsFrom = async (taskId: string, orderInfo: Order): Promise<AdditionalProps | undefined> => {
  if (!taskId) return undefined;
  const taskRef = await firestore.collection(collections.ORDERS).doc(taskId).get();
  if (taskRef?.exists) {
    const taskData = taskRef.data();
    return {
      driverId: taskData?.driverId ?? null,
      recipientId: taskData?.recipientId ?? null,
      destinationId: taskData?.destinationId ?? null,
      estimatedPickup: taskData?.estimatedPickup ?? null,
      estimatedDelivery: taskData?.estimatedDelivery ?? null,
      orderNumber: orderInfo.orderNumber,
      orderId: orderInfo.id,
      orderStatus: orderInfo.status,
    };
  }
  return undefined;
};

const getTaskDataInfo = async (id: string): Promise<AdditionalProps | undefined> => {
  const orderListRef = await firestore.collection(collections.ORDER_LIST).where('orderRequestId', '==', id).get();
  const orderList = getIterableDataFromCollection(orderListRef);

  if (!isEmpty(orderList) && orderList[0].id) {
    const orderInfo = orderList[0];
    if (orderInfo) {
      const { pickupTaskId, deliveryTaskId } = orderInfo;
      return getAdditionPropsFrom(pickupTaskId ?? deliveryTaskId, orderInfo);
    }
  }
  return undefined;
};

export const getOrderHistory = async (customerId: string): Promise<Array<Order>> => {
  const orderRequests = await firestore.collection(collections.ORDER_REQUESTS).where('creator', '==', customerId).get();
  const orders = mapOrders(orderRequests);

  const drivers: Array<Driver> = await getDrivers();
  const recipients: Array<Recipient> = await getRecipients();
  const destinations: Array<Destination> = await getDestinations();

  // Use Promise.all to wait for all asynchronous operations to complete
  await Promise.all(
    orders.map(async order => {
      if ([OrderStatus.ACCEPTED, OrderStatus.CREATED].includes(order.status)) {
        const destination = destinations.find(d => d.id === order.destinationId);
        const origin = destinations.find(d => d.id === order.originId);
        const sender = recipients.find(r => r.id === order.senderId);
        const recipient = recipients.find(r => r.id === order.recipientId);
        // Modify the order directly
        Object.assign(order, {
          senderInfo: sender,
          originInfo: origin,
          destinationInfo: destination,
          recipientInfo: recipient,
        });

        if (order.status === OrderStatus.ACCEPTED) {
          const taskDataProps = await getTaskDataInfo(order.id);
          if (taskDataProps) {
            const driver = drivers.find(d => d.id === taskDataProps.driverId);
            // Modify the order directly
            Object.assign(order, {
              driverInfo: driver,
              estimatedDelivery: taskDataProps.estimatedDelivery,
              orderNumber: taskDataProps.orderNumber,
              orderId: taskDataProps.orderId,
              status: taskDataProps.orderStatus,
            });
          }
        }
      }
    }),
  );

  return orderBy(orders, 'createdAt', 'desc');
};

const getOrderDeliveryData = async (orderRefId: string, orderItem: any): Promise<OrderDeliveryItem> => {
  if (orderItem.recipientId) {
    orderItem.recipientInfo = await getRecipientById(orderItem.recipientId);
  }

  if (orderItem.destinationId) {
    orderItem.destinationInfo = await getDestinationById(orderItem.destinationId);
  }
  return {
    orderRefId: orderRefId,
    ...orderItem,
  };
};

export const getOrderById = async (orderId: string): Promise<Order | undefined> => {
  const orderRequestRef = await firestore.collection(collections.ORDER_REQUESTS).doc(orderId).get();

  const relatedOrders = await firestore.collection(collections.ORDER_REQUESTS).where('parentId', '==', orderId).get();
  const orderDataRef = await firestore.collection(collections.ORDER_LIST).where('orderRequestId', '==', orderId).get();
  let relatedOrderList: Array<any> = [];

  if (!orderRequestRef.exists) {
    return undefined;
  }

  if (!relatedOrders.empty) {
    relatedOrderList = getIterableDataFromCollection(relatedOrders);
  }

  const orderData = (orderRequestRef.data() || {}) as Order;

  if (orderData.status === OrderStatus.ACCEPTED && !orderDataRef.empty) {
    const orderList = getIterableDataFromCollection(orderDataRef);
    const baseOrder = orderList.find(o => o.orderRequestId === orderRequestRef.id);

    if (baseOrder) {
      orderData.orderId = baseOrder.id;
      orderData.orderNumber = baseOrder.orderNumber;
      orderData.estimatedDelivery = baseOrder.estimatedDelivery;
      orderData.estimatedPickup = baseOrder.estimatedPickup;
      orderData.orderTotal = baseOrder.orderTotal;
      orderData.status = baseOrder.status;

      let taskRelatedRef: any = {};

      if (baseOrder.pickupTaskId) {
        taskRelatedRef = await firestore.collection(collections.ORDERS).doc(baseOrder.pickupTaskId).get();
      } else if (baseOrder.deliveryTaskId) {
        taskRelatedRef = await firestore.collection(collections.ORDERS).doc(baseOrder.deliveryTaskId).get();
      }

      if (taskRelatedRef.exists) {
        const taskRelatedData = taskRelatedRef.data() || {};
        orderData.driverId = taskRelatedData.driverId;
        orderData.recipientId = taskRelatedData.recipientId;
        orderData.destinationId = taskRelatedData.destinationId;
      }
    }
  }

  const deliveryOrders: Array<OrderDeliveryItem> = [
    {
      ...(await getOrderDeliveryData(orderRequestRef.id, orderData)),
    },
  ];

  for (let i = 0; i < relatedOrderList.length; i++) {
    const relatedOrderItem = relatedOrderList[i];
    const orderItem = await getOrderDeliveryData(relatedOrderItem.id, relatedOrderItem);
    deliveryOrders.push(orderItem);
  }

  const order: Order = {
    ...orderData,
    id: orderRequestRef.id,
    orderRequestId: orderRequestRef.id,
    deliveries: deliveryOrders,
  };

  if (order.driverId) {
    order.driverInfo = await getDriverById(order.driverId);
  }

  if (order.senderId) {
    order.senderInfo = await getRecipientById(order.senderId);
  }

  if (order.originId) {
    order.originInfo = await getDestinationById(order.originId);
  }

  return order;
};

// TODO: move this somewhere else
export const getOrderTotal = ({ deliveries }: Order): string => {
  const items = deliveries[0].items;
  let total = 0;

  if (items) {
    const totalItemValues: Array<number> = [];

    items.forEach(item => {
      if (item.price && item.quantity) {
        totalItemValues.push(item.price * item.quantity);
      }
    });

    total = sumArrayValues(totalItemValues);
  }

  return currencyFormat(total);
};

export const getOrderReference = async (): Promise<any> => await firestore.collection(collections.ORDER_REQUESTS).doc();
