import { OrderStatus } from 'types';

export const getCurrentOrderStatus = (orderStatus: string, isRequest: boolean): OrderStatus => {
  // If orderStatus is not defined, return DRAFT
  if (!orderStatus) return OrderStatus.DRAFT;
  // If orderStatus is CREATED, it means that has been scheduled
  if (orderStatus === OrderStatus.CREATED && !isRequest) return OrderStatus.SCHEDULED;
  // If orderStatus is ARRIVAL or COMPLETED, return COMPLETED
  if ([OrderStatus.ARRIVAL, OrderStatus.COMPLETED].includes(OrderStatus[orderStatus])) return OrderStatus.COMPLETED;
  // Otherwise return the orderStatus
  return OrderStatus[orderStatus];
};
