import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { firebase as config } from 'config';

if (!firebase.apps.length) {
  firebase.initializeApp(config);
}

const auth = firebase.auth();
const firestore = firebase.firestore();

if (process.env.REACT_APP_FIREBASE_EMULATORS === 'on') {
  firestore.useEmulator('localhost', 8088);
  auth.useEmulator('http://localhost:9099');
}

export { firebase, auth, firestore };
