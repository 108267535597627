import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { connect } from 'react-redux';
import { Alert } from 'components/Alerts';
import { PickupStep, DeliveriesStep } from 'components/OrderRequest/Steps';
import { GuestContactForm } from 'components/Forms';
import { OptionType } from 'components/Select';
import { Stepper, StepperFeedback } from 'components/Stepper';
import withAlert from 'components/WithAlert';
import { dashboard } from 'constants/routes';
import { USER_HAS_NOT_RECIPIENT_ASSOCIATED } from 'constants/strings';
import { AuthContext } from 'contexts';
import { StateProps } from 'reducers';
import {
  OrderStepperDeliveryStateProps,
  OrderStepperStateProps,
  deliveryInfoProp,
  productListProp,
  EMPTY_DELIVERY_ITEM,
} from 'reducers/orderStepper';
import { saveOrderRequest, getOrderReference } from 'services';
import { useGetOrderById } from 'services/hooks/orders';
import * as OrderStepperActions from 'actions/orderStepper';
import { OrderStepperActionsProps } from 'actions/types/orderStepper';
import { OrderRequest, OrderRequestStatus, UploadedFileModel, AttachmentInfo, FileInfo, SelectedFileInfo } from 'types';
import Alerts, { AlertType } from 'types/alert';
import { uploadFile, deleteFile } from 'services/fileService';
import { api } from 'config';
import { OrderContext } from 'contexts/orderContext';
import { OrderSteps } from 'types/orderContext';
import { Spinner } from 'components/Loaders';
import { useIsLoading } from 'services/hooks/useIsLoading';
const { metrc: metrcApi } = api;
const isMetrcApiDisabled = !metrcApi;

interface Props extends OrderStepperStateProps, OrderStepperActionsProps, Alerts {
  id: string;
}

enum RequestStatus {
  IDLE = 'idle',
  SUCCESS = 'success',
  ERROR = 'error',
}

const stepBaseState = {
  isValid: false,
  errors: {},
};

const stepDeliveriesBaseState = {
  [OrderSteps.DELIVERY]: stepBaseState,
  [OrderSteps.ATTACHMENT]: { ...stepBaseState, isValid: true },
};

const OrderStepper: React.FC<Props> = ({
  // State
  id,
  isGuestView,
  contact,
  pickup,
  deliveries,
  onCleanOrder,
  onNewDeliveryInfo,
  onRemoveDeliveryInfo,
  onSetDeliveries,
  // Utilities
  success,
  error,
}: Props) => {
  const { currentUser } = useContext(AuthContext);
  const { recipients = [], destinations = [], metrcLicenses } = currentUser || {};
  const { order } = useGetOrderById(id);
  const history = useHistory();
  const [requestStatus, setRequestStatus] = useState<RequestStatus>(RequestStatus.IDLE);
  const [orderId, setOrderId] = useState<string>(id);
  const [alert, setAlert] = useState<string | boolean>();
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [savingOrdersMsg, setSavingOrdersMsg] = useState<string>('Saving orders...');
  const [stepperState, setStepperState] = useState({
    [OrderSteps.CONTACT]: stepBaseState,
    [OrderSteps.PICKUP]: stepBaseState,
    [OrderSteps.DELIVERIES]: [stepDeliveriesBaseState],
  });
  const { isLoading } = useIsLoading();

  const { contactStep, pickupStep, deliveriesStep } = stepperState;
  const isGuestUser = isGuestView || !currentUser;

  const nextButtonRef = React.createRef<HTMLButtonElement>();

  useEffect(() => {
    return (): void => {
      onCleanOrder();
    };
  }, [onCleanOrder]);

  useEffect(() => {
    if (!currentUser?.recipientId && !isGuestUser) {
      setAlert(USER_HAS_NOT_RECIPIENT_ASSOCIATED);
    }
  }, [currentUser, isGuestUser]);

  const isAllDeliveriesValid = useMemo(() => {
    const deliveriesSteps = stepperState.deliveriesStep;
    if (!deliveriesSteps.length) {
      return false;
    }
    return deliveriesSteps.every(delivery => Object.values(delivery).every(item => item.isValid));
  }, [stepperState]);

  const isAtLeastDeliveryFormValid = useMemo(() => {
    const deliveriesSteps = stepperState.deliveriesStep;
    return deliveriesSteps.some(delivery => delivery[OrderSteps.DELIVERY].isValid);
  }, [stepperState]);

  const updateDeliveryStepperState = (deliveryIndex: number, deliveryErrorList: any, attachmentErrorList): void => {
    setStepperState(prevValue => {
      return {
        ...prevValue,
        [OrderSteps.DELIVERIES]: prevValue[OrderSteps.DELIVERIES].map((delivery, index) => {
          if (index === deliveryIndex) {
            return {
              ...delivery,
              [OrderSteps.DELIVERY]: {
                isValid: isEmpty(deliveryErrorList),
                errors: deliveryErrorList,
              },
              [OrderSteps.ATTACHMENT]: {
                isValid: isEmpty(attachmentErrorList),
                errors: attachmentErrorList,
              },
            };
          }
          return delivery;
        }),
      };
    });
  };

  const validateStep = (step: OrderSteps, errorList: any): boolean => {
    const isValid = isEmpty(errorList);

    setStepperState({
      ...stepperState,
      [step]: {
        isValid,
        errors: errorList,
      },
    });

    return isValid;
  };

  const handleAddNewDelivery = (): void => {
    setStepperState({
      ...stepperState,
      [OrderSteps.DELIVERIES]: [...stepperState.deliveriesStep, stepDeliveriesBaseState],
    });
    onNewDeliveryInfo();
  };

  const handleDeleteDelivery = (index: number): void => {
    const updatedDeliveries = stepperState.deliveriesStep.filter((__item, itemIndex) => itemIndex !== index);
    setStepperState({
      ...stepperState,
      [OrderSteps.DELIVERIES]: updatedDeliveries,
    });
    onRemoveDeliveryInfo(index);
  };

  useEffect(() => {
    const fillDeliveries = async (): Promise<any> => {
      const orderDeliveries = order?.deliveries || [];
      if (!isEmpty(orderDeliveries) && isFirstLoad) {
        const updatedStepDeliveriesState: Array<any> = [];
        const updatedDeliveries: Array<OrderStepperDeliveryStateProps> = [];
        for (const orderDelivery of orderDeliveries) {
          if (orderDelivery) {
            updatedStepDeliveriesState.push(stepDeliveriesBaseState);
            updatedDeliveries.push(EMPTY_DELIVERY_ITEM);
          }
        }
        setStepperState({
          ...stepperState,
          [OrderSteps.DELIVERIES]: updatedStepDeliveriesState,
        });
        onSetDeliveries(updatedDeliveries);
        setIsFirstLoad(false);
      }
    };
    fillDeliveries();
  }, [order, isFirstLoad, onSetDeliveries, stepperState]);

  const destinationsOptions = (newOption = false): Array<OptionType> => {
    const options: Array<OptionType> = [];

    if (metrcLicenses) {
      metrcLicenses.forEach(license => {
        let recipient = recipients.find(r => license.recipientId === r.id);

        if (!recipient) {
          recipient = currentUser?.recipientInfo;
        }

        const destination = destinations.find(d => license.destinationId === d.id);

        if (recipient && destination) {
          const value = `${recipient.id},${destination.id}`;
          if (!options.find(option => option.value === value)) {
            options.push({
              value: value,
              label: `${recipient.name} - ${destination.name.split(',')[0]} (${license.number})`,
            });
          }
        }
      });
    }

    options.sort((a, b) => a.label.localeCompare(b.label));

    if (newOption) {
      options.push({
        value: 'new',
        label: 'Add New',
      });
    }

    return options;
  };

  const validateContactForm = (): boolean => {
    const errorList = new Map();

    if (isEmpty(contact.name)) {
      errorList.set('name', 'Name is missing');
    }

    if (isEmpty(contact.email)) {
      errorList.set('email', 'Email is missing');
    }

    return validateStep(OrderSteps.CONTACT, Object.fromEntries(errorList));
  };

  const validatePickupStep = (): boolean => {
    const errorList = new Map();

    if ((!pickup?.pickupAddress?.id && !pickup.isNewPickupRecipient) || (isGuestUser && !pickup?.pickupAddress)) {
      errorList.set('pickupAddress', 'Enter a pickup address or create a new one');
    }

    if (isEmpty(pickup?.requestedPickup)) {
      errorList.set('requestedPickup', 'Enter a preferred pickup date');
    }

    if (isEmpty(pickup?.pickupRecipient?.name)) {
      errorList.set('pickupContactName', 'Pickup contact name is missing');
    }

    if (isEmpty(pickup?.pickupRecipient?.phone)) {
      errorList.set('pickupPhone', 'Pickup contact phone is missing');
    }

    if (isEmpty(pickup?.pickupRecipient?.email)) {
      errorList.set('email', 'Email is mising');
    }

    if (isEmpty(pickup?.pickupRecipient?.metrcLicenseNumber)) {
      errorList.set('metrcLicense', 'METRC license is mising');
    }

    if (!pickup?.pickupAddressAsBilling && isEmpty(pickup?.billingAddressId)) {
      errorList.set('billTo', 'Select a billing address');
    }

    return validateStep(OrderSteps.PICKUP, Object.fromEntries(errorList));
  };

  const validateDeliveryForm = (delivery: deliveryInfoProp): Record<string, unknown> => {
    const errorList = new Map();

    if (
      (!delivery?.deliveryAddress?.id && !delivery.isNewDeliveryRecipient) ||
      (isGuestUser && !delivery?.deliveryAddress)
    ) {
      errorList.set('deliveryAddress', 'Enter a delivery address or create a new one');
    }

    if (isEmpty(delivery?.requestedDelivery)) {
      errorList.set('requestedDelivery', 'Enter a preferred delivery date');
    }

    if (isEmpty(delivery?.orderSize)) {
      errorList.set('orderSize', 'Enter the order size');
    }

    if (isEmpty(delivery?.deliveryRecipient?.name)) {
      errorList.set('deliveryContactName', 'Delivery contact name is missing');
    }

    if (isEmpty(delivery?.deliveryRecipient?.name)) {
      errorList.set('deliveryPhone', 'Delivery contact phone is missing');
    }

    if (isEmpty(delivery?.deliveryRecipient?.email)) {
      errorList.set('email', 'Email is mising');
    }

    if (isEmpty(delivery?.deliveryRecipient?.metrcLicenseNumber)) {
      errorList.set('metrcLicense', 'METRC license is missing');
    }

    return Object.fromEntries(errorList);
  };

  const isProductDuplicated = (productList: productListProp, metrcReferenceNumber: string): boolean =>
    productList.products?.filter(p => p.metrcReferenceNumber === metrcReferenceNumber).length > 1;

  const metrcReferenceNumberExists = (productList: productListProp, metrcReferenceNumber: string): boolean =>
    productList.metrcPackages?.find(p => p.label === metrcReferenceNumber) !== undefined;

  const validateProductForm = (deliveryIndex: number, productList: productListProp): boolean => {
    const errorList: Array<any> = [];

    if (productList.products.length > 0) {
      productList.products.forEach((product, index) => {
        const productErrors = new Map();
        // added empty obj to error[index] to display errors in exact row element in the ui (table)
        errorList[index] = {};

        if (!isMetrcApiDisabled && !metrcReferenceNumberExists(productList, product.metrcReferenceNumber || '')) {
          productErrors.set('metrcReferenceNumber', `No product found with reference ${product.metrcReferenceNumber}`);
        }

        if (isProductDuplicated(productList, product.metrcReferenceNumber || '')) {
          productErrors.set(
            'metrcReferenceNumber',
            `Duplicated reference ${product.metrcReferenceNumber} is not allowed`,
          );
        }

        if (isEmpty(product.metrcReferenceNumber)) {
          productErrors.set('metrcReferenceNumber', 'This field is required');
        }

        if (isEmpty(product.item)) {
          productErrors.set('item', 'This field is required');
        }

        if (isNaN(product.quantity) || Number(product.quantity) <= 0) {
          productErrors.set('quantity', 'This field requires a number grater than zero');
        }

        const errorListArray = Object.fromEntries(productErrors);
        if (!isEmpty(errorListArray)) {
          errorList[index] = errorListArray;
        }
      });
    }

    const hasRealErrors = errorList.find(errorObj => !isEmpty(errorObj)) !== undefined;

    return hasRealErrors;
  };

  const validateAttachmentForm = (
    attachments: AttachmentInfo,
    attachmentFileInfo?: SelectedFileInfo,
  ): Record<string, unknown> => {
    const attachmentErrorList = new Map();

    if (attachments?.isInvoiceRequired && !attachmentFileInfo?.invoiceFile) {
      attachmentErrorList.set('invoiceFile', 'An invoice file is required');
    }

    return Object.fromEntries(attachmentErrorList);
  };

  const validateDeliveryStep = (
    deliveryIndex: number,
    delivery: deliveryInfoProp,
    attachments: AttachmentInfo,
    attachmentFileInfo?: SelectedFileInfo,
  ): boolean => {
    const deliveryErrorList = validateDeliveryForm(delivery);
    const attachmentErrorList = validateAttachmentForm(attachments, attachmentFileInfo);

    updateDeliveryStepperState(deliveryIndex, deliveryErrorList, attachmentErrorList);

    return false;
  };

  const getFileData = (matchItem: FileInfo): FileInfo => ({
    fileName: matchItem.fileName,
    fileUrl: matchItem.fileUrl,
    filePath: matchItem.filePath,
  });

  const getUploadedAttachmentData = async (
    currentAttachment: AttachmentInfo,
    uploadedFileData: UploadedFileModel[],
  ): Promise<AttachmentInfo> => {
    let invoiceFileInfo: FileInfo | null = null;
    const otherFilesInfo: Array<FileInfo> = [];
    const currentInvoiceFile = currentAttachment?.invoiceFile;

    if (currentInvoiceFile) {
      const matchItem = uploadedFileData.find(item => item.fileName === currentInvoiceFile.fileName);
      if (matchItem) {
        invoiceFileInfo = getFileData(matchItem);
      }
    }

    const otherFiles = currentAttachment?.otherFiles;
    if (otherFiles) {
      Array.from(otherFiles).forEach(otherFile => {
        const matchItem = uploadedFileData.find(item => item.fileName === otherFile.fileName);
        if (matchItem) {
          const fileData = getFileData(matchItem);
          otherFilesInfo.push(fileData);
        }
      });
    }

    const newAttachmentData: AttachmentInfo = {
      ...currentAttachment,
      ...(invoiceFileInfo ? { invoiceFile: invoiceFileInfo } : {}),
      ...(otherFilesInfo && otherFilesInfo.length > 0 ? { otherFiles: otherFilesInfo } : {}),
    };
    return newAttachmentData;
  };

  const uploadFiles = async (attachments: AttachmentInfo, path: string): Promise<any> => {
    const fileListData: UploadedFileModel[] = [];
    const fileList = Array.from([...([attachments?.invoiceFile] || []), ...(attachments?.otherFiles || [])]);
    const newFiles = fileList.filter(file => !file?.fileUrl);

    for (const fileItem of newFiles) {
      const file = fileItem?.file;
      if (file) {
        await uploadFile(file, path).then((response: UploadedFileModel) => {
          fileListData.push(response);
        });
      }
    }
    return fileListData;
  };

  const removeFiles = async (attachments: AttachmentInfo): Promise<any> => {
    const fileList = Array.from([...([attachments?.invoiceFile] || []), ...(attachments?.otherFiles || [])]);
    const filesToDelete = fileList.filter(file => file?.shouldBeRemoved && file.filePath);

    for (const fileItem of filesToDelete) {
      const filePath = fileItem?.filePath;
      if (filePath) {
        await deleteFile(filePath).then();
      }
    }
  };

  const buildIndividualRequests = async (
    status: OrderRequestStatus,
    deliveryItem: OrderStepperDeliveryStateProps,
    parentId: string | null,
    orderId: string,
    orderReference: any,
    isNew: boolean,
  ): Promise<OrderRequest | null> => {
    const {
      requestedPickup,
      pickupAddress,
      pickupRecipient,
      billingAddressId,
      pickupAddressAsBilling,
      isFreshFrozenTransport,
    } = pickup;
    const { deliveryInfo, productList, attachments } = deliveryItem;
    const { requestedDelivery, deliveryAddress, deliveryRecipient, orderSize } = deliveryInfo;
    const isDraft = status === OrderRequestStatus.DRAFT;

    if (isDraft || (!isDraft && pickupRecipient && deliveryRecipient && pickupAddress && deliveryAddress)) {
      const dateField = isDraft ? 'draftedAt' : 'createdAt';
      const currentDate = moment().utc().unix();
      let attachmentData = attachments;
      const orderRefId = orderId ?? orderReference.id;
      if (orderRefId) {
        if (attachments?.invoiceFile ?? attachments?.otherFiles) {
          const path = `attachments/${orderRefId}`;
          await removeFiles(attachments);
          const result = await uploadFiles(attachments, path);
          attachmentData = await getUploadedAttachmentData(attachments, result);
        }
      }
      const tmpOrder: OrderRequest = {
        ...(order?.id ? { id: orderRefId } : {}),
        ...(order?.id ? { updatedAt: currentDate } : {}),
        [dateField]: currentDate,
        status,
        requestedPickup: moment(requestedPickup).unix() ?? 0,
        requestedDelivery: moment(requestedDelivery).unix() ?? 0,
        ...(currentUser?.uid ? { creator: currentUser.uid } : null),
        ...(contact && !currentUser?.uid ? { contactInfo: contact } : null),
        ...(pickupRecipient ? { senderInfo: pickupRecipient } : null),
        ...(deliveryRecipient ? { recipientInfo: deliveryRecipient } : null),
        ...(pickupAddress ? { originInfo: pickupAddress } : null),
        ...(deliveryAddress ? { destinationInfo: deliveryAddress } : null),
        billingAddressId: billingAddressId,
        pickupAddressAsBilling,
        isFreshFrozenTransport: isFreshFrozenTransport ?? false,
        createdAt: currentDate,
        ...(productList.products ? { items: productList.products } : {}),
        attachments: attachmentData,
        orderSize: orderSize,
        orderReferenceId: orderRefId,
        orderReference: orderReference,
        isNew: isNew,
        isDraftChild: !!parentId && status === OrderRequestStatus.DRAFT,
        parentId: parentId ?? null,
        type: 'package',
      };

      return tmpOrder;
    }

    return null;
  };

  const buildOrderRequest = async (status: OrderRequestStatus): Promise<Array<OrderRequest> | null> => {
    const orderRequests: Array<OrderRequest> = [];
    let parentId = null;
    for (let i = 0; i < deliveries.length; i++) {
      const isFirstDelivery = i === 0;
      const deliveryStepItem = deliveries[i];
      const orderReference = await getOrderReference();
      const orderRefId = isFirstDelivery ? id : order?.deliveries[i]?.orderRefId;
      const isNew = orderRefId === undefined;
      const orderId = orderRefId ?? orderReference.id;
      const orderRequestItem = await buildIndividualRequests(
        status,
        deliveryStepItem,
        parentId,
        orderId,
        orderReference,
        isNew,
      );
      if (orderRequestItem !== null) {
        orderRequests.push(orderRequestItem);
      }
      if (isFirstDelivery) {
        parentId = orderId;
      }
    }
    if (orderRequests.length > 0) return orderRequests;
    return null;
  };

  const onSubmitDraft = async (): Promise<void> => {
    setIsSaving(true);
    const ordersInfo = await buildOrderRequest(OrderRequestStatus.DRAFT);
    if (ordersInfo && currentUser) {
      const results = await saveOrderRequest(ordersInfo, currentUser, setSavingOrdersMsg);
      if (results) {
        success('Transport Requests saved as draft.');
        history.push(dashboard);
      }
    }
    setIsSaving(false);
  };

  const onSubmitOrder = async (): Promise<void> => {
    validatePickupStep();

    nextButtonRef.current?.click();

    deliveries.forEach((delivery, index) => {
      const { deliveryInfo, attachments } = delivery;
      validateDeliveryStep(index, deliveryInfo, attachments);
    });

    if (!pickupStep.isValid) {
      return;
    }

    if (!isAllDeliveriesValid) {
      error(`Check the following errors on delivery before continue`);
      return;
    }

    setIsSaving(true);
    const ordersInfo = await buildOrderRequest(OrderRequestStatus.CREATED);
    if (ordersInfo) {
      const results = await saveOrderRequest(ordersInfo, currentUser, setSavingOrdersMsg);
      success('Transport Requests created successfully.');
      if (currentUser) {
        history.push(dashboard);
      } else {
        setOrderId(results[0]?.orderRefId || null);
        setRequestStatus(RequestStatus.SUCCESS);
      }
    }
    setIsSaving(false);
  };

  const isRequestSuccess = (): boolean => requestStatus === RequestStatus.SUCCESS;

  const getSteps = (): Array<any> => [
    ...(isGuestUser
      ? [
          {
            name: 'Contact',
            icon: 'fa-user',
            isValid: contactStep?.isValid,
            component: <GuestContactForm isGuestView={isGuestUser} order={order} {...contactStep} />,
            validate: validateContactForm,
          },
        ]
      : []),
    {
      name: 'Pick-Up',
      icon: 'fa-shopping-cart',
      isValid: pickupStep?.isValid,
      component: (
        <PickupStep
          destinations={destinations}
          destinationsOptions={destinationsOptions}
          isGuestView={isGuestUser}
          recipients={recipients}
          order={order}
          {...pickupStep}
        />
      ),
      validate: validatePickupStep,
    },
    {
      name: 'Delivery',
      icon: 'fa-tasks',
      isValid: isAllDeliveriesValid,
      component: (
        <OrderContext.Provider
          value={{
            isGuestView,
            destinations,
            order,
            recipients,
            deliveriesStep,
            stepperState,
            deliveries,
            destinationsOptions,
            validateProductForm,
            validateDeliveryStep,
            metrcReferenceNumberExists,
            isProductDuplicated,
            validateStep,
            setStepperState,
            handleAddNewDelivery,
            handleDeleteDelivery,
          }}
        >
          <DeliveriesStep />
        </OrderContext.Provider>
      ),
    },
  ];

  const resizeSummary = (): void => {
    const MIN_HEIGHT = 615;
    const stepperContainerDiv = document.querySelector('.stepper-container');
    const summaryContainer = document.querySelector('.summary-container');
    if (stepperContainerDiv instanceof HTMLElement && summaryContainer instanceof HTMLElement) {
      let maxHeight = stepperContainerDiv.offsetHeight || 0;
      maxHeight = maxHeight > MIN_HEIGHT ? maxHeight : MIN_HEIGHT;
      summaryContainer.style.height = `${maxHeight}px`;
      summaryContainer.style.overflow = 'auto';
    }
  };
  resizeSummary();

  useEffect(() => {
    const stepperContainerDiv = document.querySelector('.stepper-container');

    const handleOnClick = (event: any): void => {
      const clickedElement = event.target.classList;
      const validElementClasses = ['stepper-button', 'collapse-header', 'add-delivery', 'remove-item-btn'];
      const isValidElement = validElementClasses.findIndex(validClass => clickedElement.contains(validClass)) > -1;
      if (isValidElement) {
        setTimeout(() => {
          resizeSummary();
        }, 250);
      }
    };

    if (stepperContainerDiv) {
      stepperContainerDiv.addEventListener('click', handleOnClick);
    }

    return (): void => {
      if (stepperContainerDiv) {
        stepperContainerDiv.removeEventListener('click', handleOnClick);
      }
    };
  });

  if (isLoading) {
    return <Spinner message="Loading..." />;
  }

  return (
    <>
      {isSaving && <Spinner message={savingOrdersMsg} overScren={true} />}
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <h1>{!id ? 'Transport Request' : 'Edit Transport Request'}</h1>
        </div>
        <div className="col-12">
          {alert && <Alert message={USER_HAS_NOT_RECIPIENT_ASSOCIATED} type={AlertType.primary} isShowing={!!alert} />}
        </div>
      </div>
      <div className="row">
        {isRequestSuccess() ? (
          <div className="col-12 mt-4">
            <StepperFeedback
              order={{ ...order, id: orderId }}
              title="Order Request created successfully"
              message="Thak you for submitting your request. Our team will review it and contact you if necessary."
            />
          </div>
        ) : (
          <>
            <div className="col-12 col-lg-9 mt-4">
              <Stepper steps={getSteps()} mainStepper ref={nextButtonRef} />
            </div>
            <div className="d-none d-lg-block col-lg-3 mt-4 bg-white rounded summary-container">
              <div className="row">
                <div className="col-12 pt-4">
                  <h2>Transport Request Summary</h2>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-12 pt-2">
                    <h3 className="mb-3">Pick-up</h3>
                  </div>
                  <div className="col-12">
                    <p className="text-uppercase font-light text-small mb-2">Bill To</p>
                    <p className="font-semibold mb-2">{pickup.pickupRecipient?.name || '-'}</p>
                  </div>
                  <div className="col-12">
                    <p className="text-uppercase font-light text-small mb-2">Address</p>
                    <p className="font-semibold mb-2">{pickup.pickupAddress?.name || '-'}</p>
                  </div>
                  <div className="col-12">
                    <p className="text-uppercase font-light text-small mb-2">Date of Pick-up</p>
                    <p className="font-semibold mb-2">{pickup.requestedPickup || '-'}</p>
                  </div>
                </div>
              </div>
              <div>
                {isAtLeastDeliveryFormValid && (
                  <>
                    <h3>Deliveries</h3>
                    {deliveries.map(({ deliveryInfo }, index) => (
                      <div className="row" key={index}>
                        <div className="col-12 pt-1">
                          <h4 className="mb-2">Delivery # {index + 1}</h4>
                        </div>
                        <div className="col-12">
                          <p className="text-uppercase font-light text-small mb-1">Deliver To</p>
                          <p className="font-semibold mb-2">{deliveryInfo.deliveryRecipient?.name || '-'}</p>
                        </div>
                        <div className="col-12">
                          <p className="text-uppercase font-light text-small mb-1">Address</p>
                          <p className="font-semibold mb-2">{deliveryInfo.deliveryAddress?.name || '-'}</p>
                        </div>
                        <div className="col-12">
                          <p className="text-uppercase font-light text-small mb-1">Date of Delivery</p>
                          <p className="font-semibold mb-2">{deliveryInfo.requestedDelivery || '-'}</p>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="row">
        <div className="col-12 col-lg-9">
          {!isRequestSuccess() && (
            <div className="col-xs-12 pull-right mt-3">
              <div className="float-right">
                <button
                  type="button"
                  className="btn btn-transparent text-uppercase mr-3"
                  onClick={(): void => history.push(dashboard)}
                >
                  Cancel
                </button>
                {!isGuestUser && (
                  <button onClick={onSubmitDraft} type="button" className="btn btn-secondary text-uppercase mr-3">
                    Save to Draft
                  </button>
                )}
                <button onClick={onSubmitOrder} type="button" className="btn btn-primary text-uppercase">
                  Submit
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ orderStepper }: StateProps): any => ({ ...orderStepper });
const mapDispatchToProps = { ...OrderStepperActions };
const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(OrderStepper);
export default withAlert(Connected);
