import React from 'react';
import classnames from 'classnames';

interface Props {
  index: number;
  title: string;
  shouldRoundBottom: boolean;
  invalid?: boolean;
  onRemoveItem?: (index: number) => void;
}

const CollapseHeader: React.FC<Props> = ({ index, shouldRoundBottom, title, invalid, onRemoveItem }: Props) => {
  return (
    <>
      <div
        className={classnames(
          'card__header d-flex',
          index === 0 ? 'rounded-top' : '',
          invalid ? 'border border-danger' : '',
          shouldRoundBottom ? 'rounded-bottom' : '',
        )}
      >
        <h4 className="card__header--title">
          <a
            href="/#"
            role="button"
            className="btn btn-link collapse-header"
            data-toggle="collapse"
            data-target={`#collapse${index}`}
            aria-expanded={index === 0 ? 'true' : 'false'}
            aria-controls={`#collapse${index}`}
          >
            {title}
          </a>
          {invalid && <i className="fas fa-exclamation-circle fa-lg text-danger"></i>}
        </h4>
        {onRemoveItem !== undefined && (
          <button
            key={index}
            className="btn btn-transparent no-hover remove-item-btn"
            onClick={(): void => onRemoveItem(index)}
          >
            <i className="fas fa-trash fa-lg remove-item-btn"></i>
          </button>
        )}
      </div>
    </>
  );
};
export default CollapseHeader;
