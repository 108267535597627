import React from 'react';
import style from '../../OrderDetail.module.scss';
import { OrderPickupInfoProps } from './types';

const OrderPickupInfo: React.FC<OrderPickupInfoProps> = ({ originInfo, senderInfo }: OrderPickupInfoProps) => {
  return (
    <>
      <div className="d-flex flex-column mt-3 pickup-from">
        <span className={style.label}>Pickup From</span>
        <span className={style.titleLarge} data-id="order-pickup-from">
          {senderInfo?.name || '-'}
        </span>
      </div>
      <div className="d-flex flex-column mt-3 pickup-phone">
        <span className={style.label}>Pickup Phone</span>
        <span data-id="order-pickup-phone">{senderInfo?.phone || '-'}</span>
      </div>
      <div className="d-flex flex-column mt-3 pickup-address">
        <span className={style.label}>Pickup Address</span>
        <span data-id="order-pickup-address">{originInfo?.name || '-'}</span>
      </div>
    </>
  );
};

export default OrderPickupInfo;
