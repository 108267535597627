import BaseModel from './BaseModel';
import { Driver, Recipient, Destination } from 'types';
import { AttachmentInfo } from './attachmentInfo';

export enum OrderStatus {
  DRAFT = 'DRAFT',
  PROCESSING = 'PROCESSING',
  SCHEDULED = 'SCHEDULED',
  CREATED = 'CREATED',
  ACCEPTED = 'ACCEPTED',
  ASSIGNED = 'ASSIGNED',
  PROGRESS = 'PROGRESS',
  ARRIVAL = 'ARRIVAL',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export type OrderStatusInfo = {
  status: OrderStatus;
};

export const TransactionType = {
  changeDrops: 'Change Drops',
  cashDeposit: 'Cash Deposit',
};

export const DepositTypes = {
  bank: 'Bank',
  vault: 'Vault',
  fedReserve: 'FED Reserve',
};

export const LocationTypes = {
  vault: 'Vault',
  fedReserve: 'FED Reserve',
  addressBook: 'Address Book',
};

export const TransportRequestTypes = {
  package: 'Package',
  cash: 'Cash',
};

export type CashTransactionType = keyof typeof TransactionType;
export type DepositType = keyof typeof DepositTypes;
export type LocationType = keyof typeof LocationTypes;
export type TransportRequestType = keyof typeof TransportRequestTypes;

export enum OrderDateFilterOptions {
  ALL = '',
  THIS_WEEK = 'week',
  TODAY = 'today',
}

export type OrderItem = {
  metrcReferenceNumber?: string;
  item: string;
  description: string;
  price?: number;
  quantity: number;
  amount?: number;
};

export const cashDenomination = [
  'HUNDRED',
  'FIFTIES',
  'TWENTIES',
  'TENS',
  'FIVES',
  'ONES',
  'QUARTERS',
  'DIMES',
  'NICKELS',
  'PENNIES',
] as const;

export type CashDenomination = typeof cashDenomination[number];

export const CashDenominationValues: Record<CashDenomination, number> = {
  HUNDRED: 100,
  FIFTIES: 50,
  TWENTIES: 20,
  TENS: 10,
  FIVES: 5,
  ONES: 1,
  QUARTERS: 0.25,
  DIMES: 0.1,
  NICKELS: 0.5,
  PENNIES: 0.1,
};

export type Denomination = {
  denomination: CashDenomination;
  quantity: number;
  amount: number;
};

export type CashChange = {
  denominations: Denomination[];
  total: number;
};

export type OrderCashTransportItem = {
  item: string;
  description: string;
  amount: number;
};

export type InvoiceItem = {
  name: string;
  transportationPrice: number;
};

export type EstimatedDelivery = {
  date: string;
  time: string;
};

export type OrderDeliveryItem = {
  orderRefId: string;
  estimatedDelivery: number;
  requestedDelivery?: number;
  destinationInfo?: Destination;
  recipientInfo?: Recipient;
  items: Array<OrderItem>;
  attachments: AttachmentInfo;
  orderSize?: string;
};

export interface Order extends BaseModel {
  terminalId: string;
  driverId: string;
  assistantId: string;
  estimatedPickup: number;
  requestedPickup?: number;
  notes: string;
  fuelSurcharge: number;
  status: OrderStatus;
  creator: string;
  createdAt: number;
  senderId: string;
  originId: string;
  recipientId: string;
  destinationId: string;
  vehicleId: string;
  invoiceItems: Array<InvoiceItem>;
  orderNumber: string;
  billingAddressId?: string;
  driverInfo?: Driver;
  senderInfo?: Recipient;
  billingAddressInfo?: Destination;
  originInfo?: Destination;
  taxPercentage?: number;
  reportUrl?: string;
  deliveries: Array<OrderDeliveryItem>;
  parentId?: string;
  destinationInfo?: Destination;
  recipientInfo?: Recipient;
  estimatedDelivery: number;
  requestedDelivery?: number;
  items?: Array<OrderItem>;
  attachments?: AttachmentInfo;
  isFreshFrozenTransport?: boolean;
  cashChange?: CashChange;
  type?: TransportRequestType;
  transactionType?: CashTransactionType;
  depositType?: DepositType;
  orderTotal?: number;
  orderRequestId?: string;
  orderId?: string;
}

export interface CustomerOrder
  extends Pick<
      Order,
      'status' | 'creator' | 'requestedPickup' | 'billingAddressId' | 'senderInfo' | 'originInfo' | 'reportUrl'
    >,
    Pick<OrderDeliveryItem, 'requestedDelivery' | 'recipientInfo' | 'destinationInfo'> {
  id?: string;
  createdAt?: number;
  draftedAt?: number;
  updatedAt?: number;
  pickupAddressAsBilling?: boolean;
  taxPercentage?: number;
  senderId?: string;
  originId?: string;
  recipientId?: string;
  destinationId?: string;
  products?: Array<OrderItem>;
  source?: string;
  senderLicenseId?: string;
  recipientLicenseId?: string;
  processed?: boolean;
  attachments: AttachmentInfo;
}

export interface OrderRow extends Pick<Order, 'id' | 'orderNumber' | 'status' | 'createdAt'> {
  driver?: string;
  origin?: string;
  recipient?: string;
  destination?: string;
  orderType?: string;
}

export type AdditionalProps = {
  driverId: string;
  recipientId: string;
  destinationId: string;
  estimatedPickup: EstimatedDelivery;
  estimatedDelivery: EstimatedDelivery;
  orderNumber: string;
  orderId: string;
  orderStatus: string;
};
