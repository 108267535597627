import React from 'react';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
};

const KeepMeSignedInField: React.FC<Props> = ({ onChange, checked }: Props) => {
  return (
    <div className="form-group form-check mb-2">
      <input
        id="login-keep-signed-in"
        type="checkbox"
        className="form-check-input"
        onChange={onChange}
        checked={checked}
      />
      <label className="form-check-label" htmlFor="login-keep-signed-in">
        Remember Me
      </label>
    </div>
  );
};

export default KeepMeSignedInField;
