import React, { Fragment } from 'react';
import classnames from 'classnames';

import AppHeader from './components/AppHeader';
import AppFooter from './components/AppFooter';
import { SITE } from '../../constants/footerTypes';
import * as routes from 'constants/routes';

import style from './AppLayout.module.scss';

export type GuestLayoutProps = {
  component: React.FC;
};

const links = [
  {
    label: 'Sign In',
    route: routes.login,
  },
];

const Guestlayout: React.FC<GuestLayoutProps> = ({ component: Component }: GuestLayoutProps) => {
  return (
    <Fragment>
      <AppHeader links={links} />
      <div className="align-items-center">
        <div className={classnames('container', style.layoutContainer)}>
          <Component />
        </div>
      </div>
      <AppFooter type={SITE}> </AppFooter>
    </Fragment>
  );
};

export default Guestlayout;
