import {
  ActionTypes,
  SET_BILLING_ADDRESS,
  SET_PICKUP_ADDRESS,
  SET_PICKUP_RECIPIENT,
  SET_PICKUP_DATE,
  SET_NEW_PICKUP_RECIPIENT,
  SET_PICKUP_ADDRESS_AS_BILLING,
  SET_PICKUP_IS_FRESH_FROZEN_TRANSPORT,
  SET_NEW_DELIVERY_RECIPIENT,
  SET_DELIVERY_ADDRESS,
  SET_DELIVERY_RECIPIENT,
  SET_DELIVERY_DATE,
  SET_DELIVERY_ORDER_SIZE,
  SET_PRODUCTS,
  SET_NEW_PRODUCT,
  SET_REMOVE_PRODUCT,
  SET_PRODUCT_METRC_LICENSE,
  SET_PRODUCT_DESCRIPTION,
  SET_PRODUCT_NAME,
  SET_PRODUCT_QUANTITY,
  SET_FOUND_PRODUCT,
  SET_EMPTY_PRODUCT_WITH_TAG,
  SET_GUEST_NAME,
  SET_GUEST_EMAIL,
  SET_GUEST_PHONE,
  CLEAN_ORDER,
  SET_ATTACHMENT,
  SET_INVOICE_AS_REQUIRED,
  SET_NOTE,
  SET_NEW_DELIVERY_INFO,
  SET_DELIVERIES_DATA,
  SET_REMOVE_DELIVERY_INFO,
  SET_TRANSACTION_TYPE,
  SET_DEPOSIT_TYPE,
  SET_AMOUNT,
  RESET_RECIPIENT,
  SET_FINANCIAL_INSTITUTION,
  SET_NEW_CASH_DENOMINATION,
  SET_REMOVE_CASH_DENOMINATION,
  SET_CASH_DENOMINATION_TYPE,
  SET_CASH_DENOMINATION_QUANTITY,
  SET_CASH_DENOMINATION_AMOUNT,
  SET_LOCATION_SOURCE,
  SET_LOCATION_TARGET,
} from './types/orderStepper';
import { Recipient, Destination, OrderItem, CashDenomination, LocationType } from 'types';
import { MetrcPackage } from 'types/metrcPackage';
import { AttachmentInfo } from 'types/attachmentInfo';
import { OrderStepperDeliveryStateProps } from 'reducers/orderStepper';
import { OptionType } from 'components/Select';

export const onCleanOrder = (): ActionTypes => ({
  type: CLEAN_ORDER,
});

export const onBillingAddressChanged = (billingAddressId: string): ActionTypes => ({
  type: SET_BILLING_ADDRESS,
  payload: { billingAddressId },
});

export const onPickupAddressChanged = (pickupAddress: Destination): ActionTypes => ({
  type: SET_PICKUP_ADDRESS,
  payload: { pickupAddress },
});

export const onTransactionTypeChanged = (transactionType: OptionType): ActionTypes => ({
  type: SET_TRANSACTION_TYPE,
  payload: { transactionType },
});

export const onDepositTypeChanged = (depositType: OptionType): ActionTypes => ({
  type: SET_DEPOSIT_TYPE,
  payload: { depositType },
});

export const onFinancialInstitutionChanged = (financialInstitutionId: string): ActionTypes => ({
  type: SET_FINANCIAL_INSTITUTION,
  payload: { financialInstitutionId },
});

export const onLocationSourceChanged = (locationSource: LocationType): ActionTypes => ({
  type: SET_LOCATION_SOURCE,
  payload: { locationSource },
});

export const onLocationTargetChanged = (locationTarget: LocationType): ActionTypes => ({
  type: SET_LOCATION_TARGET,
  payload: { locationTarget },
});

export const onAmountChanged = (amount: number): ActionTypes => ({
  type: SET_AMOUNT,
  payload: { amount },
});

export const onPickupRecipientChanged = (pickupRecipient: Recipient): ActionTypes => ({
  type: SET_PICKUP_RECIPIENT,
  payload: { pickupRecipient },
});

export const onPickupDateChanged = (requestedPickup: string): ActionTypes => ({
  type: SET_PICKUP_DATE,
  payload: { requestedPickup },
});

export const onPickupAddressAsBillingChanged = (pickupAddressAsBilling: boolean): ActionTypes => ({
  type: SET_PICKUP_ADDRESS_AS_BILLING,
  payload: { pickupAddressAsBilling },
});

export const onIsFreshFrozenTransport = (isFreshFrozenTransport: boolean): ActionTypes => ({
  type: SET_PICKUP_IS_FRESH_FROZEN_TRANSPORT,
  payload: { isFreshFrozenTransport },
});

export const onNewPickupRecipient = (): ActionTypes => ({
  type: SET_NEW_PICKUP_RECIPIENT,
});

export const onDeliveryAddressChanged = (index: number, deliveryAddress: Destination): ActionTypes => ({
  type: SET_DELIVERY_ADDRESS,
  payload: { index, deliveryAddress },
});

export const onDeliveryRecipientChanged = (index: number, deliveryRecipient: Recipient): ActionTypes => ({
  type: SET_DELIVERY_RECIPIENT,
  payload: { index, deliveryRecipient },
});

export const onDeliveryDateChanged = (index: number, requestedDelivery: string): ActionTypes => ({
  type: SET_DELIVERY_DATE,
  payload: { index, requestedDelivery },
});

export const onDeliveryOrderSizeChanged = (index: number, orderSize: string): ActionTypes => ({
  type: SET_DELIVERY_ORDER_SIZE,
  payload: { index, orderSize },
});

export const onNewDeliveryRecipient = (index: number): ActionTypes => ({
  type: SET_NEW_DELIVERY_RECIPIENT,
  payload: { index },
});

export const onSetProducts = (deliveryIndex: number, products: Array<OrderItem>): ActionTypes => ({
  type: SET_PRODUCTS,
  payload: { deliveryIndex, products },
});

export const onNewProduct = (deliveryIndex: number): ActionTypes => ({
  type: SET_NEW_PRODUCT,
  payload: { deliveryIndex },
});

export const onRemoveProduct = (deliveryIndex: number, productIndex: number): ActionTypes => ({
  type: SET_REMOVE_PRODUCT,
  payload: { deliveryIndex, productIndex },
});

export const onProductMetrcLicenseChanged = (
  deliveryIndex: number,
  productIndex: number,
  metrcReferenceNumber: string,
): ActionTypes => ({
  type: SET_PRODUCT_METRC_LICENSE,
  payload: { deliveryIndex, productIndex, metrcReferenceNumber },
});

export const onProductDescriptionChanged = (
  deliveryIndex: number,
  productIndex: number,
  description: string,
): ActionTypes => ({
  type: SET_PRODUCT_DESCRIPTION,
  payload: { deliveryIndex, productIndex, description },
});

export const onProductNameChanged = (deliveryIndex: number, productIndex: number, item: string): ActionTypes => ({
  type: SET_PRODUCT_NAME,
  payload: { deliveryIndex, productIndex, item },
});

export const onProductQuantityChanged = (
  deliveryIndex: number,
  productIndex: number,
  quantity: number,
): ActionTypes => ({
  type: SET_PRODUCT_QUANTITY,
  payload: { deliveryIndex, productIndex, quantity },
});

export const onSearchedProductFound = (
  deliveryIndex: number,
  productIndex: number,
  foundProduct: OrderItem,
  metrcPackage: MetrcPackage,
): ActionTypes => {
  return {
    type: SET_FOUND_PRODUCT,
    payload: {
      deliveryIndex,
      productIndex,
      product: foundProduct,
      metrcPackage: metrcPackage,
    },
  };
};

export const onSetEmptyProducWithTag = (deliveryIndex: number, productIndex: number, tag: string): ActionTypes => {
  return {
    type: SET_EMPTY_PRODUCT_WITH_TAG,
    payload: {
      deliveryIndex,
      productIndex,
      tag,
    },
  };
};

export const onGuestNameChanged = (name: string): ActionTypes => ({
  type: SET_GUEST_NAME,
  payload: { name },
});

export const onGuestEmailChanged = (email: string): ActionTypes => ({
  type: SET_GUEST_EMAIL,
  payload: { email },
});

export const onGuestPhoneChanged = (phone: string): ActionTypes => ({
  type: SET_GUEST_PHONE,
  payload: { phone },
});

export const onIsInvoiceRequiredChanged = (deliveryIndex: number, isInvoiceRequired: boolean): ActionTypes => ({
  type: SET_INVOICE_AS_REQUIRED,
  payload: { deliveryIndex, isInvoiceRequired },
});

export const onNoteChanged = (deliveryIndex: number, note: string): ActionTypes => ({
  type: SET_NOTE,
  payload: { deliveryIndex, note },
});

export const onSetAttachment = (deliveryIndex: number, attachments: AttachmentInfo): ActionTypes => ({
  type: SET_ATTACHMENT,
  payload: { deliveryIndex, attachments },
});

export const onNewDeliveryInfo = (): ActionTypes => ({
  type: SET_NEW_DELIVERY_INFO,
});

export const onSetDeliveries = (deliveries: Array<OrderStepperDeliveryStateProps>): ActionTypes => ({
  type: SET_DELIVERIES_DATA,
  payload: { deliveries },
});

export const onRemoveDeliveryInfo = (deliveryIndex: number): ActionTypes => ({
  type: SET_REMOVE_DELIVERY_INFO,
  payload: { deliveryIndex },
});

export const onResetRecipient = (type: 'pickup' | 'delivery', index = 0): ActionTypes => ({
  type: RESET_RECIPIENT,
  payload: { type, index },
});

export const onNewCashDenomination = (): ActionTypes => ({
  type: SET_NEW_CASH_DENOMINATION,
});

export const onRemoveCashDenomination = (index: number): ActionTypes => ({
  type: SET_REMOVE_CASH_DENOMINATION,
  payload: { index },
});

export const onCashDenominationTypeChanged = (index: number, denomination: CashDenomination): ActionTypes => ({
  type: SET_CASH_DENOMINATION_TYPE,
  payload: { index, denomination },
});

export const onCashDenominationQuantityChanged = (index: number, quantity: number): ActionTypes => ({
  type: SET_CASH_DENOMINATION_QUANTITY,
  payload: { index, quantity },
});

export const onCashDenominationAmountChanged = (index: number, amount: number): ActionTypes => ({
  type: SET_CASH_DENOMINATION_AMOUNT,
  payload: { index, amount },
});
