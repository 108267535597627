import { firestore } from '_firebase';
import { Destination, DestinationStatus } from 'types/destination';
import * as collections from 'constants/collections';

export const getDestinations = async (): Promise<Array<Destination>> => {
  const destinations: Array<Destination> = [];

  // prettier-ignore
  const destinationsRef = await firestore
    .collection(collections.DESTINATIONS)
    .get();

  if (!destinationsRef.empty) {
    destinationsRef.forEach(doc => {
      const destination = doc.data();

      destinations.push({
        id: doc.id,
        name: destination.name,
        active: destination.active,
        address: destination.address,
        location: destination.location,
        owner: destination.owner,
        photoUrl: destination.photoUrl,
      });
    });
  }

  return destinations;
};

export const getRecipientDestinations = async (recipientId: string): Promise<Array<Destination>> => {
  const destinations: Array<Destination> = [];

  // prettier-ignore
  const destinationsRef = await firestore
    .collection(collections.DESTINATIONS)
    .where('owner', '==', recipientId)
    .get();

  if (!destinationsRef.empty) {
    destinationsRef.forEach(doc => {
      const destination = doc.data();

      destinations.push({
        id: doc.id,
        name: destination.name,
        active: destination.active,
        address: destination.address,
        location: destination.location,
        owner: destination.owner,
        photoUrl: destination.photoUrl || '',
      });
    });
  }

  return destinations;
};

export const getDestination = async (destinationId: string): Promise<Destination | undefined> => {
  const doc = await firestore.collection(collections.DESTINATIONS).doc(destinationId).get();
  const destination = doc.exists && doc.data();

  if (destination) {
    return {
      id: doc.id,
      name: destination.name,
      active: destination.active,
      address: destination.address,
      location: destination.location,
      owner: destination.owner,
      photoUrl: destination.photoUrl,
    };
  }

  return undefined;
};

export const getFinancialInstitutionDestination = async (
  financialInstitutionId: string,
): Promise<Destination | undefined> => {
  const destinationRef = await firestore
    .collection(collections.DESTINATIONS)
    .where('owner', '==', financialInstitutionId)
    .where('active', '==', true)
    .get();

  if (!destinationRef.empty) {
    const [doc] = destinationRef.docs;

    const data = doc.data();

    return {
      id: doc.id,
      name: data.name,
      active: data.active,
      address: data.address,
      location: data.location,
      owner: data.owner,
    };
  }

  return undefined;
};

// prettier-ignore
export const createDestination = async (data: Destination): Promise<any> => {
  const destinationRef = await firestore.collection(collections.DESTINATIONS).doc(data.extra?.googlePlaceId).get();

  if (destinationRef.exists) {
    return {
      id: destinationRef.id,
      ...destinationRef.data(),
    };
  } else {
    const docRef = firestore.collection(collections.DESTINATIONS).doc(data.extra?.googlePlaceId);
    await docRef.set(data);
    return docRef.get();
  }
};

export const updateDestination = async (destinationId: string, data: Destination): Promise<any> => {
  await firestore.collection(collections.DESTINATIONS).doc(destinationId).update(data);

  return getDestinationById(destinationId);
};

export const updateDestinationStatus = async (destinationId: string, data: DestinationStatus): Promise<any> => {
  return firestore.collection(collections.DESTINATIONS).doc(destinationId).update(data);
};

export const getDestinationById = async (destinationId: string): Promise<Destination | undefined> => {
  const destinationRef = await firestore.collection(collections.DESTINATIONS).doc(destinationId).get();

  if (!destinationRef.exists) {
    return undefined;
  }

  const destinationData = destinationRef.data() || {};

  const destination: Destination = {
    id: destinationRef.id,
    name: destinationData.name,
    active: destinationData.active,
    address: destinationData.address,
    location: destinationData.location,
    owner: destinationData.owner,
    photoUrl: destinationData.photoUrl,
    receivingHours: destinationData.receivingHours,
  };

  return destination;
};
