import React, { useCallback, useEffect, useContext } from 'react';
import moment from 'moment';
import classnames from 'classnames';
import styles from 'components/Layout/AuthLayout.module.scss';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';

import { AuthContext } from 'contexts';
import { signUp } from 'services/auth';

import { SignUpData } from 'types/auth';
import Alert from 'types/alert';

import { withNoAuthorization, withAlert, withPageView } from 'components';
import { SignUpForm } from './components';

import * as routes from 'constants/routes';
import { SIGN_UP_FORM_TITLE } from 'constants/strings';

interface Props extends Alert, RouteComponentProps {}

const SignUpPage: React.FC<Props> = (props: Props) => {
  const { history } = props;
  const auth = useContext(AuthContext);

  const onSubmit = async (signUpData: SignUpData): Promise<any> => {
    const currentDate = moment().utc().unix();
    const accountRequestData: SignUpData = {
      ...signUpData,
      displayName: `${signUpData.firstName} ${signUpData.lastName}`,
      createdAt: currentDate,
      updatedAt: currentDate,
    };
    return await signUp(accountRequestData);
  };

  const redirectTo = useCallback(
    (path: string) => {
      history.push(path);
    },
    [history],
  );

  useEffect(() => {
    if (auth?.currentUser) {
      redirectTo(routes.dashboard);
    }
  }, [auth, history, redirectTo]);

  return (
    <div className={classnames('d-flex align-items-center sign-up-page', styles.sign_up_page)}>
      <div className={styles.sign_up_container}>
        <div className="text-center">
          <p className={styles.header_subtitle}>{SIGN_UP_FORM_TITLE}</p>
        </div>
        <div className={styles.content}>
          <SignUpForm onSubmit={onSubmit} success={props.success} error={props.error} redirectTo={redirectTo} />
        </div>
      </div>
    </div>
  );
};

const Enhanced = compose(withNoAuthorization, withAlert, withPageView)(SignUpPage, 'Login');
export default withRouter(Enhanced as React.ComponentType<any>);
