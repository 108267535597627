import React from 'react';
import { withRouter, RouteComponentProps, Redirect } from 'react-router-dom';
import { compose } from 'redux';
import cls from 'classnames';
import * as routes from 'constants/routes';

import { withAuthorization, withPageView } from 'components';
import { SimpleCard } from 'components/Cards';

import style from './CashBalance.module.scss';
import { currencyFormat, formatShortDate } from 'utils';
import { useGetTransactions } from 'services/hooks/cashTransactions';
import moment from 'moment';
import { useAuth } from 'contexts/auth';
import CustomDataTable, { CustomCell } from 'components/CustomDataTable';
import { CashTransaction } from 'types/cashTransaction';
import { Spinner } from 'components/Loaders';

const getTransactionsTotal = (dayTransactions): number => {
  return dayTransactions.reduce((total, item) => {
    total += item.amount;
    return total;
  }, 0);
};

const today = moment().startOf('day');

const transactionTypeLabels = {
  cashDeposit: 'Cash Deposit',
  changeDrops: 'Change Drops',
};

const transactionTargetLabels = {
  vault: 'Vault',
  fedReserve: 'Fed Reserve',
  addressBook: 'Address',
};

const conditionalCellStyles = [
  {
    when: (row: CashTransaction): boolean => row.target !== 'vault',
    style: { backgroundColor: '#fecaca', borderBottom: '1px solid #CACACA' },
  },
  {
    when: (row: CashTransaction): boolean => row.target === 'vault',
    style: { backgroundColor: '#fff', borderBottom: '1px solid #CACACA' },
  },
];

const columns = [
  {
    name: 'Date',
    selector: 'createdAt',
    maxWidth: '15%',
    sortable: true,
    cell: (row: CashTransaction): any => {
      const { createdAt } = row;
      return <CustomCell title={formatShortDate(createdAt)} content={formatShortDate(createdAt)} />;
    },
    conditionalCellStyles,
  },
  {
    name: 'Type',
    selector: 'transactionType',
    maxWidth: '15%',
    sortable: true,
    cell: (row: CashTransaction): any => <CustomCell content={transactionTypeLabels[row.transactionType]} />,
    conditionalCellStyles,
  },
  {
    name: 'From',
    cell: (row: CashTransaction): any => (
      <CustomCell
        content={row.transactionType === 'changeDrops' ? transactionTargetLabels[row.source] : row.recipient?.name}
      />
    ),
    conditionalCellStyles,
  },
  {
    name: 'To',
    cell: (row: CashTransaction): any => (
      <CustomCell
        content={row.transactionType === 'changeDrops' ? row.recipient?.name : transactionTargetLabels[row.target]}
      />
    ),
    conditionalCellStyles,
  },
  {
    name: 'Amount',
    cell: (row: CashTransaction): any => (
      <CustomCell
        content={
          <span className={row.target === 'vault' ? style.green : style.red}>
            {row.target === 'vault' ? (
              <i className={cls('fas fa-plus', style.icon)}></i>
            ) : (
              <i className={cls('fas fa-minus', style.icon)}></i>
            )}
            {currencyFormat(row.amount)}
          </span>
        }
      />
    ),
    conditionalCellStyles,
  },
];

const CashBalanceReport: React.FC<RouteComponentProps> = () => {
  const { data: transactions, isLoading } = useGetTransactions();
  const { currentUser } = useAuth();

  const beginningBalance = transactions.reduce((total, item) => {
    if (item.createdAt < today.unix()) {
      if (['CASH IN', 'BULK IN'].includes(item.branch)) {
        total += item.amount;
      } else if (['CASH OUT', 'BULK OUT'].includes(item.branch)) {
        total -= item.amount;
      }
    }

    return total;
  }, 0);

  const dayTransactions = transactions.filter(t => t.createdAt > today.unix()) || [];

  const daylyCashInTransactions = dayTransactions.filter(t => t.branch === 'CASH IN') || [];
  const daylyCashOutTransactions = dayTransactions.filter(t => t.branch === 'CASH OUT') || [];
  const daylyBulkInTransactions = dayTransactions.filter(t => t.branch === 'BULK IN') || [];
  const daylyBulkOutTransactions = dayTransactions.filter(t => t.branch === 'BULK OUT') || [];

  const cashInTransactionsTotal = getTransactionsTotal(daylyCashInTransactions);
  const cashOutTransactionsTotal = getTransactionsTotal(daylyCashOutTransactions);
  const bulkInTransactionsTotal = getTransactionsTotal(daylyBulkInTransactions);
  const bulkOutTransactionsTotal = getTransactionsTotal(daylyBulkOutTransactions);

  const cashInTransactions = transactions.filter(t => t.branch === 'CASH IN') || [];
  const cashOutTransactions = transactions.filter(t => t.branch === 'CASH OUT') || [];
  const bulkInTransactions = transactions.filter(t => t.branch === 'BULK IN') || [];
  const bulkOutTransactions = transactions.filter(t => t.branch === 'BULK OUT') || [];

  if (!currentUser?.recipientInfo?.isFinancialInstitution) {
    return <Redirect to={routes.notFound} />;
  }

  return (
    <>
      <div id="orderDetailsWrapper" className="row mb-4">
        <div className="col-12">
          <h1>Cash Report</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className={style.viewOrderContainer}>
            <div className={cls(style.summaryContainer, 'bg-white p-4 rounded')}>
              <SimpleCard title="Cash Report">
                <>
                  <div className="row border-bottom border-gray">
                    <div className="col">
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Beginning Balance (as of {formatShortDate(today.unix())})</span>
                        <span className={cls('font-light', style.green)}>{currencyFormat(beginningBalance)}</span>
                      </div>
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Cash In</span>
                        <span className={cls('font-light', style.green)}>
                          <i className={cls('fas fa-plus', style.icon)}></i>
                          {currencyFormat(cashInTransactionsTotal)}
                        </span>
                      </div>
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Cash Out</span>
                        <span className={cls('font-light', style.red)}>
                          <i className={cls('fas fa-minus', style.icon)}></i>
                          {currencyFormat(cashOutTransactionsTotal)}
                        </span>
                      </div>
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Bulk In</span>
                        <span className={cls('font-light', style.green)}>
                          <i className={cls('fas fa-plus', style.icon)}></i>
                          {currencyFormat(bulkInTransactionsTotal)}
                        </span>
                      </div>
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Bulk Out</span>
                        <span className={cls('font-light', style.red)}>
                          <i className={cls('fas fa-minus', style.icon)}></i>
                          {currencyFormat(bulkOutTransactionsTotal)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Total</span>
                        <span className={cls('font-light h2', style.green)}>
                          {currencyFormat(
                            beginningBalance +
                              cashInTransactionsTotal +
                              bulkInTransactionsTotal -
                              (cashOutTransactionsTotal + bulkOutTransactionsTotal),
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              </SimpleCard>
            </div>
            <div className={cls(style.summaryContainer, 'bg-white p-4 rounded')}>
              <SimpleCard title="Cash Transactions">
                <div>
                  <div className="row border-bottom border-gray">
                    <div className="col">
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Cash In Total</span>
                        <span className={cls('font-light')}>{cashInTransactions.length}</span>
                      </div>
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Cash Out Total</span>
                        <span className={cls('font-light')}>{cashOutTransactions.length}</span>
                      </div>
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Bulk In Total</span>
                        <span className={cls('font-light')}>{bulkInTransactions.length}</span>
                      </div>
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Bulk Out Total</span>
                        <span className={cls('font-light')}>{bulkOutTransactions.length}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="d-flex flex-row mt-3 justify-content-between align-items-center">
                        <span className="label">Total</span>
                        <span className={cls('font-light h2')}>{transactions.length}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </SimpleCard>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <SimpleCard title="Transactions List" className="bg-white mt-3 p-4 rounded">
            {isLoading ? <Spinner /> : <CustomDataTable columns={columns} data={transactions} pointerOnHover />}
          </SimpleCard>
        </div>
      </div>
    </>
  );
};

const Enhanced = compose(withAuthorization, withRouter, withPageView)(CashBalanceReport, 'Cash Report');
export default withRouter(Enhanced as React.ComponentType<any>);
