import React from 'react';
import cls from 'classnames';

import style from './NotFound.module.scss';

export interface NotFoundProps {
  className?: string;
  notFoundUrl?: string;
  redirectUrl?: string;
}

const NotFound: React.FC<NotFoundProps> = ({ className, notFoundUrl, redirectUrl }: NotFoundProps) => {
  const errorMsg = notFoundUrl ? "We couldn't find this page:" : "We couldn't find the requested page.";
  return (
    <>
      <div className={cls('row pt-5', className)}>
        <div className="col-md-8 offset-md-2 bg-white p-5 text-center">
          <div className="row">
            <div className="col-12">
              <h1 className={cls(style.heading, 'mt-2')}>Error 404</h1>
            </div>
            <div className="col-12">
              <h3 className="h4">{errorMsg}</h3>
            </div>
            {notFoundUrl && (
              <div className="col-12">
                <p className="tagline text-default text-wrap">{notFoundUrl}</p>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col text-center">
              <a href={redirectUrl} target="_self" className="btn btn-primary text-uppercase mt-4">
                <i className="fa fa-arrow-left fa-lg pr-3" aria-hidden="true"></i>
                Go Home
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFound;
