/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { StateProps } from 'reducers';
import { OrderStepperStateProps } from 'reducers/orderStepper';
import * as OrderStepperActions from 'actions/orderStepper';
import { OrderStepperActionsProps } from 'actions/types/orderStepper';
import { Destination, LocationTypes, Order, Recipient, TransactionType } from 'types';
import { DraggableMarkerMap } from 'components/Map';
import { TextField, DestinationSelectField } from 'components/FormFields';
import { OptionType } from 'components/Select';
import { SelectField } from 'components/FormFields';
import { MapMarker } from 'components/Map/DraggableMarkerMap';
import { isEmpty } from 'lodash';
import { AuthContext } from 'contexts';
import { CASH_CHANGE_DROPS, CASH_DEPOSIT } from 'constants/transactions';

interface Props extends OrderStepperStateProps, OrderStepperActionsProps {
  order?: Order;
  recipients: Array<Recipient>;
  financialInstitutions: Array<Recipient>;
  financialDestinations: Array<Destination>;
  destinations: Array<Destination>;
  destinationsOptions: (financialInstitution: boolean, showNew?: boolean) => Array<OptionType>;
  isValid: boolean;
  errors: any;
}

const CashTransportRequestForm: React.FC<Props> = ({
  // State
  order,
  transactionType,
  depositType,
  amount,
  pickup,
  deliveries,
  financialInstitutionId,
  locationSource,
  locationTarget,

  // Props
  recipients,
  financialInstitutions,
  destinations,
  financialDestinations,
  destinationsOptions,
  isValid,
  errors,
  // Actions
  onTransactionTypeChanged,
  onAmountChanged,
  onLocationSourceChanged,
  onLocationTargetChanged,
  onFinancialInstitutionChanged,
  onPickupAddressChanged,
  onPickupRecipientChanged,
  onDeliveryRecipientChanged,
  onPickupDateChanged,
  onBillingAddressChanged,
  onPickupAddressAsBillingChanged,
  onNewPickupRecipient,
  onNewDeliveryRecipient,
  onDeliveryDateChanged,
  onDeliveryAddressChanged,
  onResetRecipient,
}: Props) => {
  const { currentUser } = useContext(AuthContext);
  const deliveryIndex = 0;
  const { pickupAddress, requestedPickup, pickupRecipient, isNewPickupRecipient } = pickup;
  const delivery = deliveries[deliveryIndex].deliveryInfo;
  const { deliveryAddress, requestedDelivery, deliveryRecipient, isNewDeliveryRecipient } = delivery;
  const financialInstitution = depositType && ['bank', 'fedReserve'].includes(depositType);
  const isUsingAddressBook = [locationSource, locationTarget].includes('addressBook');
  const isPickup = transactionType === CASH_DEPOSIT && isUsingAddressBook;
  const isDelivery = transactionType === CASH_CHANGE_DROPS && isUsingAddressBook;

  useEffect(() => {
    const originInfo = order?.originInfo || pickup?.pickupAddress;
    if (originInfo) {
      onPickupAddressChanged(originInfo);
    }

    const senderInfo = order?.senderInfo || pickup.pickupRecipient;
    if (senderInfo) {
      onPickupRecipientChanged(senderInfo);
    }

    if (order?.requestedPickup) {
      const formattedDate = moment.unix(order.requestedPickup).format('YYYY-MM-DDThh:mm');
      onPickupDateChanged(formattedDate);
    }

    if (order?.billingAddressId) {
      onBillingAddressChanged(order?.billingAddressId);
    }

    onPickupAddressAsBillingChanged(order?.originId === order?.billingAddressId);
  }, [order]);

  const onTransactionTypeChange = (option: OptionType): void => {
    onResetRecipient('pickup');
    onResetRecipient('delivery');

    option && onTransactionTypeChanged(option);

    if (!currentUser?.recipientInfo?.isFinancialInstitution) {
      if (option.value === CASH_DEPOSIT) {
        onLocationSourceChanged('addressBook');
        onLocationTargetChanged('vault');
      } else {
        onLocationSourceChanged('vault');
        onLocationTargetChanged('addressBook');
      }
    } else {
      if (option.value === CASH_DEPOSIT) {
        onLocationSourceChanged('vault');
        onLocationTargetChanged('fedReserve');
      } else {
        onLocationSourceChanged('fedReserve');
        onLocationTargetChanged('vault');
      }
    }

    if (currentUser?.financialInstitution?.id) {
      onFinancialInstitutionChanged(currentUser?.financialInstitution?.id);
    }
  };

  const onAmountChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (e.target.value && !isNaN(parseFloat(e.target.value))) {
      onAmountChanged(parseFloat(e.target.value));
    }
  };

  const onFinancialInstitutionChange = (option: OptionType): void => {
    option && onFinancialInstitutionChanged(option.value);
  };

  const onPickupAddressChange = (option: OptionType): void => {
    if (option.value === 'new') {
      onNewPickupRecipient();
      return;
    }

    const destinationList = financialInstitution ? financialDestinations : destinations;
    const recipientList = financialInstitution ? financialInstitutions : recipients;

    const [recipientId, destinationId] = (option.value || '').split(',');

    let selectedDestination = destinationList.find((d: Destination) => d.id === destinationId);
    let selectedRecipient = recipientList.find((r: Recipient) => r.id === recipientId);

    if (!selectedDestination) {
      selectedDestination = pickupRecipient?.destinations?.find((d: Destination) => d.id === destinationId);
    }

    if (!selectedRecipient) {
      selectedRecipient = pickupRecipient;
    }

    selectedDestination && onPickupAddressChanged(selectedDestination);
    selectedRecipient && onPickupRecipientChanged(selectedRecipient);
  };

  const onPickupDestinationChange = (destination: Destination): void => {
    destination && onPickupAddressChanged(destination);
  };

  const onPickupRecipientFieldChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (pickup?.pickupRecipient) {
      onPickupRecipientChanged({
        ...pickup?.pickupRecipient,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onDeliveryAddressChange = (option: OptionType): void => {
    if (option.value === 'new') {
      onNewDeliveryRecipient(deliveryIndex);
      return;
    }

    const destinationList = financialInstitution ? financialDestinations : destinations;
    const recipientList = financialInstitution ? financialInstitutions : recipients;

    const [recipientId, destinationId] = (option.value || '').split(',');
    let selectedDestination = destinationList.find((d: Destination) => d.id === destinationId.trim());
    let selectedRecipient = recipientList.find((r: Recipient) => r.id === recipientId);

    if (!selectedDestination) {
      selectedDestination = deliveryRecipient?.destinations?.find((d: Destination) => d.id === destinationId);
    }

    if (!selectedRecipient) {
      selectedRecipient = deliveryRecipient;
    }

    selectedDestination && onDeliveryAddressChanged(deliveryIndex, selectedDestination);
    selectedRecipient && onDeliveryRecipientChanged(deliveryIndex, selectedRecipient);
  };

  const onDeliveryDestinationChange = (destination: Destination): void => {
    destination && onDeliveryAddressChanged(deliveryIndex, destination);
  };

  const onDeliveryRecipientFieldChange = (e: ChangeEvent<HTMLInputElement>): void => {
    if (delivery?.deliveryRecipient) {
      onDeliveryRecipientChanged(deliveryIndex, {
        ...delivery?.deliveryRecipient,
        isNew: delivery.isNewDeliveryRecipient,
        [e.target.name]: e.target.value,
      });
    }
  };

  const onPinChanged = ({ longitude, latitude }: MapMarker): void => {
    if (pickupAddress) {
      onPickupAddressChanged({
        ...pickupAddress,
        location: {
          lon: longitude,
          lat: latitude,
        },
      });
    }
  };

  const marker = useMemo(() => {
    if (pickupAddress) {
      return {
        latitude: pickupAddress?.location.lat,
        longitude: pickupAddress?.location.lon,
      };
    }
  }, [pickupAddress]);

  const prevDestinations = useMemo(() => {
    if (pickupAddress) {
      return [
        {
          value: pickupAddress.id,
          label: pickupAddress.name,
        },
      ];
    }

    return [];
  }, [pickupAddress]);

  const getFinancialInstitutions = (): Array<OptionType> => {
    const { financialInstitution } = currentUser || {};

    if (financialInstitution) {
      return [
        {
          label: financialInstitution.name,
          value: financialInstitution.id,
        },
      ];
    }

    return [];
  };

  const getLocationSourceOptions = (): Array<OptionType> => {
    const options: Array<OptionType> = [];

    if (!transactionType) {
      return options;
    }

    for (const [key, value] of Object.entries(LocationTypes)) {
      if (
        (transactionType === CASH_CHANGE_DROPS && key === 'addressBook') ||
        (transactionType === CASH_DEPOSIT && key === 'fedReserve') ||
        locationTarget === key
      ) {
        continue;
      }

      if (currentUser?.recipientInfo?.isFinancialInstitution && key === 'addressBook') {
        continue;
      }

      if (!currentUser?.recipientInfo?.isFinancialInstitution && key === 'fedReserve') {
        continue;
      }

      if (!currentUser?.recipientInfo?.isFinancialInstitution) {
        if (transactionType === CASH_DEPOSIT && key === 'vault') {
          continue;
        }
      }

      options.push({ value: key, label: value });
    }

    return options;
  };

  const getLocationTargetOptions = (): Array<OptionType> => {
    const options: Array<OptionType> = [];

    if (!transactionType) {
      return options;
    }

    for (const [key, value] of Object.entries(LocationTypes)) {
      if (
        (transactionType === CASH_CHANGE_DROPS && key === 'fedReserve') ||
        (transactionType === CASH_DEPOSIT && key === 'addressBook') ||
        locationSource === key
      ) {
        continue;
      }

      if (currentUser?.recipientInfo?.isFinancialInstitution && key === 'addressBook') {
        continue;
      }

      if (!currentUser?.recipientInfo?.isFinancialInstitution && key === 'fedReserve') {
        continue;
      }

      options.push({ value: key, label: value });
    }

    return options;
  };

  const getDestinationOptions = (): Array<OptionType> => destinationsOptions(false, true);

  const getTransactionTypeOptions = (): Array<OptionType> => {
    const options: Array<OptionType> = [];

    Object.entries(TransactionType).forEach(([key, value]) => {
      options.push({ value: key, label: value });
    });

    return options;
  };

  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <div className="row">
            <div className="col-12">
              <h2>Cash Transport</h2>
              <p className="tagline text-secondary">Order Details</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <form className="needs-validation" noValidate>
                <div className="row">
                  <div className="col-12">
                    {!isValid && !isEmpty(errors) && (
                      <div className="alert alert-primary" role="alert">
                        Check the following errors before continue
                      </div>
                    )}
                  </div>
                  <div className="col-12 mb-1">
                    <SelectField
                      id="transactionType"
                      label="Transaction Type"
                      options={getTransactionTypeOptions()}
                      onChange={(option: OptionType): void => onTransactionTypeChange(option)}
                      value={transactionType}
                      isValid={!errors?.transactionType}
                      feedback={errors?.transactionType}
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-1">
                    <SelectField
                      id="locationSource"
                      label="From"
                      options={getLocationSourceOptions()}
                      onChange={(option: OptionType): void => onLocationSourceChanged(option.value)}
                      value={locationSource}
                      isValid={!errors?.locationSource}
                      feedback={errors?.locationSource}
                    />
                  </div>
                  <div className="col-12 col-sm-6 mb-1">
                    <SelectField
                      id="locationTarget"
                      label="To"
                      options={getLocationTargetOptions()}
                      onChange={(option: OptionType): void => onLocationTargetChanged(option.value)}
                      value={locationTarget}
                      isValid={!errors?.locationTarget}
                      feedback={errors?.locationTarget}
                    />
                  </div>
                  <div className="col-12 mb-1">
                    <SelectField
                      id="financialInstitutionId"
                      label="Financial Institution"
                      options={getFinancialInstitutions()}
                      onChange={(option: OptionType): void => onFinancialInstitutionChange(option)}
                      value={financialInstitutionId}
                      isValid={!errors?.financialInstitutionId}
                      feedback={errors?.financialInstitutionId}
                    />
                  </div>
                  {isPickup && (
                    <div className="col-12 mb-1">
                      <SelectField
                        id="pickupAddress"
                        label="Pick-up Address"
                        options={getDestinationOptions()}
                        onChange={(option: OptionType): void => onPickupAddressChange(option)}
                        value={isNewPickupRecipient ? 'new' : `${pickupRecipient?.id},${pickupAddress?.id}`}
                        isValid={!errors?.pickupAddress}
                        feedback={errors?.pickupAddress}
                      />
                    </div>
                  )}
                  {isDelivery && (
                    <div className="col-12 mb-1">
                      <SelectField
                        id="deliveryAddress"
                        label="Delivery Address"
                        options={getDestinationOptions()}
                        onChange={(option: OptionType): void => onDeliveryAddressChange(option)}
                        value={isNewDeliveryRecipient ? 'new' : `${deliveryRecipient?.id},${deliveryAddress?.id}`}
                        isValid={!errors?.deliveryAddress}
                        feedback={errors?.deliveryAddress}
                      />
                    </div>
                  )}
                  {isNewPickupRecipient && (
                    <div className="col-12 mb-1">
                      <DestinationSelectField
                        id="newPickupAddress"
                        label="New Pick-up Address"
                        options={prevDestinations}
                        onChange={onPickupDestinationChange}
                        value={pickupAddress?.id}
                        isValid={!errors?.pickupAddress}
                        feedback={errors?.pickupAddress}
                      />
                    </div>
                  )}
                  {isNewDeliveryRecipient && (
                    <div className="col-12 mb-1">
                      <DestinationSelectField
                        id="newDeliveryAddress"
                        label="New Delivery Address"
                        options={prevDestinations}
                        onChange={onDeliveryDestinationChange}
                        value={deliveryAddress?.id}
                        isValid={!errors?.deliveryAddress}
                        feedback={errors?.deliveryAddress}
                        autoComplete={false}
                      />
                    </div>
                  )}
                  {transactionType === CASH_DEPOSIT && (
                    <div className="col-12 mb-1">
                      <TextField
                        id="requestedPickup"
                        name="requestedPickup"
                        type="datetime-local"
                        label="Date of Pick-up"
                        onChange={(e: ChangeEvent<HTMLInputElement>): void => onPickupDateChanged(e.target.value)}
                        value={requestedPickup}
                        isValid={!errors?.requestedPickup}
                        feedback={errors?.requestedPickup}
                      />
                    </div>
                  )}
                  {transactionType === CASH_CHANGE_DROPS && (
                    <div className="col-12 mb-1">
                      <TextField
                        id="requestedDelivery"
                        name="requestedDelivery"
                        type="datetime-local"
                        label="Date of Delivery"
                        onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                          onDeliveryDateChanged(deliveryIndex, e.target.value)
                        }
                        value={requestedDelivery}
                        isValid={!errors?.requestedDelivery}
                        feedback={errors?.requestedDelivery}
                      />
                    </div>
                  )}
                  {transactionType === CASH_DEPOSIT && (
                    <div className="col-12 mb-1">
                      <TextField
                        id="amount"
                        name="amount"
                        type="number"
                        label="Amount"
                        onChange={onAmountChange}
                        value={amount}
                        isValid={!errors?.amount}
                        feedback={errors?.amount}
                      />
                    </div>
                  )}

                  {isPickup && (
                    <>
                      <div className="col-12 mb-1">
                        <TextField
                          id="pickupContactName"
                          name="name"
                          type="text"
                          label="Business Name"
                          disabled={!isNewPickupRecipient}
                          onChange={onPickupRecipientFieldChange}
                          value={pickupRecipient?.name ?? ''}
                          isValid={!errors?.pickupContactName}
                          feedback={errors?.pickupContactName}
                        />
                      </div>
                      <div className="col-12 mb-1">
                        <TextField
                          id="pickupPhone"
                          name="phone"
                          type="text"
                          label="Pick-up Phone"
                          disabled={!isNewPickupRecipient}
                          onChange={onPickupRecipientFieldChange}
                          value={pickupRecipient?.phone ?? ''}
                          isValid={!errors?.pickupPhone}
                          feedback={errors?.pickupPhone}
                        />
                      </div>
                      <div className="col-12 mb-1">
                        <TextField
                          id="pickupRecipientEmail"
                          name="email"
                          type="email"
                          label="Email"
                          disabled={!isNewPickupRecipient}
                          onChange={onPickupRecipientFieldChange}
                          value={pickupRecipient?.email ?? ''}
                          isValid={!errors?.email}
                          feedback={errors?.email}
                        />
                      </div>
                    </>
                  )}
                  {isDelivery && (
                    <>
                      <div className="col-12 mb-1">
                        <TextField
                          id="deliveryContactName"
                          name="name"
                          type="text"
                          label="Business Name"
                          disabled={!isNewDeliveryRecipient}
                          onChange={onDeliveryRecipientFieldChange}
                          value={deliveryRecipient?.name || ''}
                          isValid={!errors?.deliveryContactName}
                          feedback={errors?.deliveryContactName}
                        />
                      </div>
                      <div className="col-12 mb-1">
                        <TextField
                          id="deliveryPhone"
                          name="phone"
                          type="text"
                          label="Delivery Phone"
                          disabled={!isNewDeliveryRecipient}
                          onChange={onDeliveryRecipientFieldChange}
                          value={deliveryRecipient?.phone || ''}
                          isValid={!errors?.deliveryPhone}
                          feedback={errors?.deliveryPhone}
                        />
                      </div>
                      <div className="col-12 mb-1">
                        <TextField
                          id="deliveryRecipientEmail"
                          name="email"
                          type="email"
                          label="Email"
                          disabled={!isNewDeliveryRecipient}
                          onChange={onDeliveryRecipientFieldChange}
                          value={deliveryRecipient?.email}
                          isValid={!errors?.email}
                          feedback={errors?.email}
                        />
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-md-6">
          <DraggableMarkerMap
            className="rounded"
            marker={marker}
            disabled={!isNewPickupRecipient}
            onPinChanged={onPinChanged}
          />
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ orderStepper }: StateProps): any => ({ ...orderStepper });

const mapDispatchToProps = { ...OrderStepperActions };

const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CashTransportRequestForm);

export default Connected;
