import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { signIn, setPersistence } from 'services/auth';
import classnames from 'classnames';
import { PersitenceMode } from 'types/auth';
import { EmailField, PasswordField, KeepMeSignedInField } from '.';
import ReCaptcha from 'components/Recaptcha';
import { MIN_RECAPTCHA_SCORE, MAX_LOGIN_ATTEMPTS_ALLOWED } from 'constants/auth';

import styles from 'components/Layout/AuthLayout.module.scss';
import { logActionAttempt, getActionAttempt, clearActionAttempt, getUnits } from 'services/recaptcha';
import { login as loginConfig } from 'config';
import moment from 'moment';
import {
  resetPassword as resetPasswordRoute,
  newOrderGuest as newOrderGuestPath,
  signUp as signUpPath,
} from 'constants/routes';

interface Props {
  error: (message: string) => void;
  warning: (message: string) => void;
}

const LoginForm: React.FC<Props> = ({ warning, error }: Props) => {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [keepMeSignedIn, setKeepMeSigned] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>();

  const onUsernameChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value);
  };

  const onKeepMeSignedChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setKeepMeSigned(e.target.checked);
  };

  const validateRecaptchaThenSubmit = async (execute: () => Promise<any>): Promise<void> => {
    const recaptchaResponse = await execute();
    console.log('--> start recaptchaResponse');

    const {
      resetTime: { amount, unit },
    } = loginConfig;

    if (recaptchaResponse.status === 'failed') {
      logActionAttempt({ actionName: 'login', amount, unit });
      warning(recaptchaResponse.message);
      return;
    }

    if (recaptchaResponse.score <= MIN_RECAPTCHA_SCORE) {
      logActionAttempt({ actionName: 'login', amount, unit });
      warning('Unable to login at this moment.');
      return;
    }

    const persistence = keepMeSignedIn ? PersitenceMode.local : PersitenceMode.session;
    setPersistence(persistence);

    console.log('--> start signIn');
    signIn({ email, password })
      .then(() => {
        clearActionAttempt('login');
      })
      .catch(() => {
        const {
          resetTime: { amount, unit },
        } = loginConfig;

        logActionAttempt({ actionName: 'login', amount, unit });
        warning('The password is invalid or the user does not have a password.');
        setIsLoading(false);
      });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>, execute: () => Promise<any>): Promise<any> => {
    setIsLoading(true);
    console.log('--> start handleSubmit');

    e.preventDefault();
    const {
      resetTime: { amount, unit },
    } = loginConfig;
    try {
      const attemptpsJson = getActionAttempt('login');
      const attemptps = attemptpsJson && JSON.parse(attemptpsJson);

      if (attemptps) {
        const end = moment(attemptps.timestamp);
        const now = moment();
        const difference = end.diff(now, getUnits(unit));

        // reset attempts counter after 30 seconds
        if (difference <= 0) {
          clearActionAttempt('login');
          validateRecaptchaThenSubmit(execute);
          return;
        }

        // reject login if attempts exceeded today
        if (difference > 0 && attemptps.count > MAX_LOGIN_ATTEMPTS_ALLOWED) {
          error(`Too many unsuccessful login attempts. Please try again in ${difference} ${unit}`);
          return;
        }
      }

      // otherwise execute captcha validation
      if (!attemptps || attemptps.count <= MAX_LOGIN_ATTEMPTS_ALLOWED) {
        validateRecaptchaThenSubmit(execute);
        return;
      }
    } catch (e) {
      logActionAttempt({ actionName: 'login', amount, unit });
      console.log(e);
    }
  };

  return (
    <ReCaptcha action="login">
      {(captcha): JSX.Element => (
        <form
          id="login-form"
          className="text-center"
          onSubmit={(e: React.FormEvent<HTMLFormElement>): Promise<void> => handleSubmit(e, captcha.execute)}
        >
          <EmailField
            className={classnames('form-control', styles.form_input)}
            onChange={onUsernameChange}
            value={email}
          />
          <PasswordField
            className={classnames('form-control', styles.form_input)}
            onChange={onPasswordChange}
            value={password}
          />
          <div className={classnames('d-flex align-items-center', styles.form_actions)}>
            <KeepMeSignedInField onChange={onKeepMeSignedChange} checked={keepMeSignedIn} />
            <Link to={resetPasswordRoute} className="text-dark mb-2">
              Forgot Password
            </Link>
          </div>
          <button
            id="login-submit-btn"
            className={styles.form_submit}
            type="submit"
            disabled={!captcha.isReady || isLoading}
          >
            {isLoading ? 'Authenticating...' : 'Sign in'}
          </button>
          <div className={classnames('mt-3', styles.login_others_links)}>
            <div className={styles.login_other_link_item}>
              <Link to={signUpPath} className="text-info text-small">
                Request access
              </Link>
            </div>
            <div className={styles.login_other_link_item}>
              <span className="text-info text-small"> or </span>
            </div>
            <div className={styles.login_other_link_item}>
              <Link to={newOrderGuestPath} className="text-info text-small">
                Continue as Guest
              </Link>
            </div>
          </div>
        </form>
      )}
    </ReCaptcha>
  );
};

export default LoginForm;
