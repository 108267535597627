import { Recipient } from 'types';
import { MRALicense } from './recipient';

export type Address = {
  name?: string;
  country: string;
  formatted: string;
  line1: string;
  postalCode: string;
  state: string;
};

export const DayOfWeek = {
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
};

export type Location = {
  lat: any;
  lon: any;
};

export type DestinationStatus = {
  active: false;
};

export type ReceivingHour = {
  dayOfWeek?: keyof typeof DayOfWeek;
  startTime?: string;
  endTime?: string;
};

export interface Destination {
  id?: string;
  active: boolean;
  address: Address;
  location: Location;
  name: string;
  owner: string;
  photoUrl?: string;
  extra?: { googlePlaceId: string };
  ownerInfo?: Recipient;
  metrcLicenses?: Array<MRALicense>;
  receivingHours?: Array<ReceivingHour>;
}
