import React, { PropsWithChildren } from 'react';
import PropTypes from 'prop-types';

import { AlertProvider, AlertConsumer } from 'providers/alert';
import { Alert } from 'components/Alerts';

import styles from './AlertWrapper.module.scss';

const AlertWrapper: React.FC<PropsWithChildren<unknown>> = ({ children }) => (
  <AlertProvider>
    <AlertConsumer>
      {({ alert }): any => {
        return (
          <div className={styles.wrapper}>
            <Alert isShowing={!!alert} type={alert?.type} message={alert?.message} />
          </div>
        );
      }}
    </AlertConsumer>
    {children}
  </AlertProvider>
);

AlertWrapper.propTypes = {
  children: PropTypes.element,
};

export default AlertWrapper;
