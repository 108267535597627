import React from 'react';
import style from '../../OrderDetail.module.scss';
import { OrderDeliveryInfoProps } from './type';

const OrderDeliveryInfo: React.FC<OrderDeliveryInfoProps> = ({
  destinationInfo,
  recipientInfo,
}: OrderDeliveryInfoProps) => {
  return (
    <>
      <div className="d-flex flex-column mt-3 delivery-to-name">
        <span className={style.label}>Delivery to Name</span>
        <span className={style.titleLarge} data-id="order-delivery-name">
          {recipientInfo?.name || '-'}
        </span>
      </div>
      <div className="d-flex flex-column mt-3 delivery-to-phone">
        <span className={style.label}>Delivery to Phone</span>
        <span data-id="order-delivery-phone">{recipientInfo?.phone || '-'}</span>
      </div>
      <div className="d-flex flex-column mt-3 delivery-to-address">
        <span className={style.label}>Delivery to Address</span>
        <span data-id="order-delivery-address">{destinationInfo?.name || '-'}</span>
      </div>
    </>
  );
};

export default OrderDeliveryInfo;
