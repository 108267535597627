import { firebase, firestore, auth } from '_firebase';
import { SignInData, SignUpData, PersitenceMode, ResetPasswordData } from '../types/auth';
import { ResetPassword } from 'types/resetPassword';
import * as collections from 'constants/collections';
import { SUCCESS, ERROR, REQUEST_ACCOUNT_ERROR, REQUEST_ACCOUNT_SUCCESS } from 'constants/strings';

export const signIn = ({ email, password }: SignInData): Promise<unknown> => {
  return auth.signInWithEmailAndPassword(email, password);
};

export const signUp = async (data: SignUpData): Promise<any> => {
  let result: any;
  try {
    const signUpCollection = firestore.collection(collections.ACCOUNT_REQUESTS);
    await signUpCollection.add(data);
    result = {
      code: SUCCESS,
      message: REQUEST_ACCOUNT_SUCCESS,
    };
  } catch (error) {
    result = {
      code: ERROR,
      message: REQUEST_ACCOUNT_ERROR,
    };
  }
  return result;
};

export const resetPassword = ({ email }: ResetPasswordData): Promise<unknown> => {
  return auth.sendPasswordResetEmail(email);
};

export const signOut = (): Promise<void> => {
  return auth.signOut();
};

export const setPersistence = (type: PersitenceMode): Promise<void> => {
  return auth.setPersistence(firebase.auth.Auth.Persistence[type]);
};

export const updatePassword = async (resetPassword: ResetPassword): Promise<any> => {
  const user = firebase.auth().currentUser;

  if (!resetPassword.oldPassword || !user?.email || !resetPassword.newPassword) {
    return;
  }

  const emailCred = firebase.auth.EmailAuthProvider.credential(user?.email, resetPassword?.oldPassword);
  let result: any;
  try {
    const data = await user?.reauthenticateWithCredential(emailCred);
    if (data) {
      user?.updatePassword(resetPassword.newPassword);
      result = {
        code: 'success',
        message: 'password updated successfully',
      };
    }
  } catch (error) {
    result = {
      code: error.code,
      message: error.message,
    };
  }
  return result;
};
