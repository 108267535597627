import { OrderStatus } from 'types';

export const ORDER_STATUS_LABELS: { [key in OrderStatus]: string } = {
  [OrderStatus.DRAFT]: 'Draft',
  [OrderStatus.CREATED]: 'Sent',
  [OrderStatus.ACCEPTED]: 'Accepted',
  [OrderStatus.PROCESSING]: 'Processing',
  [OrderStatus.SCHEDULED]: 'Scheduled',
  [OrderStatus.PROGRESS]: 'On the Way',
  [OrderStatus.COMPLETED]: 'Delivered',
  [OrderStatus.ASSIGNED]: 'Assigned',
  [OrderStatus.ARRIVAL]: 'Arrival',
  [OrderStatus.CANCELED]: 'Canceled',
};
