import React from 'react';
import { OrderRequest } from 'types';
import { DownloadPDFButton } from 'components/DownloadPDFButton';

import styles from './Stepper.module.scss';

type Props = {
  title: string;
  message: string;
  order: OrderRequest;
};

const StepperFeedback: React.FC<Props> = ({ order, title, message }: Props) => {
  return (
    <div className="d-flex flex-column align-items-center bg-white rounded py-4">
      <div className={styles.feedbackIconContainer}>
        <i className="fas fa-check-circle"></i>
      </div>
      <h2 className={styles.feedbackTitle}>{title}</h2>
      <div>{!!order.id && <DownloadPDFButton orderId={order.id} isRequest />}</div>
      <p className={styles.feedbackMessage}>{message}</p>
      <div className="d-flex flex-column align-items-center mt-2">
        <small>
          <a href="https://jklogix.com/">Go to JKLogix</a>
        </small>
      </div>
    </div>
  );
};

export default StepperFeedback;
