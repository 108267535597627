/* eslint-disable no-case-declarations */
import {
  ActionTypes,
  SET_BILLING_ADDRESS,
  SET_PICKUP_ADDRESS,
  SET_PICKUP_RECIPIENT,
  SET_PICKUP_DATE,
  SET_NEW_PICKUP_RECIPIENT,
  SET_PICKUP_ADDRESS_AS_BILLING,
  SET_DELIVERY_ADDRESS,
  SET_NEW_DELIVERY_RECIPIENT,
  SET_DELIVERY_RECIPIENT,
  SET_DELIVERY_DATE,
  SET_PRODUCTS,
  SET_NEW_PRODUCT,
  SET_REMOVE_PRODUCT,
  SET_PRODUCT_METRC_LICENSE,
  SET_PRODUCT_NAME,
  SET_PRODUCT_DESCRIPTION,
  SET_PRODUCT_QUANTITY,
  SET_FOUND_PRODUCT,
  SET_EMPTY_PRODUCT_WITH_TAG,
  SET_GUEST_NAME,
  SET_GUEST_EMAIL,
  SET_GUEST_PHONE,
  CLEAN_ORDER,
  SET_ATTACHMENT,
  SET_INVOICE_AS_REQUIRED,
  SET_NOTE,
  SET_NEW_DELIVERY_INFO,
  SET_DELIVERIES_DATA,
  SET_REMOVE_DELIVERY_INFO,
  SET_TRANSACTION_TYPE,
  SET_DEPOSIT_TYPE,
  SET_AMOUNT,
  RESET_RECIPIENT,
  SET_FINANCIAL_INSTITUTION,
  SET_NEW_CASH_DENOMINATION,
  SET_REMOVE_CASH_DENOMINATION,
  SET_CASH_DENOMINATION_QUANTITY,
  SET_CASH_DENOMINATION_TYPE,
  SET_PICKUP_IS_FRESH_FROZEN_TRANSPORT,
  SET_LOCATION_SOURCE,
  SET_LOCATION_TARGET,
  SET_DELIVERY_ORDER_SIZE,
  SET_CASH_DENOMINATION_AMOUNT,
} from 'actions/types/orderStepper';
import { isEmpty } from 'lodash';
import {
  Recipient,
  Destination,
  OrderItem,
  CashTransactionType,
  DepositType,
  cashDenomination,
  CashDenominationValues,
  CashChange,
  LocationType,
} from 'types';
import { AttachmentInfo } from 'types/attachmentInfo';
import { MetrcPackage } from 'types/metrcPackage';

const EMPTY_RECIPIENT = {
  id: '',
  name: '',
  email: '',
  metrcLicenseNumber: '',
  phone: '',
  ownerId: '',
  disabled: false,
};

const EMPTY_ORDER_ITEM: OrderItem = {
  metrcReferenceNumber: '',
  description: '',
  item: '',
  price: 0,
  quantity: 0,
};

export type deliveryInfoProp = {
  deliveryAddress?: Destination;
  deliveryRecipient?: Recipient;
  requestedDelivery?: string;
  orderSize?: string;
  isNewDeliveryRecipient: boolean;
};

export type productListProp = {
  products: Array<OrderItem>;
  metrcPackages?: Array<MetrcPackage>;
};

export type OrderStepperDeliveryStateProps = {
  deliveryInfo: deliveryInfoProp;
  productList: productListProp;
  attachments: AttachmentInfo;
};

export const EMPTY_DELIVERY_ITEM: OrderStepperDeliveryStateProps = {
  deliveryInfo: {
    isNewDeliveryRecipient: false,
  },
  productList: {
    products: [EMPTY_ORDER_ITEM],
    metrcPackages: [],
  },
  attachments: {
    isInvoiceRequired: false,
    note: '',
  },
};

export type OrderStepperStateProps = {
  contact: {
    name: string;
    email: string;
    phone: string;
  };
  pickup: {
    billingAddressId: string;
    pickupAddress?: Destination;
    pickupRecipient?: Recipient;
    requestedPickup?: string;
    isNewPickupRecipient: boolean;
    pickupAddressAsBilling: boolean;
    isFreshFrozenTransport: boolean;
  };
  deliveries: Array<OrderStepperDeliveryStateProps>;

  financialInstitutionId?: string;
  locationSource?: LocationType;
  locationTarget?: LocationType;
  transactionType?: CashTransactionType;
  depositType?: DepositType;
  amount?: number;
  cashChange: CashChange;

  isGuestView: boolean;
};

const initialState: OrderStepperStateProps = {
  contact: {
    name: '',
    email: '',
    phone: '',
  },
  pickup: {
    billingAddressId: '',
    isNewPickupRecipient: false,
    pickupAddressAsBilling: true,
    isFreshFrozenTransport: false,
  },
  deliveries: [EMPTY_DELIVERY_ITEM],

  transactionType: undefined,
  depositType: undefined,
  amount: undefined,
  cashChange: {
    denominations: [],
    total: 0,
  },
  isGuestView: false,
};

export default function (state = initialState, action: ActionTypes): OrderStepperStateProps {
  let products: OrderItem[];
  let metrcPackages: MetrcPackage[] | undefined;

  const getUpdatedDeliveries = (index: number, key: string, props: any): Array<OrderStepperDeliveryStateProps> => {
    const updatedDeliveries = state.deliveries.map((deliveryItem, itemIndex) => {
      if (itemIndex === index) {
        return {
          ...deliveryItem,
          [key]: {
            ...props,
          },
        };
      }
      return deliveryItem;
    });
    return updatedDeliveries;
  };

  switch (action.type) {
    case CLEAN_ORDER:
      return initialState;
    case SET_BILLING_ADDRESS:
      return {
        ...state,
        pickup: {
          ...state.pickup,
          billingAddressId: action.payload.billingAddressId,
        },
      };
    case SET_TRANSACTION_TYPE:
      return {
        ...state,
        transactionType: action.payload.transactionType.value,
      };
    case SET_DEPOSIT_TYPE:
      return {
        ...state,
        depositType: action.payload.depositType.value,
      };
    case SET_AMOUNT:
      return {
        ...state,
        amount: action.payload.amount,
      };
    case SET_FINANCIAL_INSTITUTION:
      return {
        ...state,
        financialInstitutionId: action.payload.financialInstitutionId,
      };
    case SET_LOCATION_SOURCE:
      return {
        ...state,
        locationSource: action.payload.locationSource,
      };
    case SET_LOCATION_TARGET:
      return {
        ...state,
        locationTarget: action.payload.locationTarget,
      };
    case SET_PICKUP_ADDRESS:
      return {
        ...state,
        pickup: {
          ...state.pickup,
          pickupAddress: action.payload.pickupAddress,
        },
      };
    case SET_NEW_PICKUP_RECIPIENT:
      return {
        ...state,
        pickup: {
          ...state.pickup,
          isNewPickupRecipient: true,
          pickupAddress: undefined,
          pickupRecipient: EMPTY_RECIPIENT,
        },
      };
    case SET_PICKUP_RECIPIENT:
      return {
        ...state,
        pickup: {
          ...state.pickup,
          isNewPickupRecipient: isEmpty(action.payload.pickupRecipient.id),
          pickupRecipient: action.payload.pickupRecipient,
        },
      };
    case SET_PICKUP_DATE:
      return {
        ...state,
        pickup: {
          ...state.pickup,
          requestedPickup: action.payload.requestedPickup,
        },
      };
    case SET_PICKUP_ADDRESS_AS_BILLING:
      return {
        ...state,
        pickup: {
          ...state.pickup,
          pickupAddressAsBilling: action.payload.pickupAddressAsBilling,
        },
      };
    case SET_PICKUP_IS_FRESH_FROZEN_TRANSPORT:
      return {
        ...state,
        pickup: {
          ...state.pickup,
          isFreshFrozenTransport: action.payload.isFreshFrozenTransport,
        },
      };
    case SET_DELIVERY_ADDRESS:
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.index, 'deliveryInfo', {
          ...state.deliveries[action.payload.index].deliveryInfo,
          deliveryAddress: action.payload.deliveryAddress,
        }),
      };
    case SET_NEW_DELIVERY_RECIPIENT:
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.index, 'deliveryInfo', {
          ...state.deliveries[action.payload.index].deliveryInfo,
          isNewDeliveryRecipient: true,
          deliveryAddress: undefined,
          deliveryRecipient: EMPTY_RECIPIENT,
        }),
      };
    case SET_DELIVERY_RECIPIENT:
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.index, 'deliveryInfo', {
          ...state.deliveries[action.payload.index].deliveryInfo,
          isNewDeliveryRecipient: action.payload.deliveryRecipient.isNew,
          deliveryRecipient: action.payload.deliveryRecipient,
          orderSize: action.payload.deliveryRecipient.orderSize,
        }),
      };
    case SET_DELIVERY_DATE:
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.index, 'deliveryInfo', {
          ...state.deliveries[action.payload.index].deliveryInfo,
          requestedDelivery: action.payload.requestedDelivery,
        }),
      };
    case SET_DELIVERY_ORDER_SIZE:
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.index, 'deliveryInfo', {
          ...state.deliveries[action.payload.index].deliveryInfo,
          orderSize: action.payload.orderSize,
        }),
      };
    case SET_PRODUCTS:
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.deliveryIndex, 'productList', {
          products: action.payload.products,
          metrcPackages: state.deliveries[action.payload.deliveryIndex].productList.metrcPackages,
        }),
      };
    case SET_NEW_PRODUCT:
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.deliveryIndex, 'productList', {
          products: [...state.deliveries[action.payload.deliveryIndex].productList.products, EMPTY_ORDER_ITEM],
          metrcPackages: state.deliveries[action.payload.deliveryIndex].productList.metrcPackages,
        }),
      };
    case SET_REMOVE_PRODUCT:
      const { payload: payloadData } = action;
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.deliveryIndex, 'productList', {
          products: [
            ...state.deliveries[payloadData.deliveryIndex].productList.products.slice(0, payloadData.productIndex),
            ...state.deliveries[payloadData.deliveryIndex].productList.products.slice(payloadData.productIndex + 1),
          ],
          metrcPackages: state.deliveries[action.payload.deliveryIndex].productList.metrcPackages,
        }),
      };
    case SET_PRODUCT_METRC_LICENSE:
    case SET_PRODUCT_NAME:
    case SET_PRODUCT_DESCRIPTION:
    case SET_PRODUCT_QUANTITY:
      const {
        payload: { deliveryIndex: payloadDeliveryIndex, productIndex: payloadProductIndex, ...productData },
      } = action;
      products = [...state.deliveries[payloadDeliveryIndex].productList.products];
      products[payloadProductIndex] = { ...products[payloadProductIndex], ...productData };
      return {
        ...state,
        deliveries: getUpdatedDeliveries(payloadDeliveryIndex, 'productList', {
          products,
          metrcPackages: state.deliveries[payloadDeliveryIndex].productList.metrcPackages,
        }),
      };
    case SET_FOUND_PRODUCT:
      const { payload } = action;
      products = [...state.deliveries[action.payload.deliveryIndex].productList.products];
      metrcPackages = state.deliveries[action.payload.deliveryIndex].productList.metrcPackages;
      products[payload.productIndex] = { ...products[payload.productIndex], ...payload.product };
      if (metrcPackages && metrcPackages.length > 0) {
        const index =
          state.deliveries[action.payload.deliveryIndex].productList.metrcPackages?.findIndex(
            p => p.label === payload.metrcPackage.label,
          ) || -1;
        if (index > -1) {
          metrcPackages[index] = { ...payload.metrcPackage };
        } else {
          metrcPackages.push(payload.metrcPackage);
        }
      } else {
        metrcPackages = [payload.metrcPackage];
      }
      return {
        ...state,
        deliveries: getUpdatedDeliveries(payload.deliveryIndex, 'productList', {
          products,
          metrcPackages,
        }),
      };
    case SET_EMPTY_PRODUCT_WITH_TAG:
      const {
        payload: { productIndex, tag: productTag, deliveryIndex },
      } = action;
      products = [...state.deliveries[action.payload.deliveryIndex].productList.products];
      products[productIndex] = { ...EMPTY_ORDER_ITEM, metrcReferenceNumber: productTag };
      return {
        ...state,
        deliveries: getUpdatedDeliveries(deliveryIndex, 'productList', {
          products,
          metrcPackages: state.deliveries[action.payload.deliveryIndex].productList.metrcPackages,
        }),
      };
    case SET_GUEST_NAME:
      return {
        ...state,
        contact: {
          ...state.contact,
          name: action.payload.name,
        },
      };
    case SET_GUEST_EMAIL:
      return {
        ...state,
        contact: {
          ...state.contact,
          email: action.payload.email,
        },
      };
    case SET_GUEST_PHONE:
      return {
        ...state,
        contact: {
          ...state.contact,
          phone: action.payload.phone,
        },
      };
    case SET_ATTACHMENT:
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.deliveryIndex, 'attachments', {
          ...action.payload.attachments,
        }),
      };
    case SET_INVOICE_AS_REQUIRED:
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.deliveryIndex, 'attachments', {
          ...state.deliveries[action.payload.deliveryIndex].attachments,
          isInvoiceRequired: action.payload.isInvoiceRequired,
        }),
      };
    case SET_NOTE:
      return {
        ...state,
        deliveries: getUpdatedDeliveries(action.payload.deliveryIndex, 'attachments', {
          ...state.deliveries[action.payload.deliveryIndex].attachments,
          note: action.payload.note,
        }),
      };
    case SET_NEW_DELIVERY_INFO:
      return {
        ...state,
        deliveries: [...state.deliveries, EMPTY_DELIVERY_ITEM],
      };
    case SET_DELIVERIES_DATA:
      return {
        ...state,
        deliveries: [...action.payload.deliveries],
      };
    case SET_REMOVE_DELIVERY_INFO:
      return {
        ...state,
        deliveries: [
          ...state.deliveries.slice(0, action.payload.deliveryIndex),
          ...state.deliveries.slice(action.payload.deliveryIndex + 1),
        ],
      };
    case RESET_RECIPIENT:
      return {
        ...state,
        ...(action.payload.type === 'pickup'
          ? {
              pickup: {
                ...state.pickup,
                isNewPickupRecipient: false,
                pickupAddress: undefined,
                pickupRecipient: EMPTY_RECIPIENT,
                requestedPickup: undefined,
                billingAddressId: '',
                pickupAddressAsBilling: false,
              },
            }
          : {
              deliveries: getUpdatedDeliveries(action.payload.index, 'deliveryInfo', {
                ...state.deliveries[action.payload.index].deliveryInfo,
                isNewDeliveryRecipient: false,
                deliveryAddress: undefined,
                deliveryRecipient: EMPTY_RECIPIENT,
                requestedDelivery: undefined,
              }),
            }),
      };

    case SET_NEW_CASH_DENOMINATION:
      return {
        ...state,
        cashChange: {
          ...state.cashChange,
          denominations: [
            ...state.cashChange.denominations,
            { denomination: cashDenomination[0], quantity: 0, amount: 0 },
          ],
        },
      };
    case SET_REMOVE_CASH_DENOMINATION: {
      const updatedCashDenominations = [
        ...state.cashChange.denominations.slice(0, action.payload.index),
        ...state.cashChange.denominations.slice(action.payload.index + 1),
      ];
      const total = updatedCashDenominations.reduce((acc, curr) => acc + curr.amount, 0);
      return {
        ...state,
        cashChange: {
          ...state.cashChange,
          denominations: updatedCashDenominations,
          total,
        },
      };
    }
    case SET_CASH_DENOMINATION_TYPE: {
      const updatedCashDenomination = { denomination: action.payload.denomination, quantity: 0, amount: 0 };
      const updatedCashDenominations = [
        ...state.cashChange.denominations.slice(0, action.payload.index),
        updatedCashDenomination,
        ...state.cashChange.denominations.slice(action.payload.index + 1),
      ];
      const total = updatedCashDenominations.reduce((acc, curr) => acc + curr.amount, 0);
      return {
        ...state,
        cashChange: {
          ...state.cashChange,
          denominations: updatedCashDenominations,
          total,
        },
      };
    }
    case SET_CASH_DENOMINATION_QUANTITY: {
      const {
        payload: { index, quantity },
      } = action;
      const { denomination } = state.cashChange.denominations[index];
      const denominationVal = CashDenominationValues[denomination];
      const amount = denominationVal * quantity;
      const updatedCashDenomination = { denomination, quantity, amount };

      const updatedCashDenominations = [
        ...state.cashChange.denominations.slice(0, action.payload.index),
        updatedCashDenomination,
        ...state.cashChange.denominations.slice(action.payload.index + 1),
      ];
      const total = updatedCashDenominations.reduce((acc, curr) => acc + curr.amount, 0);

      return {
        ...state,
        cashChange: {
          ...state.cashChange,
          denominations: updatedCashDenominations,
          total,
        },
      };
    }
    case SET_CASH_DENOMINATION_AMOUNT: {
      const {
        payload: { index, amount },
      } = action;
      const { denomination } = state.cashChange.denominations[index];
      const updatedCashDenomination = { denomination, quantity: amount / CashDenominationValues[denomination], amount };

      const updatedCashDenominations = [
        ...state.cashChange.denominations.slice(0, action.payload.index),
        updatedCashDenomination,
        ...state.cashChange.denominations.slice(action.payload.index + 1),
      ];
      const total = updatedCashDenominations.reduce((acc, curr) => acc + curr.amount, 0);

      return {
        ...state,
        cashChange: {
          ...state.cashChange,
          denominations: updatedCashDenominations,
          total,
        },
      };
    }
    default:
      return state;
  }
}
