import React from 'react';
import { CashChange, OrderItem } from 'types';
import OrderCashDepositTable from './OrderCashDepositTable';
import OrderCashDropsTable from './OrderCashDropsTable';
import { CASH_DEPOSIT } from 'constants/transactions';

export type Props = {
  items: Array<OrderItem>;
  transactionType: string;
  cashChange?: CashChange;
};

const OrderCash: React.FC<Props> = ({ items, transactionType, cashChange }: Props) => {
  return transactionType === CASH_DEPOSIT ? (
    <OrderCashDepositTable items={items} />
  ) : (
    <OrderCashDropsTable cashChange={cashChange} />
  );
};

export default OrderCash;
