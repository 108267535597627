import { firestore } from '_firebase';
import { api } from 'config';
import { Stats, Customer } from 'types/customer';
import * as collections from 'constants/collections';
import { Destination, Recipient } from 'types';

type CustomerStatsResponse = {
  status: string;
  data: Stats;
};

type CustomerPropsType = {
  customerId: string;
  post: (url: string, data: { customerId: string }) => Promise<CustomerStatsResponse>;
};

export const getCustomerStats = async (props: CustomerPropsType): Promise<Stats> => {
  const { customerId, post } = props;
  const customerStats: Stats = {
    ordersTotal: 0,
    draftOrdersTotal: 0,
    weekOrdersTotal: 0,
  };
  const { status, data } = await post(api.getCustomerStats, {
    customerId: customerId,
  });

  if (status === 'success') {
    return data;
  }
  return customerStats;
};

export const getCustomerInformation = async (customerId: string): Promise<Recipient | undefined> => {
  const doc = await firestore.collection(collections.RECIPIENTS).doc(customerId).get();
  const customer = doc.exists && (doc.data() as Recipient);

  if (customer) {
    return {
      ...customer,
      id: doc.id,
    };
  }

  return undefined;
};

export const updateCustomerInformation = async (data: Customer, customerId: string): Promise<any> => {
  if (!data || !customerId) {
    return;
  }

  return await firestore.collection(collections.RECIPIENTS).doc(customerId).update(data);
};

export const getCustomerDestinations = async (customerId: string): Promise<Array<Destination>> => {
  const destinations: Array<Destination> = [];

  // prettier-ignore
  const destinationsRef = await firestore
    .collection(collections.DESTINATIONS)
    .where('owner', '==', customerId)
    .get();

  if (!destinationsRef.empty) {
    destinationsRef.forEach(doc => {
      const destination = doc.data();
      destinations.push({
        id: doc.id,
        name: destination.name,
        active: destination.active,
        address: destination.address,
        location: destination.location,
        owner: destination.owner,
        photoUrl: destination.photoUrl,
        receivingHours: destination.receivingHours,
      });
    });
  }

  return destinations;
};
