import React from 'react';
import cls from 'classnames';

import { SelectFieldProps } from '../types';
import { Destination } from 'components/Destination';

const DestinationSelectField: React.FC<SelectFieldProps> = ({
  id,
  label,
  options,
  onChange,
  value,
  isValid,
  feedback,
  autoComplete = false,
  className,
  defaultValue,
  clearable,
}: SelectFieldProps) => {
  const Feedback = (): JSX.Element => {
    if (feedback) {
      return (
        <span className={cls({ 'valid-feedback': isValid, 'invalid-feedback': !isValid }, 'd-block')}>{feedback}</span>
      );
    }

    return <></>;
  };

  return (
    <div>
      <label>{label}</label>
      <Destination
        id={id}
        className={className}
        placeholder="Enter an address"
        options={options}
        onChange={onChange}
        value={value}
        autoComplete={autoComplete}
        defaultValue={defaultValue}
        clearable={clearable}
      />
      <Feedback />
    </div>
  );
};

export default DestinationSelectField;
