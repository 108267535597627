import React, { useContext } from 'react';
import { withAuthorization } from 'components';
import { AccountForm, AddressBook, ResetPasswordProfile } from './components';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useLocation, NavLink } from 'react-router-dom';
import * as routes from 'constants/routes';
import { Alert } from 'components/Alerts';
import { AlertType } from 'types/alert';
import { AuthContext } from 'contexts';
import { USER_HAS_NOT_RECIPIENT_ASSOCIATED } from 'constants/strings';
import BankingInformation from './components/BankingInformation';

const Account: React.FC = () => {
  const { currentUser } = useContext(AuthContext);
  const { pathname } = useLocation();

  const isAccountActive = pathname === routes.account;
  const isAddressBookActive = pathname === routes.addressBook;
  const isResetPasswordActive = pathname === routes.resetPasswordProfile;
  const isBankingInformationActive = pathname === routes.bankingInformation;

  return (
    <div className="row">
      <div className="col">
        <h1 className={styles.pageTitle}>Profile</h1>
        <ul className="nav nav-tabs" role="tablist">
          <li className="nav-item">
            <NavLink
              className={`nav-link`}
              id="account-tab"
              role="tab"
              to={routes.account}
              aria-controls="account"
              aria-selected="true"
            >
              ACCOUNT
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={`nav-link`}
              id="addressBook-tab"
              role="tab"
              to={routes.addressBook}
              aria-controls="addressBook"
              aria-selected="false"
            >
              ADDRESS BOOK
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={`nav-link`}
              id="bankingInformation-tab"
              role="tab"
              aria-controls="bankingInformation"
              to={routes.bankingInformation}
              aria-selected="false"
            >
              BANKING INFORMATION
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              className={`nav-link`}
              id="password-tab"
              role="tab"
              aria-controls="password"
              to={routes.resetPasswordProfile}
              aria-selected="false"
            >
              PASSWORD
            </NavLink>
          </li>
        </ul>
        <br />
        {pathname !== routes.resetPasswordProfile && (
          <Alert
            message={USER_HAS_NOT_RECIPIENT_ASSOCIATED}
            type={AlertType.error}
            isShowing={!currentUser?.recipientId}
          />
        )}
        <div id="myTabContent" className={classnames('tab-content')}>
          <div
            className={classnames(`tab-pane fade ${isAccountActive ? 'show active' : ''}`, styles.container)}
            id="account"
            role="tabpanel"
            aria-labelledby="account-tab"
          >
            {isAccountActive && <AccountForm />}
          </div>
          <div
            className={`tab-pane fade ${isAddressBookActive ? 'show active' : ''}`}
            id="addressBook"
            role="tabpanel"
            aria-labelledby="addressBook-tab"
          >
            {isAddressBookActive && <AddressBook />}
          </div>
          <div
            className={classnames(`tab-pane fade ${isResetPasswordActive ? 'show active' : ''}`, styles.container)}
            id="password"
            role="tabpanel"
            aria-labelledby="password-tab"
          >
            {isResetPasswordActive && <ResetPasswordProfile />}
          </div>
          <div
            className={classnames(`tab-pane fade ${isBankingInformationActive ? 'show active' : ''}`, styles.container)}
            id="bankingInformation"
            role="tabpanel"
            aria-labelledby="bankingInformation-tab"
          >
            {isBankingInformationActive && <BankingInformation />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withAuthorization(Account);
