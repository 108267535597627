import React, { useEffect, useState, useContext } from 'react';
import cls from 'classnames';
import { AuthContext } from 'contexts';
import { Stats } from 'types/customer';
import { getCustomerStats } from 'services/customers';

import { withAuthorization, withPageView } from 'components';
import { ActionCard } from 'components/Cards';
import * as routes from 'constants/routes';

import style from './Dashboard.module.scss';
import { OrderStatus, OrderDateFilterOptions } from 'types';
import { withQueryString } from 'utils/paths';

import { useApi } from 'services/hooks';

const Dashboard: React.FC = () => {
  const [stats, setStats] = useState<Stats | null>();
  const { currentUser } = useContext(AuthContext);
  const { post, isLoading } = useApi();

  useEffect(() => {
    const getStats = async (): Promise<void> => {
      if (currentUser?.uid) {
        const stats = await getCustomerStats({
          customerId: currentUser.uid,
          post,
        });
        setStats(stats);
      }
    };

    getStats();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="row">
      <div className="col">
        <div className={cls(style.wrapper, 'pb-4')}>
          <h1 className={style.pageTitle}>Dashboard</h1>
          <div className={cls('d-flex justify-content-between flex-wrap', style.actionCardsContainer)}>
            <ActionCard
              customStyle={style.actionCard}
              title="Order History"
              counter={stats?.ordersTotal}
              route={routes.orderHistory}
              selector="order-history"
              isLoading={isLoading}
            />
            <ActionCard
              customStyle={style.actionCard}
              title="Order This Week"
              counter={stats?.weekOrdersTotal}
              route={withQueryString(routes.orders, { createdDate: OrderDateFilterOptions.THIS_WEEK })}
              selector="order-this-week"
              isLoading={isLoading}
            />
            <ActionCard
              customStyle={style.actionCard}
              title="To Date Spend"
              counter={0}
              route="#"
              selector="order-spend"
              isLoading={isLoading}
            />
            <ActionCard
              customStyle={style.actionCard}
              title="Draft Orders"
              counter={stats?.draftOrdersTotal}
              route={withQueryString(routes.orders, { status: OrderStatus.DRAFT })}
              selector="order-draft"
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const Enhanced = withPageView(Dashboard, 'Dashboard');
export default withAuthorization(Enhanced);
