import React from 'react';
import { CashChange, Denomination } from 'types';
import { currencyFormat } from 'utils';

export type Props = {
  cashChange?: CashChange;
};

const OrderCashDropsTable: React.FC<Props> = ({ cashChange }: Props) => {
  return cashChange?.denominations && cashChange?.denominations.length > 0 ? (
    <div id="orderCashDropsTable" className="table-container" role="table" aria-label="Transaction details">
      <div className="flex-table header" role="rowgroup">
        <div className="flex-table-row columns-3" role="columnheader">
          Denomination
        </div>
        <div className="flex-table-row columns-3" role="columnheader">
          Quantity
        </div>
        <div className="flex-table-row columns-3" role="columnheader">
          Amount
        </div>
      </div>
      {cashChange.denominations.map(({ denomination, quantity, amount }: Denomination) => (
        <div key={`${denomination}_${quantity}_${amount}`} className="flex-table data-row" role="rowgroup">
          <div className="flex-table-row columns-3" role="cell">
            {denomination}
          </div>
          <div className="flex-table-row columns-3" role="cell">
            {quantity}
          </div>
          <div className="flex-table-row columns-3" role="cell">
            {currencyFormat(amount)}
          </div>
        </div>
      ))}
      <div className="flex-table data-row" role="rowgroup">
        <div className="flex-table-row columns-3" role="cell">
          <b>Total</b>
        </div>
        <div className="flex-table-row columns-3" role="cell"></div>
        <div className="flex-table-row columns-3" role="cell">
          <b>{currencyFormat(cashChange.total)}</b>
        </div>
      </div>
    </div>
  ) : (
    <div id="orderCashDropsEmptyWrapper">No changes to display</div>
  );
};

export default OrderCashDropsTable;
