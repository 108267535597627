import React from 'react';
import classnames from 'classnames';

import styles from './Stepper.module.scss';

export interface IStepHeader {
  name?: string;
  icon?: string; //IconProp;
  isActive?: boolean;
  isValid: boolean;
}

export type HeaderProps = {
  steps: IStepHeader[];
  onHeaderStepClick: (index: number) => void;
  mainStepper?: boolean;
};

const StepHeader: React.FC<HeaderProps> = ({ steps, onHeaderStepClick, mainStepper = false }: HeaderProps) => {
  return (
    <div className={classnames(styles.stepHeaderContainer, 'rounded')}>
      <ul>
        {steps.map(({ name, icon, isActive }: IStepHeader, index) => (
          <li
            key={index}
            onClick={(): void => onHeaderStepClick(index)}
            className={classnames(
              styles.stepHeaderItem,
              isActive ? styles.active : '',
              mainStepper ? styles.mainStepper : '',
            )}
          >
            {icon && (
              <i
                className={classnames(
                  'fas',
                  icon,
                  'fa-lg',
                  isActive ? styles.stepHeaderIconActive : styles.stepHeaderIcon,
                )}
              ></i>
            )}
            <span>{name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default StepHeader;
