/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect } from 'react';
import * as OrderStepperActions from 'actions/orderStepper';
import { OrderStepperActionsProps } from 'actions/types/orderStepper';
import { connect } from 'react-redux';
import { StateProps } from 'reducers';
import { OrderStepperStateProps } from 'reducers/orderStepper';
import { FileInfo, SelectedFileInfo } from 'types';
import { RadioButtonTypeBase, TextAreaField } from 'components/FormFields';
import { FileManager } from 'components/FileManager';
import { RadioButtonField } from 'components/FormFields/RadioButtonField';

interface Props extends OrderStepperStateProps, OrderStepperActionsProps {
  deliveryIndex: number;
  isValid: boolean;
  errors: any;
  attachmentFileInfo: SelectedFileInfo;
  setAttachmentFileInfo: (data: SelectedFileInfo) => void;
}

const AttachmentForm: React.FC<Props> = ({
  deliveryIndex,
  // State
  deliveries,
  attachmentFileInfo,
  // Props
  errors,
  // Actions
  onSetAttachment,
  setAttachmentFileInfo,
  onIsInvoiceRequiredChanged,
  onNoteChanged,
}: Props) => {
  const attachments = deliveries[deliveryIndex].attachments;
  const checkBoxFieldOptions: Array<RadioButtonTypeBase> = [
    {
      text: 'Yes',
      value: true,
    },
    {
      text: 'No',
      value: false,
    },
  ];

  useEffect(() => {
    if (attachmentFileInfo) {
      const updatedInvoiceFiles = attachmentFileInfo?.invoiceFile;
      const updatedOtherFiles = attachmentFileInfo?.otherFiles;
      const updatedAttachmentData = {
        isInvoiceRequired: attachments.isInvoiceRequired,
        note: attachments.note,
        ...(updatedInvoiceFiles ? { invoiceFile: updatedInvoiceFiles } : {}),
        ...(updatedOtherFiles ? { otherFiles: updatedOtherFiles } : {}),
      };
      onSetAttachment(deliveryIndex, updatedAttachmentData);
    }
  }, [attachmentFileInfo]);

  const setInvoiceFile = (newFileArray): any => {
    setAttachmentFileInfo({
      invoiceFile: newFileArray,
      otherFiles: attachmentFileInfo?.otherFiles,
    });
  };

  const setOtherFiles = (newFileArray): any => {
    setAttachmentFileInfo({
      otherFiles: newFileArray,
      invoiceFile: attachmentFileInfo?.invoiceFile,
    });
  };

  const getNewFileData = (file): FileInfo => ({
    fileName: file.name,
    fileUrl: '',
    filePath: '',
    file: file,
  });

  const onSelectedInvoiceFile = (selectedFile: File): any => {
    if (selectedFile) {
      const newFile = getNewFileData(selectedFile[0]);
      setInvoiceFile(newFile);
      const currentInvoiceFile = attachments.invoiceFile;
      if (!currentInvoiceFile || currentInvoiceFile.shouldBeRemoved) {
        onIsInvoiceRequiredChanged(deliveryIndex, true);
      }
    }
  };

  const onSelectedOtherFiles = (selectedFiles): any => {
    if (selectedFiles) {
      const updatedFiles = attachmentFileInfo?.otherFiles || [];

      Array.from(selectedFiles).forEach(file => {
        const newFile = getNewFileData(file);
        updatedFiles.push(newFile);
      });
      setOtherFiles(updatedFiles);
    }
  };

  const onRemoveInvoiceFile = (): void => {
    const currentInvoiceFile = attachmentFileInfo?.invoiceFile;
    if (currentInvoiceFile) {
      // If is a new file
      if (!currentInvoiceFile.fileUrl) {
        setInvoiceFile(null);
      } else {
        setInvoiceFile({
          ...currentInvoiceFile,
          shouldBeRemoved: true,
        });
      }
      if (attachments.invoiceFile) {
        onIsInvoiceRequiredChanged(deliveryIndex, false);
      }
    }
  };

  const onRemoveOtherFiles = (index: number): void => {
    const currentOtherFiles = attachmentFileInfo?.otherFiles;
    if (currentOtherFiles) {
      const fileListArr = Array.from(currentOtherFiles);
      const fileToDelete = fileListArr[index];
      // If is a new file
      if (!fileToDelete.fileUrl) {
        fileListArr.splice(index, 1);
      } else {
        fileListArr[index].shouldBeRemoved = true;
      }
      setOtherFiles(fileListArr);
    }
  };

  useEffect(() => {
    if (attachments) {
      const { isInvoiceRequired, invoiceFile: invoiceFileRef } = attachments;
      if (!isInvoiceRequired && invoiceFileRef) {
        onRemoveInvoiceFile();
      }
    }
  }, [attachments?.isInvoiceRequired]);

  return (
    <>
      <div className="row">
        <div className="col">
          <p className="tagline text-secondary">Do you want to include any file to support this order?</p>
        </div>
      </div>
      <form className="needs-validation" noValidate>
        <div className="row">
          <div className="col-12">
            <RadioButtonField
              id="isInvoiceRequired"
              label="Does this order have an invoice?"
              name="isInvoiceRequired"
              options={checkBoxFieldOptions}
              selected={attachments?.isInvoiceRequired}
              onChangeItem={(event: ChangeEvent<HTMLInputElement>): void => {
                const isInvoiceRequiredValue = event.currentTarget.value === 'true' ? true : false;
                onIsInvoiceRequiredChanged(deliveryIndex, isInvoiceRequiredValue);
              }}
            />
          </div>
          <div className="col-12">
            <div className="row">
              <div className="col">
                <FileManager
                  inputId={`invoice_${deliveryIndex}`}
                  title="Invoice"
                  isMultiple={false}
                  feedback={errors?.invoiceFile}
                  filesData={attachmentFileInfo?.invoiceFile ? [attachmentFileInfo?.invoiceFile] : null}
                  onSelectedFiles={onSelectedInvoiceFile}
                  onRemoveFile={onRemoveInvoiceFile}
                />
              </div>
              <div className="col">
                <FileManager
                  inputId={`other_files${deliveryIndex}`}
                  title="Other files"
                  filesData={attachmentFileInfo?.otherFiles}
                  onSelectedFiles={onSelectedOtherFiles}
                  onRemoveFile={onRemoveOtherFiles}
                  isMultiple={true}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mt-3">
            <TextAreaField
              id="note"
              name="note"
              label="Note"
              placeholder="Leave us a note if you have any special request or if you want to provide additional information"
              required={false}
              onChange={(e: ChangeEvent<HTMLInputElement>): void => onNoteChanged(deliveryIndex, e.target.value)}
              value={attachments?.note || ''}
              isValid={!errors?.note}
              feedback={errors?.note}
            />
          </div>
        </div>
      </form>
    </>
  );
};

const mapStateToProps = ({ orderStepper }: StateProps): any => ({
  deliveries: orderStepper.deliveries,
});

const mapDispatchToProps = { ...OrderStepperActions };

const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(AttachmentForm);

export default Connected;
