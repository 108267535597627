import React from 'react';
import { withAuthorization } from '../../components';

const InvoiceHistory: React.FC = () => {
  return (
    <div className="row">
      <div className="col">
        <h1>Transport Requests Today</h1>
      </div>
    </div>
  );
};

export default withAuthorization(InvoiceHistory);
