import React, { useMemo, useContext, useState, useEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import moment from 'moment';
import qs from 'query-string';
import cls from 'classnames';

import { AuthContext } from 'contexts';
import { useGetOrders } from 'services/hooks/orders';
import { Order, OrderRow, OrderDateFilterOptions } from 'types';
import { isSameDay, startOfWeek, withQueryString } from 'utils';
import * as routes from 'constants/routes';

import { withAuthorization, withPageView } from 'components';
import CustomDataTable from 'components/CustomDataTable';
import { OrderDateFilter, OrderStatusFilter } from 'components/Filters';

import { OptionType } from 'components/Select/Select';
import { Drawer } from 'components/Drawer';

import { columns } from './utilities';
import { OrderDetail } from './OrderDetail';

import { Spinner } from 'components/Loaders';

import style from './OrderHistory.module.scss';
import { CASH_DEPOSIT, PACKAGE, TRANSACTIONS_NAME } from 'constants/transactions';

const OrderHistory: React.FC<RouteComponentProps> = ({ location, history }: RouteComponentProps) => {
  const { currentUser } = useContext(AuthContext);
  const { orderList } = useGetOrders(currentUser?.uid);

  const [statusFilter, setStatusFilter] = useState<string>('');
  const [dateFilter, setDateFilter] = useState<string>(OrderDateFilterOptions.ALL);
  const [openOrderDetails, setOpenOrderDetails] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<Order>();

  useEffect(() => {
    if (location.search.length) {
      const filters: any = qs.parse(location.search);

      if (filters.createdDate) {
        setDateFilter(filters.createdDate);
      }

      if (filters.status) {
        setStatusFilter(filters.status);
      }
    }
  }, [location.search]);

  const getOrderType = (type = '', transactionType = ''): string => {
    if (!type && !transactionType) return TRANSACTIONS_NAME[PACKAGE];

    const orderType = TRANSACTIONS_NAME[type];
    if (!transactionType) return orderType;

    const orderTransactionType = TRANSACTIONS_NAME[transactionType];
    if (transactionType === CASH_DEPOSIT) {
      return orderTransactionType;
    }
    return orderTransactionType;
  };

  const parsedList = useMemo(() => {
    const parsedList: Array<OrderRow> = [];

    if (orderList && orderList.length) {
      let filteredOrders = orderList;

      if (statusFilter) {
        switch (statusFilter) {
          case '':
            break;
          default:
            filteredOrders = orderList.filter((order: Order) => order.status === statusFilter);
            break;
        }
      }

      if (dateFilter) {
        switch (dateFilter) {
          case OrderDateFilterOptions.TODAY:
            filteredOrders = filteredOrders.filter((order: Order) => isSameDay(order.createdAt, moment.utc().unix()));
            break;
          case OrderDateFilterOptions.THIS_WEEK:
            filteredOrders = filteredOrders.filter((order: Order) => order.createdAt >= startOfWeek().unix());
            break;
        }
      }

      filteredOrders.forEach((order: Order) => {
        const {
          id,
          orderNumber,
          status,
          driverInfo,
          originInfo,
          createdAt,
          recipientInfo,
          destinationInfo,
          type,
          transactionType,
        } = order;

        const driver = driverInfo ? `${driverInfo?.firstName ?? ''} ${driverInfo?.lastName ?? ''}` : '-';

        parsedList.push({
          id,
          orderNumber: orderNumber ?? '-',
          status,
          createdAt,
          driver,
          recipient: recipientInfo?.name ?? '-',
          origin: originInfo?.address.line1 || originInfo?.address.formatted || '-',
          destination: destinationInfo?.address.line1 || destinationInfo?.address.formatted || '-',
          orderType: getOrderType(type, transactionType),
        });
      });
    }

    return parsedList;
  }, [orderList, statusFilter, dateFilter]);

  const updateUrl = (param: string, value: string): void => {
    let queryParams = qs.parse(location.search);

    if (value === '') {
      delete queryParams[param];
    } else {
      queryParams = { ...queryParams, [param]: value };
    }

    const updatedRoute = withQueryString(routes.orders, queryParams);
    history.push(updatedRoute);
  };

  const onRowClicked = (rowData: OrderRow): void => {
    setOpenOrderDetails(true);

    const order = orderList.find((order: Order) => order.id === rowData.id);

    if (order) {
      setSelectedOrder(order);
    }
  };

  const onStatusChange = (option: OptionType): void => {
    const value = option ? option.value : undefined;
    setStatusFilter(value);
    updateUrl('status', value);
  };

  const onDateChange = (option: OptionType): void => {
    const value = option ? option.value : undefined;
    setDateFilter(value);
    updateUrl('createdDate', value);
  };

  if (!orderList) {
    return <Spinner message="Loading Orders ..." />;
  }

  return (
    <>
      <div className="row mb-4">
        <div className="col">
          <div className="d-flex justify-content-between flex-wrap">
            <h1 className="flex-fill">History</h1>
            <div
              className={cls(
                'pos-relative d-flex flex-fill flex-wrap justify-content-between justify-content-md-end',
                style.filterContainer,
              )}
            >
              <div className={style.filter}>
                <OrderDateFilter onChange={onDateChange} value={dateFilter} />
              </div>
              <div className={style.filter}>
                <OrderStatusFilter onChange={onStatusChange} value={statusFilter} />
              </div>
            </div>
          </div>
          <div className="mt-4 p-3 bg-white">
            {/* prettier-ignore */}
            <CustomDataTable
              columns={columns({ onRowClicked })}
              data={parsedList}
              onRowClicked={onRowClicked}
              pointerOnHover
            />
          </div>
        </div>
      </div>
      <Drawer
        open={openOrderDetails}
        title="Order Details"
        onClose={(): void => setOpenOrderDetails(false)}
        isAddressForm={false}
      >
        {selectedOrder && <OrderDetail {...selectedOrder} />}
      </Drawer>
    </>
  );
};

const Enhanced = compose(withAuthorization, withRouter, withPageView)(OrderHistory, 'Order History');
export default withRouter(Enhanced as React.ComponentType<any>);
