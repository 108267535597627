import { recaptcha } from 'config';
import { DurationInputArg1 } from 'moment';
import moment from 'moment';

declare let window: ExtendedWindow;
interface ExtendedWindow extends Window {
  captchaOnLoad: any;
  grecaptcha: any;
}

type RecaptchaLoginAttempt = {
  actionName: string;
  amount: DurationInputArg1;
  unit: string;
};

/**
 * @description: Validates the recaptcha challenge token. Returns the score got from the recaptcha validation API
 * @param {*} token: One-time use recaptcha token
 */
export const validateToken = async (token: string): Promise<any> => {
  try {
    const response: Response = await fetch(`${recaptcha.tokenEndpoint}?token=${token}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const jsonResult: Promise<any> = await response.json();
    return jsonResult;
  } catch (e) {
    return false;
  }
};

/**
 * @description: Creates a <script> element in the DOM referencing the Google recaptcha api url
 * @param {*} onLoad: action to point out/execute once the Google recaptcha script is ready
 */
export const loadScript = (onLoad: () => void): HTMLScriptElement => {
  window.captchaOnLoad = onLoad;

  const url = 'https://www.google.com/recaptcha/api.js';
  const queryString = '?onload=captchaOnLoad&render=explicit';
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.src = url + queryString;
  script.async = true;
  script.defer = true;

  return document.body.appendChild(script);
};

/**
 * @description: Render the recaptcha component in a DOM element container when the recaptcha script loaded
 * @param {*} callback: Function to execute once the recaptcha element rendered in the DOM
 */
export const createWidgetElement = (callback: () => void): HTMLDivElement => {
  const widget = document.createElement('div');
  widget.id = 'g-recaptcha';
  const element = document.body.appendChild(widget);

  window.grecaptcha.render('g-recaptcha', {
    sitekey: recaptcha.sitekey,
    size: 'invisible',
  });

  window.grecaptcha.ready(() => {
    callback();
  });

  return element;
};

/**
 * @description: Used to generate static variables used for moment.unitOfTime
 * @param {*} unit: time unit (seconds, minutes, hours, days)
 */
export const getUnits = (unit: string): any => {
  switch (unit) {
    case 'seconds':
      return 'seconds';
    case 'minutes':
      return 'minutes';
    case 'hours':
      return 'hours';
    case 'days':
      return 'days';
    default:
      return 'seconds';
  }
};

/**
 * @description: Add/update local storage item used to save failed action attempts
 * @param {*} actionName
 * @param {*} amount: Time the use has to wait to execute the same action again
 * @param {*} unit: seconds, minutes, hours, days, weeks, months, years
 */
export const logActionAttempt = ({ actionName, amount, unit }: RecaptchaLoginAttempt): void => {
  const attempsJson = localStorage.getItem(actionName);
  let count = 1;

  if (attempsJson) {
    const attempt = JSON.parse(attempsJson);
    count = attempt.count + 1;
  }

  const units: moment.unitOfTime.DurationConstructor = getUnits(unit);
  localStorage.setItem(
    actionName,
    JSON.stringify({
      count: count,
      timestamp: moment().add(amount, units),
    }),
  );
};

/**
 * @description: Wrapper to get an item from local storage
 * @param {*} actionName: name of the item in local storage
 */
export const getActionAttempt = (actionName: string): string | null => {
  return localStorage.getItem(actionName);
};

/**
 * @description: Wrapper to remove an item from local storage
 * @param {*} actionName: name of the item in local storage
 */
export const clearActionAttempt = (actionName: string): void => {
  localStorage.removeItem(actionName);
};
