import { firestore } from '_firebase';
import { getCustomerInformation, getCustomerDestinations } from './customers';
import { getDestination, getRecipientDestinations } from './destinations';
import { Destination, User, Recipient, MRALicense } from 'types';
import * as collections from 'constants/collections';
import {
  getCustomerLicenses,
  getFinancialInstitutionByOwner,
  getRecipientById,
  getRecipientsByOwner,
} from './recipients';

export const getCurrentUser = async (id: string): Promise<User | undefined> => {
  const doc = await firestore.collection(collections.USERS).doc(id).get();
  const userId = doc.id;
  const user = doc.data();

  if (user) {
    let recipientInfo: Recipient | undefined;
    let billingAddressInfo: Destination | undefined;
    let financialInstitution: Recipient | undefined;
    let destinations: Array<Destination> = [];
    let recipients: Array<Recipient> = [];
    let financialInstitutions: Array<Recipient> = [];
    const financialDestinations: Array<Destination> = [];
    let metrcLicenses: Array<MRALicense> = [];

    if (user.recipientId) {
      recipientInfo = await getCustomerInformation(user.recipientId);

      destinations = await getCustomerDestinations(user.recipientId);
      metrcLicenses = await getCustomerLicenses(user.recipientId);
      recipients = await getRecipientsByOwner(userId);
      financialInstitutions = await getFinancialInstitutionByOwner(userId);
      recipientInfo && recipients.unshift(recipientInfo);

      if (recipientInfo?.financialInstitutionId) {
        financialInstitution = await getRecipientById(recipientInfo?.financialInstitutionId);

        if (financialInstitution) {
          const destinationRef = await firestore
            .collection(collections.DESTINATIONS)
            .where('owner', '==', recipientInfo?.financialInstitutionId)
            .get();

          if (!destinationRef.empty) {
            const destinationData = destinationRef.docs[0].data() as Destination;

            const destination: Destination = {
              id: destinationRef.docs[0].id,
              name: destinationData.name,
              active: destinationData.active,
              address: destinationData.address,
              location: destinationData.location,
              owner: destinationData.owner,
              photoUrl: destinationData.photoUrl,
            };

            financialInstitution.destinationInfo = destination;
          }
        }
      }
    }

    for (const recipient of financialInstitutions) {
      const destinations = await getRecipientDestinations(recipient.id);
      financialDestinations.push(...destinations);
    }

    if (recipientInfo?.billingAddressId) {
      billingAddressInfo = await getDestination(recipientInfo?.billingAddressId);
    }

    return {
      uid: id,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      role: user.role,
      avatar: user.avatarUrl,
      recipientId: user.recipientId,
      disabled: user.disabled,
      recipientInfo,
      billingAddressInfo,
      destinations,
      recipients,
      financialInstitution,
      financialInstitutions,
      financialDestinations,
      metrcLicenses,
    };
  }

  return undefined;
};
