import React from 'react';
import style from './CustomCell.module.scss';

type Props = {
  content: any;
  title?: string;
  onClick?: (data: any) => void;
};

const CustomCell: React.FC<Props> = ({ title, content, onClick }: Props) => {
  return (
    <div title={title} className={style.wrapper} onClick={onClick}>
      {content}
    </div>
  );
};

CustomCell.defaultProps = {
  title: '',
};

export default CustomCell;
