const firebase = {
  apiKey: 'AIzaSyBdLvEQns1s6u5EXaKajqfBQF1iDVtkNlc',
  authDomain: 'boltbox-admin.firebaseapp.com',
  databaseURL: 'https://boltbox-admin.firebaseio.com',
  projectId: 'boltbox-admin',
  storageBucket: 'boltbox-admin.appspot.com',
  messagingSenderId: '999650919261',
};

const emailEndpoint = {
  validCustomerEmailEndpoint: 'https://us-central1-boltbox-admin.cloudfunctions.net/isValidCustomerEmail',
};

const recaptcha = {
  sitekey: '6LepjvwUAAAAAP6jYnzmkAv4h4OVQeZKQuOMf5Vw',
  tokenEndpoint: 'https://us-central1-boltbox-admin.cloudfunctions.net/checkRecaptcha',
};

const login = {
  resetTime: {
    amount: 30,
    unit: 'seconds',
  },
};

const mapbox = {
  accessToken: 'pk.eyJ1IjoiYm9sdGJveGFwcCIsImEiOiJja3lqbW5hOXQybzlmMzFwbWRhbDBhN3g5In0.X-ZAySsSseATgu2OOkCGrQ',
};

const sentry = {
  dsn: 'https://4d71c4f06d534135b89aada7dad9aa3d@o234632.ingest.sentry.io/5412756',
};

const api = {
  searchPlaces: 'https://us-central1-boltbox-admin.cloudfunctions.net/searchPlace',
  geocode: 'https://us-central1-boltbox-admin.cloudfunctions.net/geocode',
  metrc: '',
  getOrderReportUrl: 'https://us-central1-boltbox-admin.cloudfunctions.net/getOrderReportUrl',
  getCustomerStats: 'https://us-central1-boltbox-admin.cloudfunctions.net/getCustomerStats',
};

const google = {
  gaTrackingID: 'G-L83D6EBEY3',
};

export { firebase, recaptcha, login, mapbox, sentry, emailEndpoint, api, google };
