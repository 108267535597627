import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import AuthLayout from 'components/Layout/AuthLayout';
import { withAlert, withNoAuthorization, withPageView } from 'components';
import { ResetPasswordForm } from './components';
import { resetPassword } from 'services/auth';
import { validCustomerEmail } from 'services/recipients';
import { ResetPasswordData } from 'types/auth';
import Alert from 'types/alert';

interface Props extends Alert, RouteComponentProps {}

const ResetPasswordPage: React.FC<Props> = ({ success, error }: Props) => {
  const onSubmit = async (resetPasswordData: ResetPasswordData): Promise<void> => {
    const { email } = resetPasswordData;
    const response = await validCustomerEmail(email);

    if (response && !response.isCustomer) {
      error('Invalid email address');
      return;
    }

    resetPassword({ email }).finally(() => {
      success('Password reset email sent successfully');
    });
  };

  return (
    <AuthLayout title="Provide an email to send you a link to reset your password">
      <ResetPasswordForm onSubmit={onSubmit} />
    </AuthLayout>
  );
};

const Enhanced = compose(withNoAuthorization, withAlert, withPageView)(ResetPasswordPage, 'Reset Password');
export default withRouter(Enhanced as React.ComponentType<any>);
