/* eslint-disable react-hooks/exhaustive-deps */
import React, { ChangeEvent, useEffect } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { StateProps } from 'reducers';
import { OrderStepperStateProps } from 'reducers/orderStepper';
import * as OrderStepperActions from 'actions/orderStepper';
import { OrderStepperActionsProps } from 'actions/types/orderStepper';
import { TextField } from 'components/FormFields';
import { getFromLocalStorage } from 'utils';

interface Props extends OrderStepperStateProps, OrderStepperActionsProps {
  isValid: boolean;
  errors: any;
}

const GuestContactForm: React.FC<Props> = ({
  contact,
  isValid,
  errors,
  onGuestNameChanged,
  onGuestEmailChanged,
  onGuestPhoneChanged,
}: Props) => {
  const { name, email, phone } = contact;

  useEffect(() => {
    if (isEmpty(name)) {
      const persistedName = getFromLocalStorage('guestName');
      onGuestNameChanged(persistedName);
    }

    if (isEmpty(email)) {
      const persistedEmail = getFromLocalStorage('guestEmail');
      onGuestEmailChanged(persistedEmail);
    }

    if (isEmpty(phone)) {
      const persistedPhone = getFromLocalStorage('guestPhone');
      onGuestPhoneChanged(persistedPhone);
    }
  }, []);

  return (
    <>
      <div className="row">
        <div className="col-xs-12 col-md-6">
          <div className="row">
            <div className="col-12">
              <h2>Contact Info</h2>
              <p className="tagline text-secondary">So we can contact you.</p>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <form className="needs-validation" noValidate>
                <div className="row">
                  <div className="col-12">
                    {!isValid && !isEmpty(errors) && (
                      <div className="alert alert-primary" role="alert">
                        Check the following errors before continue
                      </div>
                    )}
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      id="guestName"
                      name="guestName"
                      type="text"
                      label="Name"
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => onGuestNameChanged(e.target.value)}
                      value={name}
                      isValid={!errors?.name}
                      feedback={errors?.name}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      id="guestEmail"
                      name="guestEmail"
                      type="email"
                      label="Email"
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => onGuestEmailChanged(e.target.value)}
                      value={email}
                      isValid={!errors?.email}
                      feedback={errors?.email}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <TextField
                      id="guestPhone"
                      name="guestPhone"
                      type="text"
                      label="Phone"
                      onChange={(e: ChangeEvent<HTMLInputElement>): void => onGuestPhoneChanged(e.target.value)}
                      value={phone}
                      isValid={!errors?.phone}
                      feedback={errors?.phone}
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ orderStepper: { contact } }: StateProps): any => ({
  contact,
});

const mapDispatchToProps = { ...OrderStepperActions };

const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(GuestContactForm);

export default Connected;
