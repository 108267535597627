import { Settings } from 'types/settings';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const mapper = (data: any): Settings | null => {
  let settings: Settings | null = null;

  data.forEach((doc: any) => {
    const documentData = doc.data();

    settings = {
      companyAddress: documentData.companyAddress,
      companyEmail: documentData.companyEmail,
      companyName: documentData.companyName,
      fuelSurcharge: documentData.fuelSurcharge,
      hourStatus: documentData.hourStatus,
      phoneNumber: documentData.phoneNumber,
      supportEmail: documentData.supportEmail,
      taxPercentage: documentData.taxPercentage,
    };
  });

  return settings;
};

export default mapper;
