import { OptionType } from 'components/Select';
import { Destination, Order, Recipient } from './index';
import { deliveryInfoProp, OrderStepperDeliveryStateProps, productListProp } from 'reducers/orderStepper';
import { AttachmentInfo } from './attachmentInfo';
import { SelectedFileInfo } from './uploadedFileModel';

export enum OrderSteps {
  CONTACT = 'contactStep',
  TRANSACTION = 'transactionStep',
  CASH_CHANGE = 'cashChangeStep',
  PICKUP = 'pickupStep',
  DELIVERIES = 'deliveriesStep',
  DELIVERY = 'deliveryStep',
  PRODUCTS = 'productsStep',
  ATTACHMENT = 'attachmentStep',
}

export type OrderContextType = {
  isGuestView: boolean;
  order: Order | null;
  recipients: Array<Recipient>;
  deliveriesStep: Array<any>;
  deliveries: Array<OrderStepperDeliveryStateProps>;
  destinations: Array<Destination>;
  stepperState: any;
  destinationsOptions: (showNew?: boolean) => Array<OptionType>;
  validateProductForm: (deliveryIndex: number, delivery: productListProp) => boolean;
  metrcReferenceNumberExists: (productList: productListProp, metrcReferenceNumber: string) => boolean;
  isProductDuplicated: (productList: productListProp, metrcReferenceNumber: string) => boolean;
  onBeforeSearchProduct?: (
    deliveryIndex: number,
    productList: productListProp,
    productIndex: number,
    metrcReferenceNumber: string,
  ) => boolean;
  validateStep: (step: OrderSteps, errorList: any, deliveryIndex?: number) => boolean;
  validateDeliveryStep: (
    deliveryIndex: number,
    delivery: deliveryInfoProp,
    attachments: AttachmentInfo,
    attachmentFileInfo?: SelectedFileInfo,
  ) => boolean;
  setStepperState: (data: any) => void;
  handleAddNewDelivery: () => void;
  handleDeleteDelivery: (index: number) => void;
};
