import React, { useEffect, useState, useContext, useCallback, useMemo } from 'react';
import { debounce } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import AsyncSelect from 'react-select/async';
import { OptionType } from 'components/Select';
import { SelectFieldProps } from 'components/FormFields';
import { useApi } from 'services/hooks';
import { AuthContext } from 'contexts';
import { api } from 'config';
import { Destination as DestinationType } from 'types';

const customStyles = {
  container: (provided: any): any => ({
    ...provided,
    border: '1px solid #474b4f',
    borderRadius: 5,
  }),
  valueContainer: (): any => ({
    padding: '4px 8px',
    color: '#101010',
  }),
  singleValue: (provided: any): any => ({
    ...provided,
    paddingRight: 30,
    maxWidth: 'calc(100% - 50px)', // Adjust based on the size of your clear button
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  }),
  clearIndicator: (provided: any): any => ({
    ...provided,
    zIndex: 3,
  }),
};

type OptionsArray = Array<OptionType>;

export const getLine1FromAddress = (address: string): string => address.split(',').slice(0, -2).join(',');

const Destination: React.FC<SelectFieldProps> = ({
  id,
  onChange: parentOnChange,
  placeholder,
  autoComplete = false,
  className,
  defaultValue,
  clearable = false,
}: SelectFieldProps) => {
  const { get } = useApi();
  const { currentUser } = useContext(AuthContext);

  const [selectedValue, setSelectedValue] = useState<OptionType>();

  const searchPlace = async (inputState: string): Promise<OptionsArray | undefined> => {
    if (inputState) {
      const { predictions } = await get(api.searchPlaces, {
        input: inputState,
        sessiontoken: uuidv4(),
      });

      if (predictions && predictions.length) {
        const tmpAjaxOptions: OptionsArray = [];

        predictions.forEach((prediction: any) => {
          tmpAjaxOptions.push({
            value: prediction.place_id,
            label: prediction.description,
          });
        });

        return tmpAjaxOptions;
      }
    }
  };

  useEffect(() => {
    const geocodeAddress = async (address: string): Promise<any> => {
      if (address) {
        const { results } = await get(api.geocode, { address });
        const [result] = results;

        if (result) {
          const addressComponents = result.address_components;
          const { lat, lng: lon } = result.geometry.location;
          const formatted = result.formatted_address;
          const googlePlaceId = result.place_id;
          const postalCode = addressComponents.find((c: any) => c.types.indexOf('postal_code') !== -1);
          const state = addressComponents.find((c: any) => c.types.indexOf('administrative_area_level_1') !== -1);
          const country = addressComponents.find((c: any) => c.types.indexOf('country') !== -1);

          const line1 = getLine1FromAddress(formatted);

          const destination: DestinationType = {
            active: true,
            name: selectedValue?.label || '',
            location: { lat, lon },
            address: {
              formatted,
              line1,
              state: state && state.short_name,
              country: country && country.short_name,
              postalCode: (postalCode && postalCode.short_name) || '',
            },
            extra: { googlePlaceId },
            owner: currentUser?.uid || '',
          };

          parentOnChange(destination);
        }
      }
    };

    if (selectedValue) {
      geocodeAddress(selectedValue.label);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue, currentUser]);

  const getOptions = useCallback(
    debounce((inputValue: any, callback: any) => {
      searchPlace(inputValue).then(options => callback(options));
    }, 300),
    [],
  );

  const prevSelectedValue = useMemo(() => {
    if (defaultValue && defaultValue.value) {
      return defaultValue;
    }

    return null;
  }, [defaultValue]);

  return (
    <AsyncSelect
      id={id}
      className={className}
      cacheOptions
      styles={customStyles}
      loadOptions={getOptions}
      onChange={(value: any): void => setSelectedValue(value)}
      placeholder={placeholder}
      noOptionsMessage={(): any => null}
      onFocus={(e: any): void => {
        if (e.target.autocomplete) {
          e.target.autocomplete = autoComplete ? true : 'nope';
        }
      }}
      value={selectedValue ?? prevSelectedValue}
      isClearable={clearable}
    />
  );
};

export default Destination;
