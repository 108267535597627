import { api } from 'config';
import { MetrcPackage } from 'types/metrcPackage';

const { metrc: metrcApi } = api;

/**
 * @param {*} tag: string
 */
export const getMetrcPackageByTag = async (tag: string): Promise<MetrcPackage | null> => {
  try {
    const response: Response = await fetch(`${metrcApi}/packages/tag/${tag}`, {
      method: 'GET',
      cache: 'default',
      headers: { 'Content-Type': 'application/json' },
      mode: 'cors',
    });

    const metrcPackage = (await response.json()) as MetrcPackage;
    return metrcPackage;
  } catch (e) {
    return null;
  }
};
