import React from 'react';
import classnames from 'classnames';

import AppNavLink, { NavLinkProps } from './AppNavLink';
import AppNavLinkDropdown, { DropdownNavLink } from './AppNavLinkDropdown';
import AppLogo, { AppLogoProps } from './AppLogo';

import siteLogo from 'assets/images/jklogix_logo.png';
import siteLogo2x from 'assets/images/jklogix_logo@2x.png';

import styles from './AppHeader.module.scss';

const logo: AppLogoProps = {
  redirectUrl: '/',
  src: siteLogo,
  srcSet: `${siteLogo} 1x, ${siteLogo2x} 2x`,
  alt: 'JK Logix Logo',
  width: '145px',
  heigth: '35px',
};

export type AppHeaderProps = {
  links?: NavLinkProps[];
  dropdownLinks?: DropdownNavLink;
};

const AppHeader: React.FC<AppHeaderProps> = React.memo(({ links, dropdownLinks }: AppHeaderProps) => {
  return (
    <header className={classnames(styles.appHeader)} id="main-navigation" data-test-id="main-navigation">
      <div className="container">
        <nav
          className={classnames('navbar navbar-expand-lg navbar-expand-md navbar-dark p-0', styles.appNavbar)}
          id="main-navitation__menu"
          data-test-id="main-navitation__menu"
        >
          <AppLogo
            redirectUrl={logo.redirectUrl}
            src={logo.src}
            srcSet={logo.srcSet}
            alt={logo.alt}
            heigth={logo.heigth}
            width={logo.width}
          />
          <button
            id="main-navigation__toggle"
            data-test-id="main-navigation__toggle"
            className="navbar-toggler navbar-toggler-right"
            type="button"
            data-toggle="collapse"
            data-target="#main-navigation__content"
            aria-controls="main-navigation__content"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="collapse navbar-collapse" id="main-navigation__content">
            <ul className="navbar-nav ml-md-auto">
              {links
                ? links.map((link: NavLinkProps) =>
                    link.dropdown ? (
                      <AppNavLinkDropdown
                        id="main-navigation-item__profile"
                        label={link.label}
                        options={link.options ?? []}
                        key={link.label}
                      />
                    ) : (
                      <AppNavLink
                        id={`main-navigation__item${link.label.replace(/\s/g, '')}`}
                        label={link.label}
                        route={link.route}
                        key={link.label}
                      />
                    ),
                  )
                : null}
              {dropdownLinks && (
                <AppNavLinkDropdown
                  id="main-navigation-item__profile"
                  label={dropdownLinks.label}
                  options={dropdownLinks.options}
                />
              )}
            </ul>
          </div>
        </nav>
      </div>
    </header>
  );
});

export default AppHeader;
