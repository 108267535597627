import useApi from 'services/hooks/useApi';
import { getLine1FromAddress } from 'components/Destination/Destination';
import { api } from 'config';
import { Destination } from 'types';
import { useAuth } from 'contexts/auth';

export const useGeocodeAddress = (): any => {
  const { get } = useApi();
  const { currentUser } = useAuth();

  const geocodeAddress = async (address: string): Promise<any> => {
    if (address) {
      const { results } = await get(api.geocode, { address });
      const [result] = results;

      if (result) {
        const addressComponents = result.address_components;
        const { lat, lng: lon } = result.geometry.location;
        const formatted = result.formatted_address;
        const googlePlaceId = result.place_id;
        const postalCode = addressComponents.find((c: any) => c.types.indexOf('postal_code') !== -1);
        const state = addressComponents.find((c: any) => c.types.indexOf('administrative_area_level_1') !== -1);
        const country = addressComponents.find((c: any) => c.types.indexOf('country') !== -1);

        const line1 = getLine1FromAddress(formatted);

        return {
          active: true,
          name: formatted || '',
          location: { lat, lon },
          address: {
            formatted,
            line1,
            state: state && state.short_name,
            country: country && country.short_name,
            postalCode: (postalCode && postalCode.short_name) || '',
          },
          extra: { googlePlaceId },
          owner: currentUser?.uid || '',
        } as Destination;
      }
    }
  };

  return { geocodeAddress } as const;
};
