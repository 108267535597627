import { User } from './user';

export type SignInData = {
  email: string;
  password: string;
  keepMeSignedIn?: boolean;
};

export enum Status {
  Pending = 0,
  Approved = 1,
  Rejected = 2,
}

export type SignUpData = {
  firstName: string;
  lastName: string;
  displayName?: string;
  email: string;
  phoneNumber: string;
  licenseNumber: string;
  status: Status;
  createdAt?: number;
  updatedAt?: number;
};

export type ResetPasswordData = {
  email: string;
};

export enum PersitenceMode {
  local = 'LOCAL',
  none = 'NONE',
  session = 'SESSION',
}

export interface CurrentUser extends User {
  displayName?: string;
  emailVerified?: boolean;
}

export type AuthContext = {
  currentUser?: CurrentUser | null;
};
