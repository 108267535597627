import React from 'react';
import cls from 'classnames';
import { OrderStatus } from 'types';
import { ORDER_STATUS_LABELS } from 'constants/order';

import style from './StatusBar.module.scss';
import { getCurrentOrderStatus } from 'utils/getCurrentOrderStatus';

export const getStatuses = (): OrderStatus[] => [
  OrderStatus.DRAFT,
  OrderStatus.CREATED,
  OrderStatus.SCHEDULED,
  OrderStatus.PROGRESS,
  OrderStatus.COMPLETED,
];

export const getOrderStatus = (statuses: Array<OrderStatus>, orderStatus: OrderStatus): OrderStatus =>
  statuses.find(s => s === orderStatus) || statuses[0];

type Props = {
  status: OrderStatus;
  isRequest: boolean;
};

const StatusBar: React.FC<any> = ({ status, isRequest }: Props) => {
  const orderStatus = getCurrentOrderStatus(status, isRequest);
  const statuses = getStatuses();
  const currentOrderStatus = getOrderStatus(statuses, orderStatus);

  return (
    <ul className={style.statusBar}>
      {statuses.map((status, index) => (
        <li
          key={index}
          className={
            currentOrderStatus === status && currentOrderStatus !== OrderStatus.COMPLETED ? style.activeStatus : ''
          }
        >
          <i className={cls(style.completedStatusIcon, 'fas fa-check fa-xs')}></i>
          {ORDER_STATUS_LABELS[status]}
        </li>
      ))}
    </ul>
  );
};

export default StatusBar;
