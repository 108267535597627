import React from 'react';
import { default as BaseSelect } from 'react-select';
import { SelectFieldProps } from 'components/FormFields';

export type OptionType = {
  value: any;
  label: string;
};

const customStyles = {
  container: (provided: any): any => ({
    ...provided,
    border: '1px solid #474b4f',
    borderRadius: 5,
  }),
  valueContainer: (): any => ({
    padding: '4px 8px',
    color: '#101010',
  }),
  singleValue: (provided: any): any => ({
    ...provided,
    paddingRight: 30,
  }),
};

const Select: React.FC<SelectFieldProps> = ({
  className,
  onChange,
  onInputChange,
  onBlur,
  options,
  placeholder,
  value,
  clearable,
  noOptionMessage,
}: SelectFieldProps) => {
  return (
    <BaseSelect
      className={className}
      isClearable={clearable}
      styles={customStyles}
      options={options}
      placeholder={placeholder}
      onChange={onChange}
      onInputChange={onInputChange}
      onBlur={onBlur}
      value={options.find(option => option.value === value)}
      components={{
        IndicatorSeparator: (): any => null,
      }}
      inputProps={{ autoComplete: 'off', autoFill: 'off' }}
      noOptionsMessage={(): any => noOptionMessage}
    />
  );
};

Select.defaultProps = {
  noOptionMessage: 'No options',
};

export default Select;
