import React, { PropsWithChildren } from 'react';
import cls from 'classnames';
import style from './Drawer.module.scss';

interface Props extends PropsWithChildren<any> {
  open: boolean;
  title: string;
  isAddressForm: boolean;
  onClose: () => void;
}

const Drawer: React.FC<Props> = ({ open, title, onClose, children, isAddressForm }: Props) => {
  return (
    <div
      className={cls(isAddressForm && style.wrapperAddressForm, style.wrapper, { [style.open]: open })}
      data-id="orderDetail"
    >
      <div className={cls(style.header, 'd-flex align-items-center')}>
        <div className="d-flex align-items-center horizontal-drawer-padding">
          <div className={cls(style.back, 'mr-5')} onClick={onClose}>
            <i className="fas fa-arrow-left"></i>
          </div>
          <div className={style.title}>{title}</div>
        </div>
      </div>
      <div className={cls(style.content)}>{children}</div>
    </div>
  );
};

export default Drawer;
