import React from 'react';
import { NavLink } from 'react-router-dom';
import cls from 'classnames';

import style from './ActionCard.module.scss';
import { Spinner } from 'components/Loaders';

type CardProps = {
  title: string;
  counter?: number;
  route: string;
  selector: string;
  customStyle?: string;
  isLoading?: boolean;
};

const ActionCard: React.FC<CardProps> = ({ title, counter, route, selector, customStyle, isLoading }: CardProps) => {
  return (
    <div
      className={cls('d-flex flex-column justify-content-between align-items-center', style.card, customStyle)}
      data-id={selector}
    >
      <div className="header d-flex flex-column align-items-center">
        <div className={style.headerTitle}>{title}</div>
        <div className={style.separator}></div>
      </div>
      <div className={cls('d-flex justify-content-center align-items-center', style.content)}>
        {isLoading ? <Spinner inheritColor /> : <span className="counter">{counter || 0}</span>}
      </div>
      <div className={style.footer}>
        <NavLink to={route}>
          <div className={style.action_icon}>
            <i className="fas fa-chevron-down"></i>
          </div>
        </NavLink>
      </div>
    </div>
  );
};

export default ActionCard;
