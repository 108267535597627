import React from 'react';

export type PreviousButtonProps = {
  isActive: boolean;
  onClick: () => void;
};

const PreviousButton: React.FC<PreviousButtonProps> = ({ isActive, onClick }: PreviousButtonProps) => {
  if (isActive === false) return null;
  return (
    <button className="btn btn-transparent text-uppercase mr-auto stepper-button" onClick={onClick}>
      <i className="fas fa-arrow-left mr-2"></i>
      Back
    </button>
  );
};

export default PreviousButton;
