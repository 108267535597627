import { useState } from 'react';
import { auth } from '_firebase';
import { withQueryString } from 'utils';

const useApi = (): any => {
  const [isLoading, setIsLoading] = useState<boolean>();

  const fetchHeaders = async (): Promise<HeadersInit> => {
    const token = await auth.currentUser?.getIdToken();
    return {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    };
  };

  const fetchData = async (url: string, method: string, data: any): Promise<void | false> => {
    setIsLoading(true);

    try {
      url = withQueryString(url, data);
      const headers: HeadersInit = await fetchHeaders();
      const options: RequestInit = { method, headers };

      if (method === 'POST') {
        options.body = JSON.stringify(data);
      }

      const response: Response = await fetch(url, options);
      return await response.json();
    } catch (e) {
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const get = (url: string, data: any): Promise<void | false> => fetchData(url, 'GET', data);
  const post = (url: string, data: any): Promise<void | false> => fetchData(url, 'POST', data);

  return { get, post, isLoading };
};

export default useApi;
