import { combineReducers } from 'redux';
import orderStepper, { OrderStepperStateProps } from './orderStepper';

import { reducer as global } from './global';

export type StateProps = {
  orderStepper: OrderStepperStateProps;
};

const rootReducer = combineReducers<StateProps | any>({
  orderStepper,
  global,
});

export default rootReducer;
