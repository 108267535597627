import { isObject, isEmpty } from 'lodash';
import qs from 'query-string';
import * as routes from 'constants/routes';

const defultOrderPath = (id: string): string => `${routes.orders}/${id}`;
export const editOrderPath = (id: string): string => `${defultOrderPath(id)}/edit`;
export const viewOrderPath = (id: string): string => defultOrderPath(id);

export const withQueryString = (basePath: string, queryParams: unknown): string => {
  if (isObject(queryParams) && !isEmpty(queryParams)) {
    return `${basePath}?${qs.stringify(queryParams)}`;
  }

  return basePath;
};
