import React from 'react';
import cls from 'classnames';

import { TextAreaFieldProps } from '../types';

import style from './TextAreaField.module.scss';

const TextAreaField: React.FC<TextAreaFieldProps> = ({
  id,
  name,
  label,
  inputClassName,
  placeholder,
  value,
  required,
  isValid,
  feedback,
  onChange,
  disabled,
  rows,
}: TextAreaFieldProps) => {
  const Feedback = (): React.ReactNode => {
    if (feedback) {
      return <span className={cls({ 'valid-feedback': isValid, 'invalid-feedback': !isValid })}>{feedback}</span>;
    }

    return null;
  };

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <textarea
        id={id}
        className={cls('form-control', style.input, inputClassName, {
          'is-invalid': isValid !== undefined && !isValid,
        })}
        placeholder={placeholder}
        value={value}
        required={required}
        onChange={onChange}
        disabled={!!disabled}
        rows={rows || 3}
      ></textarea>
      {Feedback()}
    </>
  );
};

export default TextAreaField;
