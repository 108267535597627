import React from 'react';
import { Collapse } from 'components/Collapse';
import DeliveriesContent from './DeliveriesContent';
import useOrderContext from 'contexts/orderContext';
import { OrderSteps } from 'types/orderContext';

const DeliveriesStep: React.FC<any> = () => {
  const { deliveries, handleAddNewDelivery, handleDeleteDelivery, stepperState } = useOrderContext();
  const deliveriesSteps = stepperState[OrderSteps.DELIVERIES];
  const invalidSteps = deliveriesSteps
    .map((delivery, index) => {
      const deliveryStep = delivery[OrderSteps.DELIVERY];
      if (Object.keys(deliveryStep.errors).length && !deliveryStep.isValid) {
        return index;
      }

      return undefined;
    })
    .filter(index => index !== undefined);

  return (
    <div id="deliveriesContent">
      <div id="collapsibleContent">
        <Collapse
          id="deliveries"
          title="Delivery Location"
          data={deliveries}
          invalidSteps={invalidSteps}
          onRemoveItem={handleDeleteDelivery}
          component={DeliveriesContent}
        />
      </div>
      <div id="addDeliveryBtnContent">
        <div className="text-center pt-3">
          <button
            className="btn btn-transparent text-uppercase ml-auto add-delivery"
            disabled={false}
            onClick={handleAddNewDelivery}
          >
            <i className="fas fa-plus mr-2"></i>
            Add Another Location
          </button>
        </div>
      </div>
    </div>
  );
};

export default DeliveriesStep;
