export const login = '/login';
export const dashboard = '/';
export const orderHistory = '/orders';
export const cashBalance = '/cash-balance';
export const invoiceHistory = '/invoiceHistory';
export const account = '/account';
export const addressBook = '/addressBook';
export const resetPassword = '/resetPassword';
export const resetPasswordProfile = '/resetPasswordProfile';
export const bankingInformation = '/bankingInformation';
export const notFound = '/404';

export const orders = '/orders';
export const viewOrder = `${orders}/:id`;
export const editOrder = `${orders}/:id/edit`;
export const newOrder = `${orders}/new`;
export const newOrderCash = `${orders}/new?type=cash`;
export const newOrderGuest = `/guest/order/new`; // it uses guest/order route because router gets confused with /orders/:id URL
export const signUp = `/signUp`;
