import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './AccountForm.module.scss';
import { withAlert, withAuthorization, withPageView } from 'components';
import { AuthContext } from 'contexts';
import { getCustomerInformation, updateCustomerInformation, getCustomerDestinations } from 'services/customers';
import { Customer } from 'types/customer';
import { Destination } from 'types';
import { notificationTypes as notifications } from 'utils';
import Alert from 'types/alert';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { Spinner } from 'components/Loaders';

interface Props extends Alert, RouteComponentProps {}

const AccountForm: React.FC<Props> = ({ success }: Props) => {
  const [customer, setCustomer] = useState<Customer | null>();
  const [destinations, setDestinations] = useState<Array<Destination> | null>();
  const { currentUser } = useContext(AuthContext);
  const [isSaving, setIsSaving] = useState(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    setIsSaving(true);

    if (currentUser?.recipientId && customer) {
      updateCustomerInformation(customer, currentUser?.recipientId).then(r => success('Account updated'));
    }

    setIsSaving(false);
  };

  useEffect(() => {
    const getCustomer = async (): Promise<void> => {
      if (currentUser?.recipientId) {
        const customer = await getCustomerInformation(currentUser?.recipientId);
        setCustomer(customer);
      }
    };

    const getDestinations = async (): Promise<void> => {
      if (currentUser?.recipientId) {
        const destinations = await getCustomerDestinations(currentUser?.recipientId);
        setDestinations(destinations);
      }
    };

    getCustomer();
    getDestinations();
  }, [currentUser]);

  return (
    <>
      {isSaving && <Spinner message="Saving..." overScren={true} />}
      <form id="account-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className={styles.label}> NAME </label>
          <input
            id="name"
            type="text"
            value={customer?.name || ''}
            placeholder="Name"
            aria-describedby="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setCustomer({ ...customer, name: e.target.value });
            }}
            className={classnames('form-control', styles.formInput)}
            required
          />
        </div>
        <div className="form-group">
          <label className={styles.label}> PHONE </label>
          <input
            id="phone"
            type="text"
            value={customer?.phone || ''}
            placeholder="Phone"
            aria-describedby="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setCustomer({ ...customer, phone: e.target.value });
            }}
            className={classnames('form-control', styles.formInput)}
            required
          />
        </div>
        <div className="form-group">
          <label className={styles.label}> MRA LICENSE # </label>
          <input
            id="mraLicense"
            type="text"
            placeholder="Mra Licence #"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setCustomer({ ...customer, metrcLicenseNumber: e.target.value });
            }}
            value={customer?.metrcLicenseNumber || ''}
            aria-describedby="text"
            className={classnames('form-control', styles.formInput)}
            required
          />
        </div>
        <div className="form-group">
          <label className={styles.label}> EMAIL </label>
          <input
            id="email"
            type="email"
            value={customer?.email || ''}
            placeholder="Email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              setCustomer({ ...customer, email: e.target.value });
            }}
            aria-describedby="email"
            className={classnames('form-control', styles.formInput)}
            required
          />
        </div>
        <div className="form-group">
          <label className={styles.label}> BILLING ADDRESS </label>
          <select
            id="billingAddress"
            className={classnames('form-control', styles.formInput)}
            value={customer?.billingAddressId}
            onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
              setCustomer({ ...customer, billingAddressId: e.target.value });
            }}
          >
            {destinations?.map(item => {
              return (
                <option key={item.id} value={item.id}>
                  {item.address.formatted}
                </option>
              );
            })}
          </select>
        </div>
        <div className="row">
          <div className="col-md-3 col-sm-4">
            <label className={styles.label}> EMAIL NOTIFICATIONS </label>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="emailSwitch"
                checked={customer?.emailNotification || false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setCustomer({ ...customer, emailNotification: e.target.checked });
                }}
              />
              <label className="custom-control-label" htmlFor="emailSwitch"></label>
            </div>
          </div>
          <div className="col-md-3 col-sm-4">
            <label className={styles.label}> SMS NOTIFICATIONS </label>
            <div className="custom-control custom-switch">
              <input
                type="checkbox"
                className="custom-control-input"
                id="smsSwitch"
                checked={customer?.smsNotification || false}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                  setCustomer({ ...customer, smsNotification: e.target.checked });
                }}
              />
              <label className="custom-control-label" htmlFor="smsSwitch"></label>
            </div>
          </div>
        </div>
        {(customer?.smsNotification || customer?.emailNotification) && (
          <div className="row mt-2">
            <div className="col-md-3 col-sm-6">
              <label className={classnames(styles.label)}> NOTIFICATION TYPES </label>
              {notifications?.map(item => {
                return (
                  <div key={item.name} className="form-check form-check-inline">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                        setCustomer({
                          ...customer,
                          notificationTypes: { ...customer.notificationTypes, [item.name]: e.target.checked },
                        });
                      }}
                      checked={customer?.notificationTypes?.[item.name] || false}
                    />
                    <label className="form-check-label"> {item.label} </label>
                  </div>
                );
              })}
            </div>
            {customer?.notificationTypes?.on_order_arriving_interval && (
              <div className="col-md-3 col-sm-6">
                <label className={styles.label}> ARRIVING INTERVAL </label>
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="form-group">
                      <select
                        id="billingAddress"
                        className={classnames('form-control')}
                        value={customer?.arrivingNotificationInterval}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
                          setCustomer({ ...customer, arrivingNotificationInterval: parseInt(e.target.value) });
                        }}
                      >
                        {['15', '30', '45', '60'].map(item => (
                          <option key={item} value={item}>
                            {`${item} min`}
                          </option>
                        ))}
                        );
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <button
          disabled={!currentUser?.recipientId}
          className={classnames(currentUser?.recipientId ? styles.formSubmit : styles.formSubmitDisabled)}
          type="submit"
        >
          SAVE
        </button>
      </form>
    </>
  );
};

const Enhanced = compose(withAlert, withAuthorization, withPageView)(AccountForm, 'Account');
export default withRouter(Enhanced as React.ComponentType<any>);
