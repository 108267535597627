/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, useState } from 'react';
import { connect } from 'react-redux';
import { DeliveryForm, AttachmentForm } from 'components/Forms';
import { AuthContext } from 'contexts';
import { StateProps } from 'reducers';
import { OrderStepperStateProps } from 'reducers/orderStepper';
import * as OrderStepperActions from 'actions/orderStepper';
import { OrderStepperActionsProps } from 'actions/types/orderStepper';
import { SelectedFileInfo } from 'types';
import useOrderContext from 'contexts/orderContext';
import { isEmpty } from 'lodash';
import moment from 'moment';

interface Props extends StateProps, OrderStepperStateProps, OrderStepperActionsProps {
  id: string;
  itemIndex: number;
}

const DeliveriesContent: React.FC<Props> = ({
  itemIndex: deliveryIndex,
  // State
  deliveries,
  // Actions
  // DeliveryInfo Form
  onDeliveryAddressChanged,
  onDeliveryRecipientChanged,
  onDeliveryDateChanged,
  onNewDeliveryRecipient,
  // Product Form
  onSetProducts,
  // Attachment Form
  onSetAttachment,
}: Props) => {
  const { isGuestView, order, deliveriesStep, destinationsOptions } = useOrderContext();
  const delivery = deliveries[deliveryIndex].deliveryInfo;
  const { currentUser } = useContext(AuthContext);
  const { recipients = [], destinations = [] } = currentUser || {};
  const currentDeliveryState = deliveriesStep[deliveryIndex];
  const { deliveryStep, attachmentStep } = currentDeliveryState;
  const isGuestUser = isGuestView || !currentUser;
  const [attachmentFileInfo, setAttachmentFileInfo] = useState<SelectedFileInfo>();

  useEffect(() => {
    const orderDeliveries = order?.deliveries;
    if (orderDeliveries && !isEmpty(orderDeliveries)) {
      orderDeliveries.forEach((orderDelivery, index) => {
        // DeliveryInfo Form
        if (orderDelivery?.destinationInfo) {
          onDeliveryAddressChanged(index, orderDelivery.destinationInfo);
        }

        if (orderDelivery?.recipientInfo) {
          onDeliveryRecipientChanged(index, {
            ...orderDelivery.recipientInfo,
            orderSize: orderDelivery.orderSize,
          });
        }

        if (orderDelivery?.requestedDelivery) {
          const formattedDate = moment.unix(orderDelivery.requestedDelivery).format('YYYY-MM-DDThh:mm');
          onDeliveryDateChanged(index, formattedDate);
        }
        // Product Form
        if (orderDelivery?.items) {
          onSetProducts(index, orderDelivery.items);
        }
        // Attachment Formconst
        const attachmentData = orderDelivery?.attachments;
        let attachmentFiles: SelectedFileInfo = { invoiceFile: null, otherFiles: null };
        if (attachmentData) {
          onSetAttachment(deliveryIndex, attachmentData);
          attachmentFiles = {
            invoiceFile: attachmentData?.invoiceFile || null,
            otherFiles: attachmentData?.otherFiles || null,
          };
          setAttachmentFileInfo(attachmentFiles);
        }
      });
    } else {
      // DeliveryInfo Form
      if (isGuestView || !delivery.deliveryRecipient?.metrcLicenseNumber) {
        onNewDeliveryRecipient(deliveryIndex);
      }
      if (delivery.deliveryRecipient) {
        onDeliveryRecipientChanged(deliveryIndex, delivery.deliveryRecipient);
      }
      if (delivery?.deliveryAddress) {
        onDeliveryAddressChanged(deliveryIndex, delivery.deliveryAddress);
      }
    }
  }, [order]);

  return (
    <div className="py-4">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <DeliveryForm
              deliveryIndex={deliveryIndex}
              destinations={destinations}
              destinationsOptions={destinationsOptions}
              isGuestView={isGuestUser}
              recipients={recipients}
              {...deliveryStep}
            />
          </div>
          <div className="col-12 pt-4">
            <AttachmentForm
              deliveryIndex={deliveryIndex}
              attachmentFileInfo={attachmentFileInfo}
              setAttachmentFileInfo={setAttachmentFileInfo}
              {...attachmentStep}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ orderStepper }: StateProps): any => ({ ...orderStepper });
const mapDispatchToProps = { ...OrderStepperActions };
const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(DeliveriesContent);
export default Connected;
