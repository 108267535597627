import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { NavLinkProps } from './AppNavLink';

import styles from './AppNavLinkDropdown.module.scss';
import navLinkStyles from './AppNavLink.module.scss';

interface DropdownActionLink extends NavLinkProps {
  action?: Promise<void> | any;
}

export type DropdownNavLink = {
  id?: string;
  label: string;
  options: DropdownActionLink[];
};

const AppNavLinkDropdown: React.FC<DropdownNavLink> = React.memo(({ id, label, options }: DropdownNavLink) => (
  <li className="nav-item dropdown">
    <a
      className={classnames(navLinkStyles.appNavLink, 'nav-link dropdown-toggle')}
      href="# "
      id={id}
      data-test-id={id}
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      {label}
    </a>
    <div id={`${id}Dropdown`} className={classnames(styles.appNavDropdown, 'dropdown-menu')} aria-labelledby="">
      {options &&
        options.map((link: DropdownActionLink, index) => (
          <Link
            key={index}
            id={`${id}Dropdown${link.label.replace(/\s/g, '')}`}
            data-test-id={`${id}Dropdown${link.label.replace(/\s/g, '')}`}
            className={classnames(styles.appNavDropdownItem, 'dropdown-item')}
            to={link.route || '#'}
            onClick={link.action}
          >
            {link.label}
          </Link>
        ))}
    </div>
  </li>
));

export default AppNavLinkDropdown;
