export default interface AlertContext {
  info: (message: string) => void;
  success: (message: string) => void;
  warning: (message: string) => void;
  error: (message: string) => void;

  alert?: AlertState;
}

export type AlertState = {
  type?: AlertType;
  message: string;
};

export enum AlertType {
  primary = 'primary',
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'danger',
}
