import React from 'react';
import { OrderItem } from 'types';
import { currencyFormat } from 'utils';

export type Props = {
  items: Array<OrderItem>;
};

const OrderCashDepositTable: React.FC<Props> = ({ items }: Props) => {
  return items && items.length > 0 ? (
    <div id="orderCashDepositTable" className="table-container" role="table" aria-label="Transaction details">
      <div className="flex-table header" role="rowgroup">
        <div className="flex-table-row columns-3" role="columnheader">
          Description
        </div>
        <div className="flex-table-row columns-3" role="columnheader">
          Name
        </div>
        <div className="flex-table-row columns-3" role="columnheader">
          Amount
        </div>
      </div>
      {items.map((orderItem: OrderItem) => (
        <div key={orderItem.item} className="flex-table data-row" role="rowgroup">
          <div className="flex-table-row columns-3" role="cell">
            {orderItem.description}
          </div>
          <div className="flex-table-row columns-3" role="cell">
            {orderItem.item}
          </div>
          <div className="flex-table-row columns-3" role="cell">
            {currencyFormat(orderItem.amount ?? 0)}
          </div>
        </div>
      ))}
    </div>
  ) : (
    <div id="orderCashDepositEmptyWrapper">No items to display</div>
  );
};

export default OrderCashDepositTable;
