import React from 'react';
import classnames from 'classnames';
interface Props {
  id: string;
  index: number;
  collapsed: boolean;
  children?: JSX.Element;
}

const CollapseContent: React.FC<Props> = ({ id, index, collapsed, children }: Props) => {
  return (
    <div
      id={`collapse${index}`}
      className={classnames('card__body collapse', !collapsed ? 'show' : '')}
      role="tabpanel"
      aria-labelledby={`heading${index}`}
      data-parent={`#${id}`}
    >
      <div className="card__body--content">{children}</div>
    </div>
  );
};
export default CollapseContent;
