import * as OrderStepperActions from 'actions/orderStepper';
import { OrderStepperActionsProps } from 'actions/types/orderStepper';
import classnames from 'classnames';

import { TextField, SelectField } from 'components/FormFields';
import { Alert } from 'components/Alerts';
import { OptionType } from 'components/Select';
import React, { ChangeEvent } from 'react';
import { connect } from 'react-redux';
import { StateProps } from 'reducers';
import { OrderStepperStateProps } from 'reducers/orderStepper';
import { cashDenomination } from 'types';
import { AlertType } from 'types/alert';
import { currencyFormat } from 'utils';

interface Props extends OrderStepperStateProps, OrderStepperActionsProps {
  stepperState: any;
  isValid: boolean;
  errors: any;
}

const CashChangeForm: React.FC<Props> = ({
  // State
  cashChange,
  // Props
  errors,
  // Actions
  onNewCashDenomination,
  onCashDenominationTypeChanged,
  onCashDenominationAmountChanged,
  onRemoveCashDenomination,
}: Props) => {
  const getCashDenominationOptions = (): Array<OptionType> => {
    return cashDenomination.map(denomination => ({
      value: denomination,
      label: denomination,
    }));
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h2>Change Order Form</h2>
        </div>
        <div className="col-12">
          {errors.cashChange && (
            <Alert message={errors.cashChange} type={AlertType.primary} isShowing={!!errors.cashChange} />
          )}
        </div>
      </div>
      <div className="row d-none d-lg-flex">
        <div className="col-6">
          <label htmlFor="denomination">Denomination</label>
        </div>
        <div className="col-6">
          <label htmlFor="quantity">Amount (USD)</label>
        </div>
      </div>
      {cashChange.denominations.map((change, index) => (
        <div key={index} className={classnames('row m-0 p-1', index % 2 === 0 ? 'bg-white-2' : '')}>
          <div className="col-xs-12 col-sm-6 pl-0">
            <SelectField
              id="cashDenomination"
              inputClassName="my-1"
              options={getCashDenominationOptions()}
              onChange={(option: OptionType): void => onCashDenominationTypeChanged(index, option.value)}
              value={change.denomination}
            />
          </div>
          <div className="col-xs-12 col-sm-6 pl-0">
            <div className="row pl-0 my-1">
              <div className="col-10">
                <TextField
                  id="amount"
                  name="amount"
                  type="number"
                  placeholder="Amount"
                  value={change?.amount || ''}
                  onChange={(e: ChangeEvent<HTMLInputElement>): void =>
                    onCashDenominationAmountChanged(index, Number(e.target.value))
                  }
                />
              </div>
              {index > 0 && (
                <div className="col-2">
                  <button
                    className="btn btn-transparent no-hover p-0 pt-3"
                    disabled={index === 0}
                    onClick={(): void => onRemoveCashDenomination(index)}
                  >
                    <i className="fas fa-trash fa-lg"></i>
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <div className="row m-0 p-1">
        <div className="col-6 pl-0"></div>
        <div className="col-6 pl-0 d-flex flex-column">
          <div className="row pl-0 my-1">
            <div className="col-10">
              <TextField
                id="total"
                name="total"
                type="text"
                disabled
                label="Total"
                onChange={function (_value: any): void {}}
                value={currencyFormat(cashChange.total)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12 text-center pt-3">
          <button
            className="btn btn-transparent text-uppercase ml-auto"
            disabled={false}
            onClick={(): void => onNewCashDenomination()}
          >
            <i className="fas fa-plus mr-2"></i>
            Add Denomination
          </button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ orderStepper }: StateProps): any => ({
  cashChange: orderStepper.cashChange,
});

const mapDispatchToProps = { ...OrderStepperActions };

const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(CashChangeForm);

export default Connected;
