import React, { Fragment } from 'react';
import classnames from 'classnames';
import * as routes from 'constants/routes';

import AppHeader from './components/AppHeader';
import { signOut } from 'services/auth';
import AppFooter from './components/AppFooter';
import { SITE } from '../../constants/footerTypes';

import style from './AppLayout.module.scss';
import { useAuth } from 'contexts/auth';

export type AppLayoutProps = {
  component: React.FC;
};

const AppLayout: React.FC<AppLayoutProps> = ({ component: Component }: AppLayoutProps) => {
  const auth = useAuth();

  const links = [
    {
      label: 'Dashboard',
      route: routes.dashboard,
    },
    {
      label: 'Transport Request',
      dropdown: true,
      options: [
        {
          label: 'Package',
          route: routes.newOrder,
        },
        {
          label: 'Cash',
          route: routes.newOrderCash,
        },
      ],
    },
    {
      label: 'History',
      route: routes.orderHistory,
    },
    ...(auth.currentUser?.recipientInfo?.isFinancialInstitution
      ? [
          {
            label: 'Cash Report',
            route: routes.cashBalance,
          },
        ]
      : []),
  ];

  const profile = {
    label: auth.currentUser?.displayName || 'Profile',
    options: [
      {
        label: 'Account',
        route: routes.account,
      },
      {
        label: 'Address Book',
        route: routes.addressBook,
      },
      {
        label: 'Reset Password',
        route: routes.resetPasswordProfile,
      },
      {
        label: 'Logout',
        action: signOut,
      },
    ],
  };

  return (
    <Fragment>
      <AppHeader links={links} dropdownLinks={profile}></AppHeader>
      <div className="align-items-center">
        <div className={classnames('container', style.layoutContainer)}>
          <Component />
        </div>
      </div>
      <AppFooter type={SITE}> </AppFooter>
    </Fragment>
  );
};

export default AppLayout;
