import React from 'react';
import { Route, RouteComponentProps } from 'react-router-dom';
import WithAuthorization from '../WithAuthorization';
import AppLayout from '../Layout/AppLayout';

type AppRouteProps = { component: React.FC } & RouteComponentProps;

const AppRoute: React.FC<AppRouteProps> = ({ component: Component, ...rest }: AppRouteProps) => {
  return <Route {...rest} render={(): any => <AppLayout {...rest} component={Component} />} />;
};

export default WithAuthorization(AppRoute);
