import { createContext, useContext } from 'react';
import { OrderContextType } from 'types/orderContext';

export const OrderContext = createContext<OrderContextType>({
  isGuestView: false,
  order: null,
  recipients: [],
  deliveriesStep: [],
  deliveries: [],
  destinations: [],
  stepperState: {},
  destinationsOptions: () => [],
  validateProductForm: () => false,
  validateDeliveryStep: () => false,
  metrcReferenceNumberExists: () => false,
  isProductDuplicated: () => false,
  onBeforeSearchProduct: () => false,
  validateStep: () => false,
  setStepperState: () => {},
  handleAddNewDelivery: () => {},
  handleDeleteDelivery: () => {},
});

const useOrderContext = (): OrderContextType => useContext(OrderContext);

export default useOrderContext;
