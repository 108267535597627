import React, { useEffect, useState } from 'react';
import { TextField } from '../TextField';
import { SearchFieldProps } from '../types';

const TextSearchField: React.FC<SearchFieldProps> = ({
  id,
  name,
  label,
  inputClassName,
  placeholder,
  value,
  required,
  isValid,
  feedback,
  onChange,
  onSearch,
  disabled,
}: SearchFieldProps) => {
  const [searchValue, setSearchValue] = useState('');

  const handleOnChange = (evt): void => {
    setSearchValue(evt.target.value);
    onChange(evt);
  };
  useEffect(() => {
    if (searchValue && searchValue.length >= 3) {
      const timeoutId = setTimeout(() => {
        onSearch(searchValue);
      }, 400);
      return (): void => clearTimeout(timeoutId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  return (
    <TextField
      id={id}
      name={name}
      type="text"
      label={label}
      required={required}
      disabled={disabled}
      placeholder={placeholder}
      inputClassName={inputClassName}
      onChange={handleOnChange}
      value={value}
      isValid={isValid}
      feedback={feedback}
    />
  );
};

export default TextSearchField;
