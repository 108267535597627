export const USERS = 'users';
export const ORDERS = 'tasks';
export const ORDER_LIST = 'orders';
export const ORDER_REQUESTS = 'orderRequests';
export const RECIPIENTS = 'recipients';
export const DESTINATIONS = 'destinations';
export const SETTINGS = 'settings';
export const MRA_LICENSES = 'mraLicenses';
export const ACCOUNT_REQUESTS = 'accountRequests';
export const LICENSES = 'licenses';
