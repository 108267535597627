import React, { forwardRef } from 'react';

export type NextButtonProps = {
  isActive: boolean;
  disabled?: boolean;
  onClick: () => void;
};

const NextButton = forwardRef<HTMLButtonElement, NextButtonProps>(
  ({ isActive, disabled, onClick }: NextButtonProps, ref) => {
    if (isActive === false) return null;
    return (
      <button
        ref={ref}
        className="btn btn-transparent text-uppercase ml-auto stepper-button"
        disabled={disabled}
        onClick={onClick}
      >
        Continue <i className="fas fa-arrow-right ml-2"></i>
      </button>
    );
  },
);

export default NextButton;
