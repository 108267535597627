import React from 'react';
import cls from 'classnames';

import { TextFieldProps } from '../types';

import style from './TextField.module.scss';

const TextField: React.FC<TextFieldProps> = ({
  id,
  name,
  label,
  type,
  inputClassName,
  placeholder,
  value,
  required,
  isValid,
  feedback,
  onChange,
  disabled,
}: TextFieldProps) => {
  const Feedback = (): React.ReactNode => {
    if (feedback) {
      return <span className={cls({ 'valid-feedback': isValid, 'invalid-feedback': !isValid })}>{feedback}</span>;
    }

    return null;
  };

  return (
    <>
      {label && <label htmlFor={name}>{label}</label>}
      <div>
        <input
          id={id}
          name={name}
          type={type}
          className={cls('form-control', style.input, inputClassName, {
            'is-invalid': isValid !== undefined && !isValid,
          })}
          placeholder={placeholder}
          value={value}
          required={required}
          autoComplete="off"
          onChange={onChange}
          disabled={!!disabled}
        />
        {Feedback()}
      </div>
    </>
  );
};

export default TextField;
