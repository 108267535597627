import React from 'react';
import cls from 'classnames';
import RadioButtonItem from './RadioButtonItem';
import { RadioButtonFieldProps } from '../types';

const RadioButtonField: React.FC<RadioButtonFieldProps> = ({
  label,
  name,
  hasFeedback,
  isValid,
  feedback,
  options,
  selected,
  onChangeItem,
}: RadioButtonFieldProps) => {
  return (
    <>
      <label className="form-check-label">{label}</label>
      <div className="form-group form-check">
        <div className="row">
          {options.map((item, index) => (
            <RadioButtonItem
              key={index}
              text={item.text}
              value={item.value}
              name={name}
              selected={selected}
              onChangeItem={onChangeItem}
            />
          ))}
        </div>
        {hasFeedback ? (
          <div className={cls({ 'valid-feedback': isValid, 'invalid-feedback': !isValid })}>{feedback}</div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default RadioButtonField;
