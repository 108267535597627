import React from 'react';
import cls from 'classnames';
import style from './Spinner.module.css';

type SpinnerProps = {
  fullScreen?: boolean;
  message?: string;
  overScren?: boolean;
  inheritColor?: boolean;
};

const Spinner: React.FC<SpinnerProps> = ({ message, fullScreen, overScren, inheritColor = false }: SpinnerProps) => {
  const overScreenClass = overScren ? `${style.over_screen} vh-100` : '';
  return (
    <div
      className={cls(
        'd-flex flex-column justify-content-center align-items-center',
        { 'vh-100': fullScreen },
        overScreenClass,
      )}
    >
      <div className={cls('spinner-border', { 'text-secondary': !inheritColor })} role="status">
        <span className="sr-only">Loading...</span>
      </div>
      {message && <span className={style.loading_text}>{message}</span>}
    </div>
  );
};

export default Spinner;
