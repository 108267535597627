import { useState, useEffect } from 'react';
import { getFinancialInstitutionTransactions } from 'services/cashTransactions';
import { useAuth } from 'contexts/auth';

export const useGetTransactions = (): any => {
  const user = useAuth();
  const [isLoading, setLoading] = useState<boolean>(false);
  const recipientId = user.currentUser?.recipientId;
  const [data, setData] = useState<any[]>([]);

  const fetchData = async (recipientId: string): Promise<void> => {
    setLoading(true);
    const data = await getFinancialInstitutionTransactions(recipientId);

    setData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (recipientId) {
      fetchData(recipientId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipientId]);

  return { data, isLoading };
};
