import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import styles from './AccountForm.module.scss';
import stylesPassword from './ResetPasswordProfile.module.scss';
import { withAlert, withPageView } from 'components';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { compose } from 'redux';
import { ResetPassword } from 'types/resetPassword';
import Alert from 'types/alert';
import { updatePassword } from 'services/auth';

interface Props extends Alert, RouteComponentProps {}

const ResetPasswordProfile: React.FC<Props> = ({ success, error }: Props) => {
  const [isValidMinLetter, setIsValidMinLetter] = useState<boolean | null>();
  const [isValidNumber, setIsValidNumber] = useState<boolean | null>();
  const [isValidLowUpperCase, setIsValidLowUpperCase] = useState<boolean | null>();
  const [isValidSpecialCharacter, setIsValidSpecialCharacter] = useState<boolean | null>();
  const [barPercent, setBarPercent] = useState<number | null>();
  const [resetPassword, setResetPassword] = useState<ResetPassword | null>();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();

    if (!isValidMinLetter || !isValidNumber || !isValidLowUpperCase || !isValidSpecialCharacter) {
      error('Please include all rules to have a strong password');
      return;
    }
    if (resetPassword?.newPassword !== resetPassword?.confirmPassword) {
      error('Your password and confirmation password do not match.');
      return;
    }

    if (resetPassword) {
      const response = await updatePassword(resetPassword);
      if (response.code !== 'success') {
        error(response.message);
      } else {
        success(response.message);
      }
    }
  };

  useEffect(() => {
    setIsValidMinLetter(false);
    setIsValidNumber(false);
    setIsValidLowUpperCase(false);
    setIsValidSpecialCharacter(false);
    setBarPercent(0);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const minimumRegex = new RegExp('^(?=.{8,})');
    const numberRegex = new RegExp('^(?=.*[0-9])');
    const lowUpperStateRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])');
    const specialCharRegex = new RegExp('^(?=.*[!@#$%^&*])');

    setIsValidMinLetter(minimumRegex.test(event.target.value));
    setIsValidNumber(numberRegex.test(event.target.value));
    setIsValidLowUpperCase(lowUpperStateRegex.test(event.target.value));
    setIsValidSpecialCharacter(specialCharRegex.test(event.target.value));
    setResetPassword({ ...resetPassword, [event.target.id]: event.target.value });

    // set Weak Percent
    const rules = Array<boolean>();
    rules.push(minimumRegex.test(event.target.value) || false);
    rules.push(numberRegex.test(event.target.value) || false);
    rules.push(lowUpperStateRegex.test(event.target.value) || false);
    rules.push(specialCharRegex.test(event.target.value) || false);

    const totalValid = rules.filter(item => item);
    setBarPercent(25 * totalValid.length);
  };

  return (
    <div className={classnames('row', styles.container)}>
      <div className={classnames('col-lg-6 col-md-12 col-sm-12 mb-4')}>
        <form id="password-reset-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label className={styles.label}> OLD PASSWORD </label>
            <input
              id="oldPassword"
              type="password"
              placeholder="Old Passoword"
              aria-describedby="text"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                setResetPassword({ ...resetPassword, oldPassword: e.target.value });
              }}
              className={classnames('form-control', styles.formInput)}
              required
            />
          </div>

          <div className="form-group">
            <label className={styles.label}> NEW PASSWORD </label>
            <input
              id="newPassword"
              type="password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                handleChange(e);
              }}
              placeholder="New Password"
              aria-describedby="text"
              className={classnames('form-control', styles.formInput)}
              required
            />
          </div>

          <div className="form-group">
            <label className={styles.label}> CONFIRM PASSWORD </label>
            <input
              id="confirmPassword"
              type="password"
              placeholder="Confirm Password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
                handleChange(e);
              }}
              aria-describedby="text"
              className={classnames('form-control', styles.formInput)}
              required
            />
          </div>
          <button
            id="login-submit-btn align-items-center mb-4"
            className={classnames('mt-0', styles.formSubmit)}
            type="submit"
          >
            CHANGE PASSWORD
          </button>
        </form>
      </div>
      <div className={classnames('col-lg-6 col-md-12 col-sm-12')}>
        <div className={classnames('p-0 col-md-12 col-sm-12')}>
          <h3 className={classnames(stylesPassword.passwordTitle)}>Password Strength</h3>
          <p className={classnames(stylesPassword.subtitle)}>
            Please include all these rules to have a strong password
          </p>
          <div className={classnames('mb-2 row')}>
            <div className={classnames('col-md-1 col-1')}>
              <i
                className={classnames(`fas ${isValidMinLetter ? 'fa-check-circle' : 'fa-times-circle'} fa-1x`)}
                style={{ color: `${isValidMinLetter ? 'green' : 'red'}` }}
              ></i>
            </div>
            <div className={classnames('p-0 pl-2 col-md-8 col-8')}>
              <span className={classnames(stylesPassword.instruction)}>Use at least 8 characters.</span>
            </div>
          </div>
          <div className={classnames('mb-2 row')}>
            <div className={classnames('col-md-1 col-1')}>
              <i
                className={classnames(`fas ${isValidNumber ? 'fa-check-circle' : 'fa-times-circle'} fa-1x`)}
                style={{ color: `${isValidNumber ? 'green' : 'red'}` }}
              ></i>
            </div>
            <div className={classnames('p-0 pl-2 col-md-8 col-8')}>
              <span className={classnames(stylesPassword.instruction)}>Use numbers.</span>
            </div>
          </div>
          <div className={classnames('mb-2 row')}>
            <div className={classnames('pl-3 col-md-1 col-1')}>
              <i
                className={classnames(`fas ${isValidLowUpperCase ? 'fa-check-circle' : 'fa-times-circle'} fa-1x`)}
                style={{ color: `${isValidLowUpperCase ? 'green' : 'red'}` }}
              ></i>
            </div>
            <div className={classnames('p-0 pl-2 col-md-8 col-8')}>
              <span className={classnames(stylesPassword.instruction)}>Use uppercase and lowercase.</span>
            </div>
          </div>
          <div className={classnames('mb-2 row')}>
            <div className={classnames('pl-3 col-md-1 col-1')}>
              <i
                className={classnames(`fas ${isValidSpecialCharacter ? 'fa-check-circle' : 'fa-times-circle'} fa-1x`)}
                style={{ color: `${isValidSpecialCharacter ? 'green' : 'red'}` }}
              ></i>
            </div>
            <div className={classnames('p-0 pl-2 col-md-8 col-8')}>
              <span className={classnames(stylesPassword.instruction)}>
                Include at least 1 special character: (!@#$%^&*()_+,.).
              </span>
            </div>
          </div>
          <div className={classnames('mt-4 row')}>
            <div className={classnames('col-md-1 col-2')}>
              <h4> Strong </h4>
            </div>
            <div className={classnames('p-1 pl-4 col-md-8 col-9')}>
              <div className="rounded-0 mt-1 ml-2 progress" style={{ height: '8px' }}>
                <div
                  role="progressbar"
                  aria-valuenow={0}
                  style={{ width: `${barPercent}%` }}
                  aria-valuemin={0}
                  className={stylesPassword.colorBar}
                  aria-valuemax={100}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Enhanced = compose(withAlert, withPageView)(ResetPasswordProfile, 'Reset Password Profile');
export default withRouter(Enhanced as React.ComponentType<any>);
