import React from 'react';
import { connect } from 'react-redux';
import { StateProps } from 'reducers';
import { OrderStepperStateProps } from 'reducers/orderStepper';
import * as OrderStepperActions from 'actions/orderStepper';
import { Destination, Order, Recipient } from 'types';
import { OptionType } from 'components/Select';
import CashTransportRequestForm from 'components/Forms/CashTransportRequestForm';

interface Props {
  destinations: Array<Destination>;
  financialDestinations: Array<Destination>;
  destinationsOptions: (financialInstitution?: boolean, showNew?: boolean) => Array<OptionType>;
  isGuestUser: boolean;
  order?: Order;
  financialInstitutions: Array<Recipient>;
  recipients: Array<Recipient>;
  isValid: boolean;
  errors: any;
}

const TransactionStep: React.FC<Props> = (props: Props) => {
  return <CashTransportRequestForm {...props} />;
};

const mapStateToProps = (): any => ({});

const mapDispatchToProps = { ...OrderStepperActions };

const Connected = connect<OrderStepperStateProps, any, any, StateProps>(
  mapStateToProps,
  mapDispatchToProps,
)(TransactionStep);

export default Connected;
