import { firebase } from '_firebase';
import 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';
import { UploadedFileModel } from 'types/uploadedFileModel';

export const uploadFile = (file: File, path: string): Promise<UploadedFileModel> => {
  const { name: fileName } = file;
  const extension = fileName.split('.').pop();
  const uidFileName = `${uuidv4()}.${extension}`;
  const filePath = `${path}/${uidFileName}`;
  const storageRef = firebase.storage().ref(filePath);

  return new Promise(resolve => {
    storageRef.put(file).then(data => {
      data.ref.getDownloadURL().then(url => {
        resolve({
          fileUrl: url,
          filePath,
          fileName,
        });
      });
    });
  });
};

export const deleteFile = (filePath: string): Promise<boolean> => {
  const storageRef = firebase.storage().ref();
  // Create a reference to the file to delete
  const desertRef = storageRef.child(filePath);
  return new Promise(resolve => {
    // Delete the file
    desertRef.delete().then(() => {
      resolve(true);
    });
  });
};
