import React from 'react';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  value: string;
};

const EmailField: React.FC<Props> = ({ className, onChange, value }: Props) => {
  return (
    <div className="form-group">
      <input
        id="login-password"
        type="password"
        placeholder="Password"
        aria-describedby="password"
        className={className}
        onChange={onChange}
        value={value}
        required
      />
    </div>
  );
};

export default EmailField;
