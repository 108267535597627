import { useState, useEffect } from 'react';
import { Order } from 'types';
import { getOrderHistory, getOrderById, getDestination, getRecipientById } from 'services';
import { actions as globalAction } from '../../reducers/global';
import { useStore } from 'react-redux';

export const useGetOrders = (customerId?: string): any => {
  const [orders, setOrders] = useState<Array<Order> | null>();

  const fetchData = async (customerId: string): Promise<void> => {
    const orders = await getOrderHistory(customerId);
    setOrders(orders);
  };

  useEffect(() => {
    if (customerId) {
      fetchData(customerId);
    }
  }, [customerId]);

  return { orderList: orders };
};

export const useGetOrderById = (orderId: string, customerId?: string): any => {
  const [order, setOrder] = useState<Order | null>();
  const store = useStore();

  useEffect(() => {
    const fetchData = async (orderId: string): Promise<void> => {
      try {
        const order = await getOrderById(orderId);

        if (order?.billingAddressId) {
          const billingAddress = await getDestination(order?.billingAddressId);
          if (billingAddress) {
            const billingAddressOwner = await getRecipientById(billingAddress?.owner);
            order.billingAddressInfo = { ...billingAddress, ownerInfo: billingAddressOwner };
          }
        }

        setOrder(order);
      } catch (error) {
        console.log(error);
      } finally {
        store.dispatch(globalAction.onRequestExecuting(false));
      }
    };

    if (orderId) {
      store.dispatch(globalAction.onRequestExecuting(true));
      fetchData(orderId);
    }
  }, [customerId, orderId, store]);

  return { order };
};
