import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import { AuthProvider } from './providers';
import * as routes from './constants/routes';
import store from './store';

import AlertWrapper from 'components/AlertWrapper';
import AppRoute from 'components/AppRoute';
import GuestRoute from 'components/GuestRoute';

import Login from './pages/Login';
import SignUp from 'pages/SignUp';
import Account from './pages/Account';
import Dashboard from './pages/Dashboard';
import InvoiceHistory from './pages/InvoiceHistory';
import NewOrder from './pages/NewOrder';
import ViewOrder from 'pages/ViewOrder';
import EditOrder from 'pages/EditOrder';
import OrderHistory from './pages/OrderHistory';
import ResetPassword from './pages/ResetPassword';
import NotFound from './pages/NotFound';
import { GuestView as NewOrderGuest } from 'pages/NewOrder/NewOrder';
import CashBalance from 'pages/CashBalance';

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AuthProvider>
        <AlertWrapper>
          <Router history={createBrowserHistory()}>
            <Switch>
              <GuestRoute
                exact
                path={routes.newOrderGuest}
                component={(): JSX.Element => NewOrderGuest({ isGuestView: true })}
              />
              <GuestRoute exact path={routes.signUp} component={(): JSX.Element => <SignUp />} />
              <Route exact path={routes.login} component={Login} />
              <AppRoute exact path={routes.dashboard} component={Dashboard} />
              <AppRoute exact path={routes.newOrder} component={NewOrder} />
              <AppRoute exact path={routes.viewOrder} component={ViewOrder} />
              <AppRoute exact path={routes.editOrder} component={EditOrder} />
              <AppRoute exact path={routes.orderHistory} component={OrderHistory} />
              <AppRoute exact path={routes.cashBalance} component={CashBalance} />
              <AppRoute exact path={routes.invoiceHistory} component={InvoiceHistory} />
              <AppRoute exact path={routes.account} component={Account} />
              <AppRoute exact path={routes.addressBook} component={Account} />
              <AppRoute exact path={routes.bankingInformation} component={Account} />
              <AppRoute exact path={routes.resetPasswordProfile} component={Account} />
              <Route exact path={routes.resetPassword} component={ResetPassword} />
              <AppRoute exact component={NotFound} />
            </Switch>
          </Router>
        </AlertWrapper>
      </AuthProvider>
    </Provider>
  );
};

export default App;
