import React from 'react';
import cls from 'classnames';
import { OrderItem } from 'types';
import style from './OrderDetail.module.scss';
import { currencyFormat } from 'utils';

type OrderTransactionItemsProps = {
  items?: Array<OrderItem>;
};

const OrderDetailCashDepositTransaction: React.FC<OrderTransactionItemsProps> = ({
  items,
}: OrderTransactionItemsProps): any => {
  return (
    <div className="order-items" data-id="order-items">
      {items && items.length ? (
        <table className="w-100 mt-4">
          <thead>
            <tr>
              <th className={style.label}>Description</th>
              <th className={style.label}>Name</th>
              <th className={style.label}>Amount</th>
            </tr>
          </thead>
          <tbody>
            {items.map(({ description, item, amount }: OrderItem, idx: number) => (
              <tr key={idx}>
                <td className={cls(style.cell, 'border-bottom border-gray pr-2')} data-id="order-item-description">
                  {description}
                </td>
                <td className={cls(style.cell, 'border-bottom border-gray pr-2')} data-id="order-item-item">
                  {item}
                </td>
                <td className={cls(style.cell, 'border-bottom border-gray pr-2')} data-id="order-item-amount">
                  {currencyFormat(amount ?? 0)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <span>No Items</span>
      )}
    </div>
  );
};

export default OrderDetailCashDepositTransaction;
