import React from 'react';

import { RadioButtonType } from '../types';

const RadioButtonItem: React.FC<RadioButtonType> = ({ text, name, value, selected, onChangeItem }: RadioButtonType) => {
  const id = `${name}_${value}`;
  return (
    <div className="form-check form-check-inline">
      <input
        id={id}
        name={name}
        className="form-check-input"
        type="radio"
        value={value}
        checked={selected === value}
        onChange={onChangeItem}
      />
      <label className="form-check-label" htmlFor={id}>
        {text}
      </label>
    </div>
  );
};

export default RadioButtonItem;
