import React from 'react';
import cls from 'classnames';

import { CheckBoxFieldProps } from '../types';

const CheckBoxField: React.FC<CheckBoxFieldProps> = ({
  id,
  name,
  label,
  inputClassName,
  checked,
  hasFeedback,
  isValid,
  feedback,
  onChange,
  disabled,
}: CheckBoxFieldProps) => {
  return (
    <div className="form-group form-check">
      <input
        id={id}
        name={name}
        type="checkbox"
        className={cls('form-check-input', inputClassName, {
          'is-valid': hasFeedback && isValid,
          'is-invalid': hasFeedback && !isValid,
        })}
        checked={checked}
        onChange={onChange}
        disabled={!!disabled}
      />
      <label className="form-check-label" htmlFor={name}>
        {label}
      </label>
      {hasFeedback ? (
        <div className={cls({ 'valid-feedback': isValid, 'invalid-feedback': !isValid })}>{feedback}</div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CheckBoxField;
