import React from 'react';
import Select, { OptionType } from 'components/Select';
import Filter from 'components/Filters';
import { OrderStatus } from 'types';
import { ORDER_STATUS_LABELS } from 'constants/order';

type Props = {
  className?: string;
  onChange: (option: OptionType) => void;
  value?: string;
};

const options = [
  { value: '', label: 'All' },
  { value: OrderStatus.DRAFT, label: ORDER_STATUS_LABELS[OrderStatus.DRAFT] },
  { value: OrderStatus.CREATED, label: ORDER_STATUS_LABELS[OrderStatus.CREATED] },
  { value: OrderStatus.ACCEPTED, label: ORDER_STATUS_LABELS[OrderStatus.ACCEPTED] },
  { value: OrderStatus.PROCESSING, label: ORDER_STATUS_LABELS[OrderStatus.PROCESSING] },
  { value: OrderStatus.SCHEDULED, label: ORDER_STATUS_LABELS[OrderStatus.SCHEDULED] },
  { value: OrderStatus.PROGRESS, label: ORDER_STATUS_LABELS[OrderStatus.PROGRESS] },
  { value: OrderStatus.COMPLETED, label: ORDER_STATUS_LABELS[OrderStatus.COMPLETED] },
];

const SelectFilter: React.FC<Props> = ({ className, onChange, value }: Props) => {
  return (
    <Filter name="Status" selector="status">
      <Select
        className={className}
        options={options}
        placeholder="Select an option"
        onChange={onChange}
        value={value}
      />
    </Filter>
  );
};

export default SelectFilter;
