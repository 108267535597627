import React from 'react';
import * as routes from 'constants/routes';

import { NotFound as NotFoundComponent } from 'components/NotFound';

const NotFound: React.FC = () => {
  return (
    <div className="row">
      <div className="col">
        <NotFoundComponent redirectUrl={routes.dashboard}></NotFoundComponent>
      </div>
    </div>
  );
};

export default NotFound;
