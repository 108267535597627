import { useState } from 'react';
import { useStore } from 'react-redux';

type UseLoadingState = {
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
};

export const useIsLoading = (initialValue = false): UseLoadingState => {
  const store = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(initialValue);

  const selectIsRequesting = (state): boolean => {
    return state.global.isRequestExecuting;
  };

  let currentStoreValue: boolean;
  const handleStoreChange = (): void => {
    const previousValue = currentStoreValue;
    currentStoreValue = selectIsRequesting(store.getState());

    if (previousValue !== currentStoreValue) {
      setIsLoading(currentStoreValue);
    }
  };

  store.subscribe(handleStoreChange);

  return { isLoading, setIsLoading };
};
