import React, { PropsWithChildren } from 'react';

import style from './Filter.module.scss';

interface Props extends PropsWithChildren<any> {
  name: string;
  selector: string;
}

const SelectFilter: React.FC<Props> = ({ name, children, selector }: Props) => {
  return (
    <div data-id={selector}>
      <span className={style.name}>{name}</span>
      {children}
    </div>
  );
};

export default SelectFilter;
