/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { firestore } from '_firebase';
import { isEmpty, omit } from 'lodash';
import { MRALicense, OrderRequest } from 'types';
import * as collections from 'constants/collections';
import { createDestination } from './destinations';
import { createMRALicense, createRecipient } from './recipients';
import { getSettings } from './settings';
import { findRecipientLicense } from 'services';
import { CurrentUser } from 'types/auth';

const getDestination = async (destination: any, owner: string): Promise<string> => {
  if (!isEmpty(destination?.id)) {
    return destination?.id || '';
  }

  if (destination) {
    delete destination.id;

    const res = await createDestination({
      ...destination,
      owner,
    });
    return res.id;
  }

  return '';
};

const getRecipient = async (recipient: any, orderRequest: OrderRequest): Promise<string> => {
  if (!isEmpty(recipient?.id)) {
    const license = await findRecipientLicense(recipient.metrcLicenseNumber);
    if (license) return license?.recipientId;
  }

  delete recipient.id;

  return await createRecipient({
    ...recipient,
    ownerId: orderRequest.creator,
    disabled: false,
  });
};

const getMetrcLicense = async (mraLicense: MRALicense): Promise<string> => {
  return await createMRALicense(mraLicense);
};

const getOrderRequestAsUserLoggued = async (orderRequest: OrderRequest, currentUser: CurrentUser): Promise<any> => {
  const { recipientId: owner } = currentUser;

  if (!owner) {
    return undefined;
  }

  // Origin Info
  if (orderRequest.senderInfo) {
    const senderId = orderRequest.senderInfo.id
      ? orderRequest.senderInfo.id
      : await getRecipient(orderRequest.senderInfo, orderRequest);

    const originId = await getDestination(orderRequest.originInfo, owner);
    orderRequest.senderId = senderId;
    orderRequest.originId = originId;

    if (orderRequest.type === 'package') {
      orderRequest.senderLicenseId = await getMetrcLicense({
        recipientId: senderId,
        number: orderRequest.senderInfo.metrcLicenseNumber,
        destinationId: originId,
        disabled: false,
        owner,
      });
    }
  }

  // Recipient Info
  if (orderRequest.recipientInfo) {
    const recipientId = orderRequest.recipientInfo.id
      ? orderRequest.recipientInfo.id
      : await getRecipient(orderRequest.recipientInfo, orderRequest);

    const destinationId = await getDestination(orderRequest.destinationInfo, owner);
    orderRequest.recipientId = recipientId;
    orderRequest.destinationId = destinationId;

    if (orderRequest.type === 'package') {
      orderRequest.recipientLicenseId = await getMetrcLicense({
        recipientId: recipientId,
        number: orderRequest.recipientInfo.metrcLicenseNumber,
        destinationId: destinationId,
        disabled: false,
        owner,
      });
    }
  }

  orderRequest.billingAddressId =
    (orderRequest.pickupAddressAsBilling ? orderRequest.originId : orderRequest.billingAddressId) ?? '';

  const settings = await getSettings();
  orderRequest.taxPercentage = settings?.taxPercentage || 0;

  orderRequest.source = 'customer-portal';
  orderRequest.processed = false;

  const propsToDelete = ['pickupAddressAsBilling', 'originInfo', 'senderInfo', 'destinationInfo', 'recipientInfo'];
  return omit(orderRequest, propsToDelete);
};

const getOrderRequestAsGuest = async (orderRequest: OrderRequest): Promise<any> => {
  const request: any = { ...orderRequest };
  const sanitizeFields = (source: string, targetId: string): void => {
    if (request[source].id !== undefined) {
      if (request[source].id.length) {
        request[targetId] = orderRequest[source].id;
        delete request[source];
      } else {
        delete request[source].id;
      }
    }
  };

  if (request.pickupAddressAsBilling && request.originId) {
    request.billingAddressId = request.originId;
  }

  sanitizeFields('senderInfo', 'senderId');
  sanitizeFields('recipientInfo', 'recipientId');
  sanitizeFields('originInfo', 'originId');
  sanitizeFields('destinationInfo', 'destinationId');
  return request;
};

enum OrderStatus {
  UPDATED = 'UPDATED',
  SAVED = 'SAVED',
}

type OrderResult = {
  orderRefId: string;
  status: OrderStatus;
};

export const saveOrderRequest = async (
  orderRequests: Array<OrderRequest>,
  currentUser: CurrentUser | null | undefined,
  setSavingOrdersMsg: (msg: string) => void,
): Promise<any> => {
  const results: Array<OrderResult> = [];
  const collection = firestore.collection(collections.ORDER_REQUESTS);

  for (let i = 0; i < orderRequests.length; i++) {
    setSavingOrdersMsg(`Saving order ${i + 1} of ${orderRequests.length}...`);
    const orderRequest = orderRequests[i];
    const orderRequestData = currentUser
      ? await getOrderRequestAsUserLoggued(orderRequest, currentUser)
      : await getOrderRequestAsGuest(orderRequest);
    const validOrderRequestData = Object.fromEntries(
      Object.entries(orderRequestData).filter(([_, value]) => value !== null && value !== undefined),
    );

    if (orderRequest.isNew) {
      const orderReference = orderRequest.orderReference;
      await orderReference.set(validOrderRequestData);
      results.push({
        orderRefId: orderReference.id,
        status: OrderStatus.SAVED,
      });
    } else {
      await collection.doc(orderRequest.id).update(validOrderRequestData);
      results.push({
        orderRefId: orderRequest.orderReferenceId,
        status: OrderStatus.UPDATED,
      });
    }
  }
  return results;
};
