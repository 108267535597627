import { firestore } from '_firebase';
import * as collections from 'constants/collections';
import { Recipient } from 'types';
import {
  BankingInformationType,
  RecipientBankingInformationType,
  CurrentUserRecipient,
} from 'types/bankingInformation';

export const getBankingInformation = async (recipientInfo: Recipient): Promise<any> => {
  const bankingInformationRef = await firestore
    .collection(collections.RECIPIENTS)
    .doc(recipientInfo.financialInstitutionId)
    .get();
  const bankingInformationData =
    (bankingInformationRef.data() as RecipientBankingInformationType) ?? ({} as RecipientBankingInformationType);

  const destinationData = {
    id: '',
    name: '',
  };

  const destinationRef = await firestore
    .collection(collections.DESTINATIONS)
    .where('owner', '==', bankingInformationRef.id)
    .where('active', '==', true)
    .get();

  if (!destinationRef.empty) {
    const [doc] = destinationRef.docs;

    destinationData.id = doc.id;
    destinationData.name = doc.data().name;
  }

  const bankingInformation: BankingInformationType = {
    ...bankingInformationData,
    destinationId: destinationData.id,
    destinationName: destinationData.name,
    name: bankingInformationData.name,
    accountNumber: recipientInfo.accountNumber ?? '',
    fein: recipientInfo.fein ?? '',
    financialInstitutionId: recipientInfo.financialInstitutionId ?? '',
  };
  return bankingInformation;
};

export const saveBankingInformation = async (
  data: RecipientBankingInformationType,
  financialInstitutionId?: string,
): Promise<any> => {
  if (financialInstitutionId) {
    await firestore.collection(collections.RECIPIENTS).doc(financialInstitutionId).update(data);
    return { id: financialInstitutionId };
  } else {
    return await firestore.collection(collections.RECIPIENTS).add(data);
  }
};

export const updateCurrentUserRecipient = async (recipientId: string, data: CurrentUserRecipient): Promise<any> => {
  return await firestore.collection(collections.RECIPIENTS).doc(recipientId).update(data);
};
