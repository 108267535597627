export const PACKAGE = 'package';
export const CASH = 'cash';
export const CASH_CHANGE_DROPS = 'changeDrops';
export const CASH_DEPOSIT = 'cashDeposit';
export const CASH_DEPOSIT_BANK = 'bank';
export const CASH_DEPOSIT_FED_RESERVE = 'fedReserve';
export const CASH_DEPOSIT_VAULT = 'vault';
export const DELIVERY = 'delivery';
export const PICKUP = 'pickup';
export const TRANSACTIONS_NAME = {
  [CASH]: 'Cash',
  [CASH_DEPOSIT]: 'Cash Deposit',
  [CASH_CHANGE_DROPS]: 'Change Drops',
  [CASH_DEPOSIT_BANK]: 'Bank',
  [CASH_DEPOSIT_FED_RESERVE]: 'FED Reserve',
  [CASH_DEPOSIT_VAULT]: 'Vault',
  [DELIVERY]: 'Delivery',
  [PICKUP]: 'Pickup',
  [PACKAGE]: 'Package',
};
