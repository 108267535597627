import React from 'react';
import classnames from 'classnames';
import JKLogixLogo from '../../assets/images/JKLogixLogo.png';
import styles from './AuthLayout.module.scss';
import AppFooter from './components/AppFooter';
import { AUTH } from '../../constants/footerTypes';
type Props = {
  title: string;
  children: any;
};

const AuthLayout: React.FC<Props> = (props: Props) => {
  return (
    <div className={classnames('vw-100 vh-100 d-flex flex-column align-items-center', styles.page)}>
      <div className={styles.container}>
        <div className="pt-4 text-center">
          <img className={styles.header_logo} src={JKLogixLogo} alt="JK Logix Logo" />
          <p className={styles.header_subtitle}>{props.title} </p>
        </div>
        <div className={styles.content}>{props.children}</div>
      </div>
      <AppFooter type={AUTH}> </AppFooter>
    </div>
  );
};

export default AuthLayout;
