import React, { PropsWithChildren, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { AlertContext } from 'contexts';
import { AlertType, AlertState } from 'types/alert';

const alertDuration = 5000; // Milliseconds

export const AlertProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [alert, setAlert] = useState<AlertState | undefined>();

  useEffect(() => {
    if (alert) {
      const timer = setTimeout(() => {
        setAlert(undefined);
      }, alertDuration);
      return (): void => clearTimeout(timer);
    }
  }, [alert]);

  const info = (message: string): void => {
    setAlert({
      message,
      type: AlertType.info,
    });
  };

  const success = (message: string): void => {
    setAlert({
      message,
      type: AlertType.success,
    });
  };

  const warning = (message: string): void => {
    setAlert({
      message,
      type: AlertType.warning,
    });
  };

  const error = (message: string): void => {
    setAlert({
      message,
      type: AlertType.error,
    });
  };

  return (
    <AlertContext.Provider
      value={{
        alert,
        info,
        success,
        warning,
        error,
      }}
    >
      {children}
    </AlertContext.Provider>
  );
};

AlertProvider.propTypes = {
  children: PropTypes.any,
};

export const AlertConsumer = AlertContext.Consumer;
