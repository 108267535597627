import React from 'react';
import { withAuthorization, withPageView } from 'components';
import { OrderStepper } from 'components/Stepper';
import { RouteComponentProps } from 'react-router-dom';

interface RouteParams {
  id: string;
}

const EditOrder: React.FC<RouteComponentProps<RouteParams>> = ({ match }: RouteComponentProps<RouteParams>) => {
  return <OrderStepper id={match.params.id} />;
};

const Enhanced = withPageView(EditOrder, 'Edit Order');
export default withAuthorization(Enhanced);
