import React, { useState, forwardRef } from 'react';
import { OrientationTypes } from 'types/orientation';
import Step, { IStepProps } from './Step';
import StepHeader from './StepHeader';
import classnames from 'classnames';
import styles from './Stepper.module.scss';

export type StepperProps = {
  steps: IStepProps[];
  orientation?: OrientationTypes;
  mainStepper?: boolean;
};

const Stepper = forwardRef<HTMLButtonElement, StepperProps>(
  ({ steps, orientation = OrientationTypes.Horizontal, mainStepper = false }: StepperProps, ref): any => {
    const [currentStepIndex, setCurrentStep] = useState<number>(0);
    const currentStep = steps[currentStepIndex];
    const totalSteps = steps.length - 1;
    const isVertical = orientation === OrientationTypes.Vertical;

    const goToPreviousStep = (): void => {
      setCurrentStep(currentStepIndex - 1);
    };

    const goToNextStep = (): void => {
      const validate = currentStep.validate;
      const isValid = validate ? validate() : currentStep.isValid;

      if (isValid) {
        setCurrentStep(currentStepIndex + 1);
      }
    };

    const onHeaderClick = (index: number): void => {
      const isMovingToNextStep = index >= currentStepIndex;
      // If the user is trying to move to the next step the current step should be valid
      // If the user is trying to move back, we should allow this actions
      if ((isMovingToNextStep && currentStep.validate()) || !isMovingToNextStep) {
        setCurrentStep(index);
      }
    };

    return (
      <div className={classnames(styles.stepper, isVertical ? styles.stepperVerticalAlign : '', 'stepper-container')}>
        <StepHeader
          steps={steps.map((step, index) => ({ ...step, isActive: index === currentStepIndex }))}
          onHeaderStepClick={onHeaderClick}
          mainStepper={mainStepper}
        ></StepHeader>
        {steps.map((step, index) => (
          <Step
            key={index}
            ref={ref}
            isActive={index === currentStepIndex}
            isValid={step.isValid || false}
            component={step.component}
            validate={step.validate}
            displayPrevious={step.displayPrevious || currentStepIndex > 0}
            displayNext={step.displayNext || currentStepIndex < totalSteps}
            goToPreviousStep={step.goToPreviousStep || ((): void => goToPreviousStep())}
            goToNextStep={step.goToNextStep || ((): void => goToNextStep())}
          />
        ))}
      </div>
    );
    // return steps;
  },
);

export default Stepper;
